import { Checkbox, Col, Form, Row } from "antd";
import { observer } from "mobx-react";
import style from '../../style.module.scss';


export const AgeBlindness = observer(() => {
    return <Row className={style.formItemStyle}>
    <Col span={4}><h3 className={style.fillingStatus}>Age / Blindness		</h3></Col>
    <Col span={20}>
    <Row justify={'space-around'} >
    
      <h4>You:	</h4>
      <h4>	Spouse: </h4>
  </Row>
  <Row justify={'space-around'}>
      <Form.Item name="age_blindness">
        <Checkbox.Group>
          <Checkbox value="Were_born_before_1958"> Were born before January 2, 1958						</Checkbox>
          <Checkbox value="you_blind">
            Are blind
          </Checkbox>
          <Checkbox value="spouse_born_before_1958"> Were born before January 2, 1958						</Checkbox>
          <Checkbox value="spouse_blind">
            Is blind
          </Checkbox>
        </Checkbox.Group>
      </Form.Item>
  </Row>
    </Col>
    </Row>
})