import { Form, Input, Radio, Row } from "antd";
import { observer } from "mobx-react";
import { Label } from "../../../common-components/label";
import style from '../../style.module.scss';

export const FilingStatus = observer(() => {
    return <div className={style.formItemStyle}>
    <Row justify={'space-around'}>
      <Label className={style.fillingStatus}>Filing Status</Label>
      <Form.Item name="filing_status">
        <Radio.Group>
          <Radio value="single">Single</Radio>
          <Radio value="marriedFilingJointly">
            Married filing jointly
          </Radio>
          <Radio value="marriedFilingSeparately">
            Married filing separately (MFS)
          </Radio>
          <Radio value="headOfHousehold">
            Head of household (HOH)
          </Radio>
          <Radio value="qualifyingSurvivingSpouse">
            Qualifying surviving spouse (QSS)
          </Radio>
        </Radio.Group>
      </Form.Item>
    </Row>
    <Row justify={'space-around'}>
      <div className={style.fillingStatus}>Check only one box</div>
      <div>If you checked the MFS box, enter the name of spouse. <br />If you checked the HOH or QSS box, enter the child’s name if the qualifying
        person is a child but not your dependent.</div>
      <Form.Item name="termination_attributes_input">
        <Input
          type={"text"}
          
        />
      </Form.Item>
    </Row>
  </div>
})