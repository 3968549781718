import { Col, Form, Input, Radio, Row } from "antd";
import { observer } from "mobx-react";
import style from "../../style.module.scss";

export const Refunds = observer(() => {
    return (
      <Row className={style.formItemStyle}>
      <Col span={4}>
        <div>
          <h2>Refund
          </h2>
          <p>Direct deposit?
            See instructions

          </p>
        </div>
      </Col>
      <Col span={20}>
        <Row>
          <Col span={15}>
            <div><b>34</b>		If line 33 is more than line 24, subtract line 24 from line 33. This is the amount you overpaid																				.   .   .   .   .   . 																					</div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_34'}>
              <Input
                type={'text'}
                addonBefore={<b>34</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>35 a</b>Amount of line 34 you want <b>refunded to you</b>. If Form 8888 is attached, check here																																								.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_35a'}>
              <Input
                type={'text'}
                addonBefore={<b>35a</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>b</b>Routing number																												.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_35b'}>
              <Input
                type={'text'}
                addonBefore={<b>35b</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>c</b> Type																												.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
          </Col>
          <Col span={6}>
            <Radio.Group style={{ width: '100%', justifyContent: 'space-around', margin: '5px 0' }}>
              <Radio value='checking'>Checking		 </Radio>
              <Radio value='savings'>Savings
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>d</b>	Account number																															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_35c'}>
              <Input
                type={'text'}
                addonBefore={<b>35c</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>36</b>	Amount   of   line 34  you  want				<b>	applied to your 2023 estimated tax	</b>																																						.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_36'}>
              <Input
                type={'text'}
                addonBefore={<b>36</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
    )
})