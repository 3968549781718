import { notification } from "antd";

// functions that are commonly used are placed here
interface StandardDeductionType {
    single: number[];
    marriedFilingJointly: number[];
    marriedFilingSeparately: number[];
    headOfHousehold: number[];
    [key: string]: number[]; // Add the index signature
  }
export const getStandardDeduction = (filingStatus: string, taxableIncome: number, numCheckboxesChecked: number) => {
    // Standard deduction amounts for each filing status (2022)
    const standardDeductionChart: StandardDeductionType = {
      single: [13850, 15700, 17550],
      marriedFilingJointly: [27700, 29200, 30700, 32200],
      marriedFilingSeparately: [13850, 15350, 16850],
      headOfHousehold: [20800, 22650, 24500]
    };

    // Check if the filing status exists in the chart
    if (standardDeductionChart.hasOwnProperty(filingStatus)) {
      // Get the standard deduction amounts for the specified filing status
      const standardDeductions = standardDeductionChart[filingStatus];

      // Check if the number of checkboxes checked is within the valid range
      if (numCheckboxesChecked >= 0 && numCheckboxesChecked <= standardDeductions.length) {
        // Get the standard deduction amount based on the number of checkboxes checked
        let standardDeduction = standardDeductions[numCheckboxesChecked];

        // Check if the taxable income is greater than the standard deduction
        if (taxableIncome > standardDeduction) {
          return standardDeduction;
        } else {
          return taxableIncome;
        }
      } else {
        // throw new Error('Invalid number of checkboxes checked.');
        notification.error({ message: 'Invalid number of checkboxes checked.' });
      }
    } else {
      // throw new Error('Invalid filing status.');
      notification.error({ message: 'Invalid filing status.' });
    }
  };

export const sumValues = (values: any[]) => {
    let sum = 0;
    for (let i = 0; i < values.length; i++) {
        if (values[i] !== null && values[i] !== undefined && !isNaN(values[i]) && (typeof values[i] === 'number' || !isNaN(Number(values[i])))) {
            sum += Number(values[i]);
        }
    }
    return sum;
};
export const subtractValues = (a: number, b: number) => {
    a = Number(a) || 0;
    b = Number(b) || 0;
    return a - b;
};
export const calculateIncomeTax = (income: number, filingStatus: string) => {
    let tax = 0;

    // Define the tax rate brackets and amounts based on the filing status
    let brackets;

    if (filingStatus === "single") {
        brackets = [
            { bottom: 0, rate: 0.1 },
            { bottom: 11000, rate: 0.12 },
            { bottom: 44725, rate: 0.22 },
            { bottom: 95375, rate: 0.24 },
            { bottom: 182100, rate: 0.32 },
            { bottom: 231250, rate: 0.35 },
            { bottom: 578125, rate: 0.37 }
        ];
    } else if (filingStatus === "marriedFilingJointly") {
        brackets = [
            { bottom: 0, rate: 0.1 },
            { bottom: 22000, rate: 0.12 },
            { bottom: 89450, rate: 0.22 },
            { bottom: 190750, rate: 0.24 },
            { bottom: 364200, rate: 0.32 },
            { bottom: 462500, rate: 0.35 },
            { bottom: 693750, rate: 0.37 }
        ];
    } else if (filingStatus === "marriedFilingSeparately") {
        brackets = [
            { bottom: 0, rate: 0.1 },
            { bottom: 11000, rate: 0.12 },
            { bottom: 44725, rate: 0.22 },
            { bottom: 95375, rate: 0.24 },
            { bottom: 182100, rate: 0.32 },
            { bottom: 231250, rate: 0.35 },
            { bottom: 346875, rate: 0.37 }
        ];
    } else if (filingStatus === "headOfHousehold") {
        brackets = [
            { bottom: 0, rate: 0.1 },
            { bottom: 15700, rate: 0.12 },
            { bottom: 59850, rate: 0.22 },
            { bottom: 95350, rate: 0.24 },
            { bottom: 182100, rate: 0.32 },
            { bottom: 231250, rate: 0.35 },
            { bottom: 578100, rate: 0.37 }
        ];
    } else {
        return "Invalid filing status";
    }

    // Iterate through the brackets and calculate the tax
    for (let i = 0; i < brackets.length; i++) {
        const { bottom, rate } = brackets[i];
        const nextBracket = brackets[i + 1];
        const bracketAmount = nextBracket ? nextBracket.bottom - bottom : Infinity;

        if (income > bottom) {
            const taxableAmount = Math.min(income, bottom + bracketAmount) - bottom;
            tax += taxableAmount * rate;
        } else {
            break;
        }
    }

    return tax;
};

export const multiplyByPercent = (value: number, percent: number) => {
  var percentage = percent / 100;
  var result = value * percentage;
  return result;
};
