import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Checkbox, Col, Form, Input, Radio, Row } from 'antd';
import style from '../style.module.scss';
import classnames from 'classnames';
import { useStore } from "../../../store/root-store";

export const ScheduleD = observer(() => {
  const [scheduleDForm] = Form.useForm();
  const { setLoggedIn } = useStore('');

  useEffect(() => {
    setLoggedIn(true);
  }, []);
  return (
    <div className={style.incomeTaxReturnContainer}>
      <Form
        form={scheduleDForm}
        // onFinish={onFinish}
        name='formscheduleC'
        layout='vertical'
        // onValuesChange={handleValuesChange}
        scrollToFirstError
      >
        <Row className={style.formItemStyle}>
          <Col span={6} className={style.borderRight}>
            <h2>"SCHEDULE D
              (Form 1040)"
            </h2>
            <p>
              Department of the Treasury	<br />
              Internal Revenue Service
            </p>
          </Col>
          <Col span={12} className={style.alignCenter}>
            <h2>Capital Gains and Losses</h2>
            <h3>Attach to Form 1040, 1040-SR, or Form 1040-NR.</h3>
            <h4>     Go to www.irs.gov/ScheduleD for instructions and the latest information.	<br />
              Use Form 8949 to list your transactions for lines 1b, 2, 3, 8b, 9, and 10.
              <br />																											</h4>
          </Col>
          <Col span={6} className={classnames(style.borderLeft, style.alignCenter)}>
            <div className={style.borderBottom}>OMB No. 1545-0074					</div>
            <h2>###
            </h2>
            <p>"Attachment
              Sequence  <b>12</b>"
            </p>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>

          <Col span={16}>
            <Form.Item
              label={'Name of Proprietor'}
              name={'mainname'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}

              />
            </Form.Item>
          </Col>
          <Col span={8} className={style.borderLeft}>
            <Form.Item
              label={'  Social security number (SSN)'}
              name={'securityNumber'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}

              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={15}>Did you dispose of any investment(s) in a qualified opportunity fund during the tax year?										Yes
            If “Yes,” attach Form 8949 and see its instructions for additional requirements for reporting your gain or loss.
          </Col>
          <Col span={6}>
            <Form.Item name={'dispose_investment'} className={style.noMarginBottom}>
              <Checkbox.Group>
                <Checkbox value={'yes'}>Yes</Checkbox>
                <Checkbox value={'no'}>No</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row className={style.formItemStyle}>
          <Col span={3} style={{ background: '#121212', color: '#fff' }}>
            <div><b>Part I</b>
            </div>
          </Col>
          <Col span={11}><b>Short-Term Capital Gains and Losses			–  Generally Assets Held One Year or Less</b></Col>
          <Col span={6}>
          </Col>
        </Row>

        <Row className={style.borderedStyle}>
          <Col span={8}>See instructions for how to figure the amounts to enter on the
            lines below.
            This form may be easier to complete if you round off cents to whole dollars.
          </Col>
          <Col span={4}>(d)
            Proceeds
            (sales price)
          </Col>
          <Col span={4}>(e)
            Cost
            (or other basis)
          </Col>
          <Col span={4}>(g)
            Adjustments
            to gain or loss from
            Form(s) 8949, Part I,
            line 2, column (g)
          </Col>
          <Col span={4}>(h) Gain or (loss)
            Subtract column (e)
            from column (d) and
            combine the result
            with column (g)
          </Col>
        </Row>
        <Row className={style.borderedStyle}>
          <Col span={8}><b>1a</b>	Totals for all short-term transactions reported on Form  1099-B for which basis was reported to the IRS and for  which you have no adjustments (see instructions).  However, if you choose to report all these transactions  on Form 8949, leave this line blank and go to line 1b   .
          </Col>
          <Col span={4}>
            <Form.Item name={'field_1_input_1'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_1_input_2'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_1_input_3'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_1_input_4'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.borderedStyle}>
          <Col span={8}><b>1b</b>	Totals for all transactions reported on Form(s) 8949 with
            <b>Box A</b> checked  .   .   .   .   .   .   .   .   .   .   .   .   .   .   .

          </Col>
          <Col span={4}>
            <Form.Item name={'field_1_input_5'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_1_input_6'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_1_input_7'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_1_input_8'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.borderedStyle}>
          <Col span={8}><b>2</b>	Totals for all transactions reported on Form(s) 8949 with
            <b>Box B</b> checked .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .

          </Col>
          <Col span={4}>
            <Form.Item name={'field_1_input_9'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_1_input_10'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_1_input_11'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_1_input_12'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.borderedStyle}>
          <Col span={8}><b>3</b>	Totals for all transactions reported on Form(s) 8949 with
            <b>Box C</b> checked .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .

          </Col>
          <Col span={4}>
            <Form.Item name={'field_1_input_13'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_1_input_14'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_1_input_15'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_1_input_16'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>4</b></Col>
          <Col span={15}>
            <div>Short-term gain from Form 6252, and short-term gain or (loss) from Forms 4684, 6781, and 8824  .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_4'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>4</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>5</b></Col>
          <Col span={15}>
            <div>Net short-term gain or (loss) from partnerships, S corporations, estates, and trusts from
              Schedules(s) K-1									.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_5'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>5</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>6</b></Col>
          <Col span={15}>
            <div>Short-term capital loss carryover. Enter the amount, if any, from line 8 of your Capital Loss Carryover
              Worksheet in the instructions									.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_6'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>6</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>7</b></Col>
          <Col span={15}>
            <div>Net short-term capital gain or (loss). Combine lines 1a through 6 in column (h). If you have any long- term capital gains or losses, go to Part II below. Otherwise, go to Part III on the back  .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_7'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>7</b>}
              />
            </Form.Item>
          </Col>
        </Row>



        <Row className={style.formItemStyle}>
          <Col span={3} style={{ background: '#121212', color: '#fff' }}>
            <div><b>Part II</b>
            </div>
          </Col>
          <Col span={11}><b>Long-Term Capital Gains and Losses			–  Generally Assets Held More Than One Year		</b>					(see instructions)

          </Col>
          <Col span={6}>
          </Col>
        </Row>

        <div className={style.borderTop} />
        <Row className={style.borderedStyle}>
          <Col span={8}>See instructions for how to figure the amounts to enter on the
            lines below.
            This form may be easier to complete if you round off cents to
            whole dollars.
          </Col>
          <Col span={4}>(d)
            Proceeds
            (sales price)
          </Col>
          <Col span={4}>(e)
            Cost
            (or other basis)
          </Col>
          <Col span={4}>(g)
            Adjustments
            to gain or loss from
            Form(s) 8949, Part II,
            line 2, column (g)
          </Col>
          <Col span={4}>(h) Gain or (loss)
            Subtract column (e)
            from column (d) and
            combine the result
            with column (g)
          </Col>
        </Row>
        <Row className={style.borderedStyle}>
          <Col span={8}><b>2a</b>	Totals for all long-term transactions reported on Form
            1099-B for which basis was reported to the IRS and for
            which you have no adjustments (see instructions).
            However, if you choose to report all these transactions
            on Form 8949, leave this line blank and go to line 8b  .   .
          </Col>
          <Col span={4}>
            <Form.Item name={'field_2_input_1'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_2_input_2'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_2_input_3'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_2_input_4'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.borderedStyle}>
          <Col span={8}><b>8b</b>Totals for all transactions reported on Form(s) 8949 with
            <b>Box D</b> checked .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
          </Col>
          <Col span={4}>
            <Form.Item name={'field_2_input_5'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_2_input_6'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_2_input_7'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_2_input_8'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.borderedStyle}>
          <Col span={8}><b>9</b>Totals for all transactions reported on Form(s) 8949 with
            <b>Box E</b> checked .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
          </Col>
          <Col span={4}>
            <Form.Item name={'field_2_input_9'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_2_input_10'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_2_input_11'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_2_input_12'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.borderedStyle}>
          <Col span={8}><b>10</b>Totals for all transactions reported on Form(s) 8949 with
            <b>Box F</b> checked .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
          </Col>
          <Col span={4}>
            <Form.Item name={'field_2_input_13'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_2_input_14'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_2_input_15'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_2_input_16'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>11</b></Col>
          <Col span={15}>
            <div>Gain from Form 4797, Part I; long-term gain from Forms 2439 and 6252; and long-term gain or (loss)
              from Forms 4684, 6781, and 8824    .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_11'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>11</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>12</b></Col>
          <Col span={15}>
            <div>Net long-term gain or (loss) from partnerships, S corporations, estates, & trusts from Schedules(s) K-1
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_12'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>12</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>13</b></Col>
          <Col span={15}>
            <div>Capital gain distributions. See the instructions
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_13'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>13</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>14</b></Col>
          <Col span={15}>
            <div>Long-term capital loss carryover. Enter the amount, if any, from line 13 of your Capital Loss Carryover
              Worksheet in the instructions									.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_14'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>14</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>15</b></Col>
          <Col span={15}>
            <div>Net long-term capital gain or (loss). Combine lines 8a thru 14 in column (h). Then go to Part III on
              the back   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_15'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>15</b>}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className={style.formItemStyle}>

          <Col span={16}>
            <b>For Paperwork Reduction Act Notice, see your tax return instructions.															</b>
            <br />
            <div>Schedule D ( Form 1040)  2023		</div>
          </Col>
          <Col span={4}>Cat. No. 11338H			</Col>
          <Col span={4} >
            <b>			Schedule D (Form 1040)   2023</b>
            <br />
            <div>	Page <b>2</b></div>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={3} style={{ background: '#121212', color: '#fff' }}>
            <div><b>Part III</b>
            </div>
          </Col>
          <Col span={11}><b>Summary</b>

          </Col>
          <Col span={6}>
          </Col>
        </Row>

        <Row>
          <Col span={1}><b>16</b></Col>
          <Col span={15}>
            <div>Combine lines 7 and 15 and enter the result.									 .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_16'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>16</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>h</b></Col>
          <Col span={15}>
            <div>If line 16 is a gain, enter the amount from line 16 on Form 1040, 1040-SR, or 1040-NR, line 7.
              Then go to line 17 below.
            </div>
          </Col>
          <Col span={6}>

          </Col>
        </Row>
        <Row>
          <Col span={1}><b>h</b></Col>
          <Col span={15}>
            <div>If line 16 is a loss, skip lines 17 through 20 below. Then go to line 21. Also be sure to complete
              line 22.
            </div>
          </Col>
          <Col span={6}>

          </Col>
        </Row>
        <Row>
          <Col span={1}><b>h</b></Col>
          <Col span={15}>
            <div>If line 16 is zero, skip lines 17 through 21 below and enter -0- on Form 1040 or 1040-SR, line 7.
              Then go to line 22.
            </div>
          </Col>
          <Col span={6}>

          </Col>
        </Row>
        <Row>
          <Col span={1}><b>17</b></Col>
          <Col span={15}>
            <div>Are lines 15 and 16 both gains?</div>
          </Col>
          <Col span={6}>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b></b></Col>
          <Col span={15}>
            <div style={{display: 'flex', alignItems: 'center'}}><Form.Item name={'field_7'} className={style.noMarginBottom}>
              <Checkbox />
            </Form.Item>Yes. Go to line 18.
            </div>
          </Col>
          <Col span={6}>

          </Col>
        </Row>
        <Row>
          <Col span={1}><b></b></Col>
          <Col span={15}>
            <div style={{display: 'flex', alignItems: 'center'}}><Form.Item name={'field_7'} className={style.noMarginBottom}>
              <Checkbox />
            </Form.Item>No. Skip lines 18 through 21, and go to line 22.
            </div>
          </Col>
          <Col span={6}>

          </Col>
        </Row>
        <Row>
          <Col span={1}><b>18</b></Col>
          <Col span={15}>
            <div>If you are required to complete the 28% Rate Gain Worksheet (see instructions), enter
              the amount, if any, from line 7 of that worksheet									.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_18'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>18</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>19</b></Col>
          <Col span={15}>
            <div>If you are required to complete the Unrecaptured Section 1250 Gain Worksheet (see
              instructions), enter the amount, if any, from line 18 of that worksheet									.   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_19'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>19</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>20</b></Col>
          <Col span={15}>
            <div>Are lines 18 and 19 both zero or blank and are you not filing Form 4952?
            </div>
          </Col>
          <Col span={6}>

          </Col>
        </Row>
        <Row>
          <Col span={1}><b></b></Col>
          <Col span={15}>
            <div style={{display: 'flex', alignItems: 'center'}}><Form.Item name={'field_7'} className={style.noMarginBottom}>
              <Checkbox />
            </Form.Item>Yes. Complete the Qualified Dividends and Capital Gain Tax Worksheet in the instructions
              for Form 1040 or 1040-SR, line 16. Don't complete lines 21 and 22 below
            </div>
          </Col>
          <Col span={6}>

          </Col>
        </Row>
        <Row>
          <Col span={1}><b></b></Col>
          <Col span={15}>
            <div style={{display: 'flex', alignItems: 'center'}}><Form.Item name={'field_7'} className={style.noMarginBottom}>
              <Checkbox />
            </Form.Item>No. Complete the Schedule D Tax Worksheet in the instructions. Don't complete lines 21
              and 22 below.
            </div>
          </Col>
          <Col span={6}>

          </Col>
        </Row>
        <Row>
          <Col span={1}><b>21</b></Col>
          <Col span={15}>
            <div>If line 16 is a loss, enter here and on Form 1040, 1040-SR, 1040-NR, line 7, the smaller of:

              l  The loss on line 16 or	The loss on line 16 or								.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
              l  ($3,000), or if married filing separately, ($1,500)  	($3,000), or if married filing separately, ($1,500)
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_21'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>21</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b></b></Col>
          <Col span={15}>
            <div><b>Note:</b> When figuring which amount is smaller, treat both amounts as positive numbers.
            </div>
          </Col>
          <Col span={6}>

          </Col>
        </Row>
        <Row>
          <Col span={1}><b>22</b></Col>
          <Col span={15}>
            <div>Do you have qualified dividends on Form 1040, 1040-SR, or 1040-NR, line 3a?
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_22'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>22</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b></b></Col>
          <Col span={15}>
            <div style={{display: 'flex', alignItems: 'center'}}><Form.Item name={'field_7'} className={style.noMarginBottom}>
              <Checkbox />
            </Form.Item>Yes.  Complete the Qualified Dividends and Capital Gain Tax Worksheet in the instructions
              for Form 1040 or 1040-SR, line 16.
            </div>
          </Col>
          <Col span={6}>

          </Col>
        </Row>
        <Row>
          <Col span={1}><b></b></Col>
          <Col span={15}>
            <div style={{display: 'flex', alignItems: 'center'}}><Form.Item name={'field_7'} className={style.noMarginBottom}>
              <Checkbox />
            </Form.Item>No.  Complete the rest of Form 1040, 1040-SR, or 1040-NR.
            </div>
          </Col>
          <Col span={6}>

          </Col>
        </Row>
      </Form>
    </div>
  );
});