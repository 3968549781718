import { Checkbox, Col, Form, Input, Radio, Row, Space } from 'antd';
import style from '.././style.module.scss';
import BoxInput from '../../common-components/box-input/boxInput';
import { FilingStatus } from './sections/filingStatus';
import { useStore } from '../../../store/root-store';
import { AgeBlindness } from './sections/ageBlindness';
import { useEffect } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { firstFormCalculations } from '../../utils/formControl';
import { TaxAndCredits } from './sections/taxAndCredits';
import { Payments } from './sections/payments';
import { Refunds } from './sections/refunds';

export const FirstForm = observer(() => {
  const [form] = Form.useForm();
  const { setLoggedIn, firstFormStore: { handleFirstForm, loadFirstFormValues, getFirstForm, postFormData, setValuesInForms } } = useStore('');
  const loadData = async () => {
    const response = await loadFirstFormValues();
    form.setFieldsValue(response);
  };
  useEffect(() => {
    setLoggedIn(true);
  }, []);
  useEffect(() => {
    // Every time when values changed the form will perform calculations and field updated
    const calculatedData = firstFormCalculations({}, getFirstForm);
    form.setFieldsValue(calculatedData);
    setValuesInForms(calculatedData);
  }, [getFirstForm]);

  const handleValuesChange = (data: any, allValues: any) => {
    // set values in store 
    handleFirstForm(data, allValues);
  };
  return (
    <div className={style.incomeTaxReturnContainer}>
      <Form
        form={form}
        // onFinish={onFinish}
        layout='vertical'
        name='form1040'
        onValuesChange={handleValuesChange}
        scrollToFirstError
      >
        <Row>
          <Col md={12} sm={12} xs={24}>
            <Row gutter={20}>
              <Col className={style.formHeading} span={8}>
                <p>Form</p>
                <h1> 1040 </h1>
              </Col>
              <Col className={style.formHeadingRight} span={16}>
                <p>Department of the Treasury—Internal Revenue Service</p>
                <h2> U.S. Individual Income Tax Return </h2>
              </Col>
            </Row>
          </Col>
          <Col md={12} sm={12} xs={24}>
            <Row className={style.rightHeaderRow}>
              <Col
                className={style.borderedBoxHeaderRight}
                md={6}
                sm={6}
                xs={12}
              >
                <h1>2023</h1>
              </Col>
              <Col
                className={style.borderedBoxHeaderRight}
                md={6}
                sm={6}
                xs={12}
              >
                <p>OMB No. 1545-0074</p>
              </Col>
              <Col
                className={style.borderedBoxHeaderRight}
                md={12}
                sm={12}
                xs={24}
              >
                <p> IRS Use Only—Do not write or staple in this space </p>
              </Col>
            </Row>
          </Col>
        </Row>


        {/* Filing status section */}
        <FilingStatus />


        {/* Main information section start */}
        <Row>
          <Col md={9} sm={9} xs={24} className={style.borderRight}>
            <p>{'Your first name and middle initial'}</p>
            <Space.Compact size='middle'>
              <Form.Item name={'first_name'}>
                <Input type={'text'} />
              </Form.Item>
              <Form.Item name={'middle_initial'}>
                <Input type={'text'} />
              </Form.Item>
            </Space.Compact>
          </Col>
          <Col md={9} sm={9} xs={24} className={style.borderRight}>
            <Form.Item
              label={<p>{'Last name'}</p>}
              name={'last_name'}
            >
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col md={6} sm={6} xs={24}>
            <Form.Item
              label={<p>{'Your social security number'}</p>}
              name={'you_ssn'}
            >
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col md={9} sm={9} xs={24} className={classNames(style.borderRight, style.borderTop)}>
            <p>{"If joint return, spouse's first name and middle initial"}</p>
            <Space.Compact size='middle'>
              <Form.Item name={'spouse_first_name'}>
                <Input type={'text'} />
              </Form.Item>
              <Form.Item name={'spouse_middle_initial'}>
                <Input type={'text'} />
              </Form.Item>
            </Space.Compact>
          </Col>
          <Col md={9} sm={9} xs={24} className={classNames(style.borderRight, style.borderTop)}>
            <Form.Item
              label={<p>{'Last name'}</p>}
              name={'spouse_last_name'}
            >
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col md={6} sm={6} xs={24} className={classNames(style.borderBottom, style.borderTop)}>
            <Form.Item
              label={<p>{"Spouse's social security number"}</p>}
              name={'spouse_ssn'}
            >
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={18}>
            <Row className={style.fieldContainer}>
              <Col span={18}>
                <Form.Item
                  label={'Home address (number and street). If you have a P.O. box, see instructions.'}
                  name={'home_address'}
                  className={style.formItemStyle}
                  validateTrigger={['onBlur']}
                >
                  <Input
                    type={'text'}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={'Apt. no.'}
                  name={'apartment_no'}
                  className={style.formItemStyle}
                  validateTrigger={['onBlur']}
                >
                  <Input
                    type={'text'}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={'City, town, or post office.   If you have a foreign address, also complete spaces below.'}
                  name={'foreign_postal_code'}
                  className={style.formItemStyle}
                  validateTrigger={['onBlur']}
                >
                  <Input
                    type={'text'}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={'State'}
                  name={'state'}
                  className={style.formItemStyle}
                  validateTrigger={['onBlur']}
                >
                  <Input
                    type={'text'}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label={'ZIP Code'}
                  name={'zip'}
                  className={style.formItemStyle}
                  validateTrigger={['onBlur']}
                  initialValue={'001-01-0002'}
                >
                  <Input
                    type={'text'}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={"If joint return, spouse's first name and middle initial"}
                  name={'spouseFirstName'}
                  className={style.formItemStyle}
                  validateTrigger={['onBlur']}
                >
                  <Input
                    type={'text'}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={'Last name'}
                  name={'spouseLastName'}
                  className={style.formItemStyle}
                  validateTrigger={['onBlur']}
                >
                  <Input
                    type={'text'}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label={"Spouse's social security number"}
                  name={'spouseSocialSecurity'}
                  className={style.formItemStyle}
                  validateTrigger={['onBlur']}
                  initialValue={'001-01-0002'}
                >
                  <Input
                    type={'text'}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={6} style={{ textAlign: 'center' }}>
            <h4>Presidential Election Campaign</h4>
            <p style={{ padding: '10px 20px', textAlign: 'center' }}>"Check here if you, or your
              spouse if filing jointly, want $3
              to go to this fund.  Checking a
              box below will not change
              your tax or refund."</p>
            <Form.Item name='filingJointly'>
              <Radio.Group>
                <Radio value='you'>You</Radio>
                <Radio value='spouse'>
                  Spouse
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {/* Main information section end */}


        {/* Digital assets section start */}
        <Row justify={'space-around'} className={style.formItemStyle}>
          <h3 className={style.fillingStatus}>"Digital
            Assets"	</h3>
          <div>"At any time during 2022, did you (a) receive (as a reward, award, or payment for property or service); or (b) sell, <br />
            exchange, gift, or otherwise dispose of a digital asset (or a financial interest in a digital asset)? (See instructions.)"

          </div>
          <Form.Item name='digital_assets'>
            <Radio.Group>
              <Radio value={true}>YES</Radio>
              <Radio value={false}>
                NO
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Row>
        {/* Digital assets section end */}



        <Row justify={'space-around'} className={style.formItemStyle}>
          <h3 className={style.fillingStatus}>"Standard
            Deduction"</h3>
          <h4>Someone can claim:			</h4>
          <Form.Item name='digitalAssets'>
            <Checkbox.Group>
              <Checkbox value='yes'> You as a dependent		</Checkbox>
              <Checkbox value='no'>
                Your spouse as a dependent
              </Checkbox>
              <Checkbox value='yes'>  Spouse itemizes on a separate return or you were a dual-status alien		</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Row>

        <AgeBlindness />

        <Row className={style.formItemStyle}>
          <Col span={4}>
            <h3 className={style.fillingStatus}>Dependents
            </h3>
            <p>"If more
              than four
              dependents,
              see instructions
              and check"
              here		</p>
          </Col>
          <Col span={6}>
            <Form.Item
              label={<div style={{ padding: '10px' }}>{'(1)  First name				Last name	'}</div>}
              name={'firstName'}
              className={style.formItemStyle}
              validateTrigger={['onBlur']}
            >
              <Input
                type={'text'}
              />
              <Input
                type={'text'}
              />
              <Input
                type={'text'}
              />
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label={<div style={{ padding: '10px' }}>{`"(2) Social security 
              number"`}</div>}
              name={'lastName'}
              className={style.formItemStyle}
              validateTrigger={['onBlur']}
            >
              <Input
                type={'text'}
              />
              <Input
                type={'text'}
              />
              <Input
                type={'text'}
              />
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              label={<div style={{ padding: '10px' }}>{`"(3) Relationship 
              to you"	`}</div>}
              name={'spouseFirstName'}
              className={style.formItemStyle}
              validateTrigger={['onBlur']}
            >
              <Input
                type={'text'}
              />
              <Input
                type={'text'}
              />
              <Input
                type={'text'}
              />
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={<div>
                {`	(4)		if qualified for (see instructions):					`}<br />
                <div style={{ display: 'flex', gap: '80px' }}>
                  <div>{'Child tax credit'}</div>
                  <div>{'Credit for other dependents					'}</div>
                </div>
              </div>}
              name={'childTaxCredit'}
              className={style.formItemStyle}
              validateTrigger={['onBlur']}
            >
              <Checkbox.Group style={{ width: '100%', justifyContent: 'space-around', margin: '5px 0' }}>
                <Checkbox value='yes'> </Checkbox>
                <Checkbox value='no'>
                </Checkbox>
              </Checkbox.Group>
              <Checkbox.Group style={{ width: '100%', justifyContent: 'space-around', margin: '5px 0' }}>
                <Checkbox value='yes'> </Checkbox>
                <Checkbox value='no'>
                </Checkbox>
              </Checkbox.Group>
              <Checkbox.Group style={{ width: '100%', justifyContent: 'space-around', margin: '5px 0' }}>
                <Checkbox value='yes'> </Checkbox>
                <Checkbox value='no'>
                </Checkbox>
              </Checkbox.Group>
              <Checkbox.Group style={{ width: '100%', justifyContent: 'space-around', margin: '5px 0' }}>
                <Checkbox value='yes'> </Checkbox>
                <Checkbox value='no'>
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>


        {/* Income section start */}
        <Row className={style.formItemStyle}>
          <Col span={4}>
            <div>
              <h2>Income
              </h2>
              <h3>"Attach Form(s) W-2 here. Also attach Forms
                W-2G and
                1099-R if tax was withheld."
              </h3>
              <h4>"If you did not
                get a Form W-2,
                see instructions."
              </h4>
            </div>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={15}>
                <div><b>1	a</b>	Total amount from Form(s) W-2, box 1 (see instructions)																				.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_1a'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>1a</b>}
                    disabled
                    className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>b</b>	Household employee wages not reported on Form(s) W-2																													.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_1b'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>1b</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>c</b>	Tip income not reported on line 1a (see instructions)																												.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_1c'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>1c</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>d</b>	Medicaid waiver payments not reported on Form(s) W-2 (see instructions)																																.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_1d'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>1d</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>e</b>	Taxable dependent care benefits from Form 2441, line 26																														.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_1e'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>1e</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>f</b>	Employer provided adoption benefits from Form 8839, line 29																														.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_1f'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>1f</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>g</b>	Wages from Form 8919, line 6																									.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_1g'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>1g</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>h</b>	Other earned income (see instructions)																									.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_1h'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>1h</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>i</b>	Nontaxable combat pay election (see instructions)																										.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_1i'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>1i</b>}
                    disabled
                    className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>z</b>Add lines 1a through 1h																							.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_1z'}>
                  <Input
                    disabled
                    className={style.formulaInput}
                    type={'text'}
                    addonBefore={<b>1z</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Income section end */}


        {/* Schedule B section start */}
        <Row className={style.formItemStyle}>
          <Col span={4}>
            <div>
              <h3>"Attach Sch. B
                if required."
              </h3>
            </div>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={12}>
                <Row>
                  <Col span={14}>
                    <div><b>2	a</b>	Tax-exempt interest																				.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'field_2a'}>
                      <Input
                        type={'text'}
                        addonBefore={<b>2a</b>}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={14}>
                    <div><b>3 a</b>	Qualified dividends																													.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'field_3a'}>
                      <Input
                        type={'text'}
                        addonBefore={<b>3a</b>}
                      />
                    </Form.Item>
                  </Col>
                </Row>

              </Col>
              <Col span={12}>

                <Row>
                  <Col span={14}>
                    <div><b>b</b>	Taxable interest																															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'field_2b'}>
                      <Input
                        type={'text'}
                        addonBefore={<b>2b</b>}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={14}>
                    <div><b>b</b>	Ordinary dividends																																		.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'field_3b'}>
                      <Input
                        type={'text'}
                        addonBefore={<b>3b</b>}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

          </Col>
        </Row>
        {/* Schedule B section end */}


        {/* Standard deduction section start */}
        <Row className={style.formItemStyle}>
          <Col span={4}>
            <div>
              <h3>Standard
                Deduction for --
              </h3>
              <p>•	Single or married
                filing separately
                $12,950	<br />
                •	Married filing
                jointly or Qualifying
                surviving spouse
                $25,900 	<br />
                •	Head of house
                $19,400 	<br />
                •	If you checked any
                Standard Deduction
                box, see instructions. 		</p>
            </div>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={15}>
                <div><b>4	a</b>	IRA distributions																					.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_4a'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>4a</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>b</b>	Taxable amount							.   .   .   . 32  </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_4b'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>4b</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>5 a</b>	Pensions and annuities																												.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_5a'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>5a</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>b</b>	Taxable amount																																		.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_5b'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>5b</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>6 a</b>	Social security benefits																													.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_6a'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>6a</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>b</b>	Taxable amount																															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_6b'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>6b</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>6 c</b>	If you elect to use the lump-sum election method, check here (see instructions)																																						.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Checkbox value='yes'> </Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>7</b>Capital gain or (loss).  Attach Schedule D if required. If not required, check here																		.   .   .   .   .   .    .   .   .   .   . .   .
                  <Form.Item name={'field_7_checkbox'}>
                    <Checkbox />
                  </Form.Item>
                </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_7'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>7</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>8</b>	Other income from Schedule 1, line 10																																			.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_8'}>
                  <Input
                    type={'text'}
                    disabled
                    addonBefore={<b>8</b>}
                    className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>9</b>	Add lines 1z, 2b, 3b, 4b, 5b, 6b, 7, and 8. This is your <b>total income</b>																																										.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_9'}>
                  <Input
                    disabled
                    className={style.formulaInput}
                    type={'text'}
                    addonBefore={<b>9</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>10</b>	Adjustments to income from Schedule 1, line 26																																						.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_10'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>10</b>}
                    disabled
                    className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>11</b>	Subtract line 10 from line 9. This is your <b>adjusted gross income	</b>																																										.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_11'}>
                  <Input
                    disabled
                    className={style.formulaInput}
                    type={'text'}
                    addonBefore={<b>11</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>12</b>	<b>Standard deduction or itemized deductions</b> (from Schedule A)																																										.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_12'}>
                  <Input
                    disabled
                    className={style.formulaInput}
                    type={'text'}
                    addonBefore={<b>12</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>13</b>		Qualified business income deduction from Form 8995 or Form 8995-A																																								.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_13'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>13</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>14</b>	Add lines 12 and 13																													.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_14'}>
                  <Input
                    disabled
                    className={style.formulaInput}
                    type={'text'}
                    addonBefore={<b>14</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>15</b>	Subtract line 14 from line 11.					If zero or less, enter -0-.						This is your<b> taxable income	</b>																																		.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={'field_15'}
                >
                  <Input
                    disabled
                    className={style.formulaInput}
                    type={'text'}
                    addonBefore={<b>15</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Standard deduction section end */}


        <Row className={style.formItemStyle}>
          <Col span={16}>
            <b>For Disclosure, Privacy Act, and Paperwork Reduction Act Notice, see separate instructions.																</b>
            <p>Form 1040  (2022)	</p>
          </Col>
          <Col span={4}><h3>1040</h3></Col>
          <Col span={4}><h4>Page 2</h4></Col>
        </Row>

        {/* tax and credits section */}
        <TaxAndCredits />

        {/* payments section */}
        <Payments />

        {/* refunds section */}
        <Refunds />

        {/* amount you owe section start */}
        <Row className={style.formItemStyle}>
          <Col span={4}>
            <div>
              <h2>"Amount
                You Owe"
              </h2>

            </div>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={15}>
                <div><b>37</b>		Subtract line 33 from line 24. This is the amount you owe. 												<br />For details on how to pay, go to www.irs.gov/Payments or see instructions																																			</div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_37'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>37</b>}

                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>38</b>Estimated tax penalty (see instructions)										   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_38'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>38</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* amount you owe section end */}


        {/* Third Party Designee section start */}
        <Row className={style.formItemStyle}>
          <Col span={4}>
            <div>
              <h2>Third Party Designee
              </h2>
            </div>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={15}>
                <div>Do you want to allow another person to discuss this return with the IRS?																								<br />See instructions																							 .   .   .   .   .   .   .   .   .   .   .   .   .   .   .											</div>
              </Col>
              <Col span={6}>
                <Radio.Group style={{ width: '100%', justifyContent: 'space-around', margin: '5px 0' }}>
                  <Radio value='checking'>Yes.  Complete below.						 </Radio>
                  <Radio value='savings'>No
                  </Radio>
                </Radio.Group>
              </Col>
            </Row>
            <Row justify={'space-around'}>
              <Col span={2}>
                <div>Designee's name				 </div>
              </Col>
              <Col span={6}>
                <Input
                  type={'text'}
                />
              </Col>

              <Col span={2}>
                <div>Phone no.				 </div>
              </Col>
              <Col span={4}>
                <Input
                  type={'text'}
                />
              </Col>

              <Col span={4}>
                <div>"Personal identification
                  number (PIN)"						 </div>
              </Col>
              <Col span={4}>
                <BoxInput
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Third Party Designee section end */}


        {/* Sign Here section start */}
        <Row className={style.formItemStyle}>
          <Col span={4} style={{ textAlign: 'center' }}>
            <div>
              <h2>"Sign
                Here"
              </h2>
              <p>Joint return?<br />
                See instructions.</p>
              <p>Keep a copy<br />
                for your records.</p>
            </div>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={22}>
                <div>Under penalties of perjury, I declare that I have examined this return and accompanying schedules and statements, and to the best of my knowledge and<br />belief, they are true, correct, and complete. Declaration of preparer (other than taxpayer) is based on all information of which preparer has any knowledge.</div>
              </Col>

            </Row>
            <Row justify={'space-around'}>
              <Col span={7}>
                <div>Signature</div>
                <Input
                  type={'text'}
                />
              </Col>
              <Col span={5}>
                <div>Date</div>
                <Input
                  type={'text'}
                />
              </Col>
              <Col span={6}>
                <div>Your occupation						</div>
                <Input
                  type={'text'}
                />
              </Col>

              <Col span={4}>
                <div>If the IRS sent you an identity Protection PIN, enter it here
                </div>
                <BoxInput />
              </Col>
            </Row>
            <Row justify={'space-around'}>
              <Col span={7}>
                <div>Spouse’s signature. If a joint return, <b>both</b> must sign.								</div>
                <Input
                  type={'text'}
                />
              </Col>
              <Col span={5}>
                <div>Date</div>
                <Input
                  type={'text'}
                />
              </Col>
              <Col span={6}>
                <div>Spouse's occupation							</div>
                <Input
                  type={'text'}
                />
              </Col>

              <Col span={4}>
                <div>If the IRS sent you an identity Protection PIN, enter it here
                </div>
                <BoxInput />
              </Col>
            </Row>
            <Row justify={'space-around'}>
              <Col span={2}><div>Phone no.		</div></Col>
              <Col span={6}>
                <Input
                  type={'text'}
                />
              </Col>
              <Col span={4}></Col>
              <Col span={2}><div>Email address			</div></Col>
              <Col span={6}>
                <Input
                  type={'text'}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Sign Here section end */}


        {/* Paid Preparers use section start */}
        <Row className={style.formItemStyle}>
          <Col span={4} style={{ textAlign: 'center' }}>
            <div>
              <h2>"Paid
                Preparers
                Use Only"
              </h2>
            </div>
          </Col>
          <Col span={20}>

            <Row justify={'space-around'} style={{ marginBottom: '20px' }}>
              <Col span={4}>
                <div>Preparer’s name						</div>
                <Input
                  type={'text'}
                />
              </Col>
              <Col span={4}>
                <div>Preparer’s signature							</div>
                <Input
                  type={'text'}
                />
              </Col>
              <Col span={4}>
                <div>Date								</div>
                <Input
                  type={'text'}
                />
              </Col>
              <Col span={4}>
                <div>PTIN									</div>
                <Input
                  type={'text'}
                />
              </Col>
              <Col span={4}>
                <div>Check if:							</div>
                <Checkbox.Group>
                  <Checkbox>Self-employed		</Checkbox>
                </Checkbox.Group>
              </Col>
            </Row>

            <Row justify={'space-around'} style={{ marginBottom: '10px' }}>
              <Col span={2}><div>Firm’s name			</div></Col>
              <Col span={8}>
                <Input
                  type={'text'}
                />
              </Col>
              <Col span={2}></Col>
              <Col span={2}><div>Phone no.			</div></Col>
              <Col span={7}>
                <Input
                  type={'text'}
                />
              </Col>
            </Row>

            <Row justify={'space-around'}>
              <Col span={2}><div>Firm’s address					</div></Col>
              <Col span={8}>
                <Input
                  type={'text'}
                />
              </Col>
              <Col span={2}></Col>
              <Col span={2}><div>Firm's EIN			</div></Col>
              <Col span={7}>
                <Input
                  type={'text'}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Paid Preparers use section end */}

      </Form>
    </div>
  );
});
