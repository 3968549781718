import { Button, Checkbox, Form, Input } from "antd";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { useStore } from "../../../store/root-store";
import { useEffect } from "react";

export const LoginPage = observer(() => {
  const { setLoggedIn } = useStore('');
  const navigate = useNavigate();
  useEffect(() => {
    setLoggedIn(false);
  }, []);
  const onFinish = (values: any) => {
    console.log("Success:", values);
    setLoggedIn(true);
    navigate(`/form_w2`);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <LoginPageContainer>
      <StyledForm
        name="basic"
        layout='vertical'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <StyledFormItem
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input />
        </StyledFormItem>

        <StyledFormItem
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </StyledFormItem>

        <StyledFormItem name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </StyledFormItem>

        <StyledFormItem>
          <StyledButton type="primary" htmlType="submit">
            Submit
          </StyledButton>
        </StyledFormItem>
      </StyledForm>
    </LoginPageContainer>
  );
});



const LoginPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const StyledForm = styled(Form)`
  max-width: 600px;
  width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;
