import { Col, Form, Input, Row } from "antd";
import { observer } from "mobx-react";
import style from "../../style.module.scss";

export const Payments = observer(() => {
    return (
      <Row className={style.formItemStyle}>
      <Col span={4}>
        <div>
          <h2>Payments
          </h2>
          <p style={{ marginTop: '120px' }}>If you have a qualifying child, attach Sch EIC.

          </p>
        </div>
      </Col>
      <Col span={20}>
        <Row>
          <Col span={15}>
            <div><b>25</b>	Federal income tax withheld from:																							</div>
          </Col>
          <Col span={6}>

          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>a</b>Form(s) W-2																												.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_25a'}>
              <Input
                type={'text'}
                addonBefore={<b>25a</b>}
                disabled
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>b</b>Form(s) 1099																												.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_25b'}>
              <Input
                type={'text'}
                addonBefore={<b>25b</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>c</b>	Other forms (see instructions)																																	.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_25c'}>
              <Input
                type={'text'}
                addonBefore={<b>25c</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>d</b>	Add lines 25a through 25c																																	.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_25d'}>
              <Input
                type={'text'}
                addonBefore={<b>25d</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>26</b>		2022 estimated tax payments and amount applied from 2021 return																																								.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_26'}>
              <Input
                type={'text'}
                addonBefore={<b>26</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>27</b>	Earned income credit (EIC)																											.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_27'}>
              <Input
                type={'text'}
                addonBefore={<b>27</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>28</b>	Additional child tax credit from Schedule 8812																															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_28'}>
              <Input
                type={'text'}
                addonBefore={<b>28</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>29</b>	American opportunity credit from Form 8863, line 8																																		.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_29'}>
              <Input
                type={'text'}
                addonBefore={<b>29</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>30</b>	Reserved for future use																								.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_30'}>
              <Input
                type={'text'}
                addonBefore={<b>30</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>31</b>Amount from Schedule 3, line 15																									.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_31'}>
              <Input
                type={'text'}
                addonBefore={<b>31</b>}
                disabled
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>32</b>Add lines 27, 28, 29, and 31. These are your <b>total other payments and refundable credits</b>																																							.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_32'}>
              <Input
                type={'text'}
                addonBefore={<b>32</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>33</b>Add lines 25d, 26, and 32. These are your <b>total payments	</b>																														.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_33'}>
              <Input
                type={'text'}
                addonBefore={<b>33</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
    )
})