import { LinkOutlined } from "@ant-design/icons";
import { Button, Menu, MenuProps } from "antd";
import { observer } from "mobx-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../store/root-store";

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}
export const NavBar = observer(() => {
    const {setLoggedIn} = useStore('');
    const navigate = useNavigate()
    const location = useLocation();
    const items: MenuItem[] = [
        getItem(<Link to="/form_w2">Form W-2</Link>, '/form_w2', <LinkOutlined />),
        getItem(<Link to="/form1040">Form 1040</Link>, '/form1040', <LinkOutlined />),
        getItem(<Link to="/sch1">Sch. 1</Link>, '/sch1', <LinkOutlined />),
        getItem(<Link to="/sch2">Sch. 2</Link>, '/sch2', <LinkOutlined />),
        getItem(<Link to="/sch3">Sch. 3</Link>, '/sch3', <LinkOutlined />),
        getItem(<Link to="/sch_a">Sch. A</Link>, '/sch_a', <LinkOutlined />),
        getItem(<Link to="/sch_b">Sch. B</Link>, '/sch_b', <LinkOutlined />),
        getItem(<Link to="/sch_c">Sch. C</Link>, '/sch_c', <LinkOutlined />),
        getItem(<Link to="/sch_d">Sch. D</Link>, '/sch_d', <LinkOutlined />),
        getItem(<Link to="/sch_e">Sch. E</Link>, '/sch_e', <LinkOutlined />),
    ];
    return (
        <>
            <nav style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                <h3 style={{color: '#2D6CCA'}}>Tax Engine</h3>
                <Menu
                    defaultSelectedKeys={[`${location.pathname}`]}
                    mode={'horizontal'}
                    theme={'light'}
                    items={items}
                />
                <Button onClick={() => {
                    setLoggedIn(false);
                    navigate('/');
                }}>Logout</Button>
            </nav>
            
        </>
    )
})