import { Col, Form, Input, Row } from "antd";
import { observer } from "mobx-react";
import style from "../../style.module.scss";

export const TaxAndCredits = observer(() => {
    return (
        <Row className={style.formItemStyle}>
          <Col span={4}>
            <div>
              <h2>Tax and
                Credits
              </h2>
            </div>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={15}>
                <div><b>16</b>	<b>Tax</b> (see instructions).  Check if any from Form(s):																											.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_16'}>
                  <Input
                    disabled
                    className={style.formulaInput}
                    type={'text'}
                    addonBefore={<b>16</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>17</b>		Amount from Schedule 2, line 3																																	.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_17'}>
                  <Input
                    type={'text'}
                    disabled
                    className={style.formulaInput}
                    addonBefore={<b>17</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>18</b>		Add lines 16 and 17																														.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_18'}>
                  <Input
                    disabled
                    className={style.formulaInput}
                    type={'text'}
                    addonBefore={<b>18</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>19</b>Child tax credit or credit for other dependents from Schedule 8812																																												.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_19'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>19</b>}
                    
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>20</b>		Amount from Schedule 3, line 8																																			.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_20'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>20</b>}
                    disabled
                    className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>21</b>		Add lines 19 and 20																																	.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_21'}>
                  <Input
                    disabled
                    className={style.formulaInput}
                    type={'text'}
                    addonBefore={<b>21</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>22</b>	Subtract line 21 from line 18. If zero or less, enter -0-																																	.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_22'}>
                  <Input
                    disabled
                    className={style.formulaInput}
                    type={'text'}
                    addonBefore={<b>22</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>23</b>	Other taxes, including self-employment tax, from Schedule 2, line 21																																			.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_23'}>
                  <Input
                    type={'text'}
                    addonBefore={<b>23</b>}
                    disabled
                    className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>24</b>Add lines 22 and 23. This is your <b>total tax	</b>																															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_24'}>
                  <Input
                    disabled
                    className={style.formulaInput}
                    type={'text'}
                    addonBefore={<b>24</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
    )
})