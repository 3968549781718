import { types } from "mobx-state-tree";

export const firstFormModel = types.model({
    Were_born_before_1958: types.maybeNull(types.boolean),
    apartment_no: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    claimed_as_dependent: types.maybeNull(types.boolean),
    dependents: types.maybeNull(types.array(types.frozen({}))),
    digital_assets: types.maybeNull(types.boolean),
    email_address: types.maybeNull(types.string),
    field_1a: types.maybeNull(types.string),
    field_1b: types.maybeNull(types.string),
    field_1c: types.maybeNull(types.string),
    field_1d: types.maybeNull(types.string),
    field_1e: types.maybeNull(types.string),
    field_1f: types.maybeNull(types.string),
    field_1g: types.maybeNull(types.string),
    field_1h: types.maybeNull(types.string),
    field_1i: types.maybeNull(types.string),
    field_1z: types.maybeNull(types.string),
    field_2a: types.maybeNull(types.string),
    field_2b: types.maybeNull(types.string),
    field_3a: types.maybeNull(types.string),
    field_3b: types.maybeNull(types.string),
    field_4a: types.maybeNull(types.string),
    field_4b: types.maybeNull(types.string),
    field_5a: types.maybeNull(types.string),
    field_5b: types.maybeNull(types.string),
    field_6a: types.maybeNull(types.string),
    field_6b: types.maybeNull(types.string),
    field_6c: types.maybeNull(types.string),
    field_7: types.maybeNull(types.string),
    field_7_checkbox: types.maybeNull(types.boolean),
    field_8: types.maybeNull(types.string),
    field_9: types.maybeNull(types.string),
    field_10: types.maybeNull(types.string),
    field_11: types.maybeNull(types.string),
    field_12: types.maybeNull(types.string),
    field_13: types.maybeNull(types.string),
    field_14: types.maybeNull(types.string),
    field_15: types.maybeNull(types.string),
    field_16: types.maybeNull(types.string),
    field_17: types.maybeNull(types.string),
    field_18: types.maybeNull(types.string),
    field_19: types.maybeNull(types.string),
    field_20: types.maybeNull(types.string),
    field_21: types.maybeNull(types.string),
    field_22: types.maybeNull(types.string),
    field_23: types.maybeNull(types.string),
    field_24: types.maybeNull(types.string),
    field_25a: types.maybeNull(types.string),
    field_25b: types.maybeNull(types.string),
    field_25c: types.maybeNull(types.string),
    field_25d: types.maybeNull(types.string),
    field_26: types.maybeNull(types.string),
    field_27: types.maybeNull(types.string),
    field_28: types.maybeNull(types.string),
    field_29: types.maybeNull(types.string),
    field_30: types.maybeNull(types.string),
    field_31: types.maybeNull(types.string),
    field_32: types.maybeNull(types.string),
    field_33: types.maybeNull(types.string),
    field_34: types.maybeNull(types.string),
    field_35a: types.maybeNull(types.string),
    field_35b: types.maybeNull(types.string),
    field_35c: types.maybeNull(types.string),
    field_35d: types.maybeNull(types.string),
    field_36: types.maybeNull(types.string),
    field_37: types.maybeNull(types.string),
    field_38: types.maybeNull(types.string),
    filing_status: types.maybeNull(types.string),
    first_name: types.maybeNull(types.string),
    foreign_country: types.maybeNull(types.string),
    foreign_postal_code: types.maybeNull(types.string),
    foreign_province: types.maybeNull(types.string),
    home_address: types.maybeNull(types.string),
    id: types.maybeNull(types.string),
    itemizing: types.maybeNull(types.boolean),
    last_name: types.maybeNull(types.string),
    middle_initial: types.maybeNull(types.string),
    need_schedule_3_part_i: types.maybeNull(types.boolean),
    string_dependents: types.maybeNull(types.string),
    occupation: types.maybeNull(types.string),
    phone_string: types.maybeNull(types.string),
    schedule_1_additional_income: types.maybeNull(types.boolean),
    schedule_2_part_i_needed: types.maybeNull(types.boolean),
    spouse_blind: types.maybeNull(types.boolean),
    spouse_born_before_1958: types.maybeNull(types.boolean),
    spouse_first_name: types.maybeNull(types.string),
    spouse_last_name: types.maybeNull(types.string),
    spouse_middle_initial: types.maybeNull(types.string),
    spouse_occupation: types.maybeNull(types.string),
    spouse_presidential_election: types.maybeNull(types.boolean),
    spouse_ssn: types.maybeNull(types.string),
    standard_deduction_exceptions: types.maybeNull(types.boolean),
    state: types.maybeNull(types.string),
    you_blind: types.maybeNull(types.boolean),
    you_presidential_election: types.maybeNull(types.boolean),
    you_ssn: types.maybeNull(types.string),
    zip: types.maybeNull(types.string),
});

export const schedule1FormModel = types.model({
    field_8a: types.maybeNull(types.string),
    field_8b: types.maybeNull(types.string),
    field_8c: types.maybeNull(types.string),
    field_8d: types.maybeNull(types.string),
    field_8e: types.maybeNull(types.string),
    field_8f: types.maybeNull(types.string),
    field_8g: types.maybeNull(types.string),
    field_8h: types.maybeNull(types.string),
    field_8i: types.maybeNull(types.string),
    field_8j: types.maybeNull(types.string),
    field_8k: types.maybeNull(types.string),
    field_8l: types.maybeNull(types.string),
    field_8m: types.maybeNull(types.string),
    field_8n: types.maybeNull(types.string),
    field_8o: types.maybeNull(types.string),
    field_8p: types.maybeNull(types.string),
    field_8q: types.maybeNull(types.string),
    field_8r: types.maybeNull(types.string),
    field_8s: types.maybeNull(types.string),
    field_8t: types.maybeNull(types.string),
    field_8u: types.maybeNull(types.string),
    field_8z: types.maybeNull(types.string),
    field_1: types.maybeNull(types.string),
    field_2a: types.maybeNull(types.string),
    field_2b: types.maybeNull(types.string),
    field_3: types.maybeNull(types.string),
    field_4: types.maybeNull(types.string),
    field_5: types.maybeNull(types.string),
    field_6: types.maybeNull(types.string),
    field_7: types.maybeNull(types.string),
    field_24a: types.maybeNull(types.string),
    field_24b: types.maybeNull(types.string),
    field_24c: types.maybeNull(types.string),
    field_24d: types.maybeNull(types.string),
    field_24e: types.maybeNull(types.string),
    field_24f: types.maybeNull(types.string),
    field_24g: types.maybeNull(types.string),
    field_24h: types.maybeNull(types.string),
    field_24i: types.maybeNull(types.string),
    field_24j: types.maybeNull(types.string),
    field_24k: types.maybeNull(types.string),
    field_24z: types.maybeNull(types.string),
    field_11: types.maybeNull(types.string),
    field_12: types.maybeNull(types.string),
    field_13: types.maybeNull(types.string),
    field_14: types.maybeNull(types.string),
    field_15: types.maybeNull(types.string),
    field_16: types.maybeNull(types.string),
    field_17: types.maybeNull(types.string),
    field_18: types.maybeNull(types.string),
    field_19a: types.maybeNull(types.string),
    field_20: types.maybeNull(types.string),
    field_21: types.maybeNull(types.string),
    field_22: types.maybeNull(types.string),
    field_23: types.maybeNull(types.string),
    field_9: types.maybeNull(types.string),
    field_10: types.maybeNull(types.string),
    field_25: types.maybeNull(types.string),
    field_26: types.maybeNull(types.string),
});

export const schedule2FormModel = types.model({
    field_1: types.maybeNull(types.string),
    field_2: types.maybeNull(types.string),
    field_3: types.maybeNull(types.string),
    field_4: types.maybeNull(types.string),
    field_5: types.maybeNull(types.string),
    field_6: types.maybeNull(types.string),
    field_7: types.maybeNull(types.string),
    field_8: types.maybeNull(types.string),
    field_9: types.maybeNull(types.string),
    field_10: types.maybeNull(types.string),
    field_11: types.maybeNull(types.string),
    field_12: types.maybeNull(types.string),
    field_13: types.maybeNull(types.string),
    field_14: types.maybeNull(types.string),
    field_15: types.maybeNull(types.string),
    field_16: types.maybeNull(types.string),
    field_17a: types.maybeNull(types.string),
    field_17b: types.maybeNull(types.string),
    field_17c: types.maybeNull(types.string),
    field_17d: types.maybeNull(types.string),
    field_17e: types.maybeNull(types.string),
    field_17f: types.maybeNull(types.string),
    field_17g: types.maybeNull(types.string),
    field_17h: types.maybeNull(types.string),
    field_17i: types.maybeNull(types.string),
    field_17j: types.maybeNull(types.string),
    field_17k: types.maybeNull(types.string),
    field_17l: types.maybeNull(types.string),
    field_17m: types.maybeNull(types.string),
    field_17n: types.maybeNull(types.string),
    field_17o: types.maybeNull(types.string),
    field_17p: types.maybeNull(types.string),
    field_17q: types.maybeNull(types.string),
    field_17z: types.maybeNull(types.string),
    field_18: types.maybeNull(types.string),
    field_19: types.maybeNull(types.string),
    field_20: types.maybeNull(types.string),
    field_21: types.maybeNull(types.string),
});

export const schedule3FormModel = types.model({
    field_1: types.maybeNull(types.string),
    field_2: types.maybeNull(types.string),
    field_3: types.maybeNull(types.string),
    field_4: types.maybeNull(types.string),
    field_5: types.maybeNull(types.string),
    field_6a: types.maybeNull(types.string),
    field_6b: types.maybeNull(types.string),
    field_6c: types.maybeNull(types.string),
    field_6d: types.maybeNull(types.string),
    field_6e: types.maybeNull(types.string),
    field_6f: types.maybeNull(types.string),
    field_6g: types.maybeNull(types.string),
    field_6h: types.maybeNull(types.string),
    field_6i: types.maybeNull(types.string),
    field_6j: types.maybeNull(types.string),
    field_6k: types.maybeNull(types.string),
    field_6l: types.maybeNull(types.string),
    field_6z: types.maybeNull(types.string),
    field_7: types.maybeNull(types.string),
    field_8: types.maybeNull(types.string),
    field_9: types.maybeNull(types.string),
    field_10: types.maybeNull(types.string),
    field_11: types.maybeNull(types.string),
    field_12: types.maybeNull(types.string),
    field_13a: types.maybeNull(types.string),
    field_13b: types.maybeNull(types.string),
    field_13c: types.maybeNull(types.string),
    field_13d: types.maybeNull(types.string),
    field_13e: types.maybeNull(types.string),
    field_13f: types.maybeNull(types.string),
    field_13g: types.maybeNull(types.string),
    field_13h: types.maybeNull(types.string),
    field_13z: types.maybeNull(types.string),
    field_14: types.maybeNull(types.string),
    field_15: types.maybeNull(types.string),
});

export const scheduleAFormModel = types.model({
    field_1: types.maybeNull(types.string),
    field_2: types.maybeNull(types.string),
    field_3: types.maybeNull(types.string),
    field_4: types.maybeNull(types.string),
    field_5a: types.maybeNull(types.string),
    field_5b: types.maybeNull(types.string),
    field_5c: types.maybeNull(types.string),
    field_5d: types.maybeNull(types.string),
    field_5e: types.maybeNull(types.string),
    field_6: types.maybeNull(types.string),
    field_7: types.maybeNull(types.string),
    field_8a: types.maybeNull(types.string),
    field_8b: types.maybeNull(types.string),
    field_8c: types.maybeNull(types.string),
    field_8d: types.maybeNull(types.string),
    field_8e: types.maybeNull(types.string),
    field_9: types.maybeNull(types.string),
    field_10: types.maybeNull(types.string),
    field_11: types.maybeNull(types.string),
    field_12: types.maybeNull(types.string),
    field_13: types.maybeNull(types.string),
    field_14: types.maybeNull(types.string),
    field_15: types.maybeNull(types.string),
    field_16: types.maybeNull(types.string),
    field_17: types.maybeNull(types.string),
    field_18: types.maybeNull(types.string),
});

export const scheduleBFormModel = types.model({
    field_1_amount_0: types.maybeNull(types.string),
    field_1_amount_1: types.maybeNull(types.string),
    field_1_amount_2: types.maybeNull(types.string),
    field_1_amount_3: types.maybeNull(types.string),
    field_1_amount_4: types.maybeNull(types.string),
    field_1_amount_5: types.maybeNull(types.string),
    field_1_amount_6: types.maybeNull(types.string),
    field_1_amount_7: types.maybeNull(types.string),
    field_1_amount_8: types.maybeNull(types.string),
    field_1_amount_9: types.maybeNull(types.string),
    field_1_amount_10: types.maybeNull(types.string),
    field_1_amount_11: types.maybeNull(types.string),
    field_1_amount_12: types.maybeNull(types.string),
    field_1_amount_13: types.maybeNull(types.string),
    field_1_amount_14: types.maybeNull(types.string),
    field_1_desc_0: types.maybeNull(types.string),
    field_1_desc_1: types.maybeNull(types.string),
    field_1_desc_2: types.maybeNull(types.string),
    field_1_desc_3: types.maybeNull(types.string),
    field_1_desc_4: types.maybeNull(types.string),
    field_1_desc_5: types.maybeNull(types.string),
    field_1_desc_6: types.maybeNull(types.string),
    field_1_desc_7: types.maybeNull(types.string),
    field_1_desc_8: types.maybeNull(types.string),
    field_1_desc_9: types.maybeNull(types.string),
    field_1_desc_10: types.maybeNull(types.string),
    field_1_desc_11: types.maybeNull(types.string),
    field_1_desc_12: types.maybeNull(types.string),
    field_1_desc_13: types.maybeNull(types.string),
    field_1_desc_14: types.maybeNull(types.string),
    field_2: types.maybeNull(types.string),
    field_3: types.maybeNull(types.string),
    field_4: types.maybeNull(types.string),
    field_5_amount_0: types.maybeNull(types.string),
    field_5_amount_1: types.maybeNull(types.string),
    field_5_amount_2: types.maybeNull(types.string),
    field_5_amount_3: types.maybeNull(types.string),
    field_5_amount_4: types.maybeNull(types.string),
    field_5_amount_5: types.maybeNull(types.string),
    field_5_amount_6: types.maybeNull(types.string),
    field_5_amount_7: types.maybeNull(types.string),
    field_5_amount_8: types.maybeNull(types.string),
    field_5_amount_9: types.maybeNull(types.string),
    field_5_amount_10: types.maybeNull(types.string),
    field_5_amount_11: types.maybeNull(types.string),
    field_5_amount_12: types.maybeNull(types.string),
    field_5_amount_13: types.maybeNull(types.string),
    field_5_amount_14: types.maybeNull(types.string),
    field_5_desc_0: types.maybeNull(types.string),
    field_5_desc_1: types.maybeNull(types.string),
    field_5_desc_2: types.maybeNull(types.string),
    field_5_desc_3: types.maybeNull(types.string),
    field_5_desc_4: types.maybeNull(types.string),
    field_5_desc_5: types.maybeNull(types.string),
    field_5_desc_6: types.maybeNull(types.string),
    field_5_desc_7: types.maybeNull(types.string),
    field_5_desc_8: types.maybeNull(types.string),
    field_5_desc_9: types.maybeNull(types.string),
    field_5_desc_10: types.maybeNull(types.string),
    field_5_desc_11: types.maybeNull(types.string),
    field_5_desc_12: types.maybeNull(types.string),
    field_5_desc_13: types.maybeNull(types.string),
    field_5_desc_14: types.maybeNull(types.string),
    field_6: types.maybeNull(types.string),
    field_7a: types.maybeNull(types.string),
    field_7a_yes: types.maybeNull(types.string),
    field_7b: types.maybeNull(types.string),
    field_8: types.maybeNull(types.string),
});

export const scheduleCFormModel = types.model({
    field_1: types.maybeNull(types.string),
    field_2: types.maybeNull(types.string),
    field_3: types.maybeNull(types.string),
    field_4: types.maybeNull(types.string),
    field_5: types.maybeNull(types.string),
    field_6: types.maybeNull(types.string),
    field_7: types.maybeNull(types.string),
    field_8: types.maybeNull(types.string),
    field_9: types.maybeNull(types.string),
    field_10: types.maybeNull(types.string),
    field_11: types.maybeNull(types.string),
    field_12: types.maybeNull(types.string),
    field_13: types.maybeNull(types.string),
    field_14: types.maybeNull(types.string),
    field_15: types.maybeNull(types.string),
    field_16a: types.maybeNull(types.string),
    field_16b: types.maybeNull(types.string),
    field_17: types.maybeNull(types.string),
    field_18: types.maybeNull(types.string),
    field_19: types.maybeNull(types.string),
    field_20a: types.maybeNull(types.string),
    field_20b: types.maybeNull(types.string),
    field_21: types.maybeNull(types.string),
    field_22: types.maybeNull(types.string),
    field_23: types.maybeNull(types.string),
    field_24a: types.maybeNull(types.string),
    field_24b: types.maybeNull(types.string),
    field_25: types.maybeNull(types.string),
    field_26: types.maybeNull(types.string),
    field_27a: types.maybeNull(types.string),
    field_27b: types.maybeNull(types.string),
    field_28: types.maybeNull(types.string),
    field_29: types.maybeNull(types.string),
    field_30: types.maybeNull(types.string),
    field_31: types.maybeNull(types.string),
    field_32: types.maybeNull(types.string),
    field_33: types.maybeNull(types.string),
    field_34: types.maybeNull(types.string),
    field_35: types.maybeNull(types.string),
    field_36: types.maybeNull(types.string),
    field_37: types.maybeNull(types.string),
    field_38: types.maybeNull(types.string),
    field_39: types.maybeNull(types.string),
    field_40: types.maybeNull(types.string),
    field_41: types.maybeNull(types.string),
    field_42: types.maybeNull(types.string),
    field_43: types.maybeNull(types.string),
    field_44a: types.maybeNull(types.string),
    field_44b: types.maybeNull(types.string),
    field_44c: types.maybeNull(types.string),
    field_45: types.maybeNull(types.string),
    field_46: types.maybeNull(types.string),
    field_47a: types.maybeNull(types.string),
    field_47b: types.maybeNull(types.string),
    field_48: types.maybeNull(types.string),
    field_g: types.maybeNull(types.string),
    field_h: types.maybeNull(types.string),
    field_i: types.maybeNull(types.string),
    field_j: types.maybeNull(types.string),
    field_pre_48_amount_1: types.maybeNull(types.string),
    field_pre_48_amount_2: types.maybeNull(types.string),
    field_pre_48_amount_3: types.maybeNull(types.string),
    field_pre_48_amount_4: types.maybeNull(types.string),
    field_pre_48_amount_5: types.maybeNull(types.string),
    field_pre_48_amount_6: types.maybeNull(types.string),
    field_pre_48_amount_7: types.maybeNull(types.string),
    field_pre_48_amount_8: types.maybeNull(types.string),
    field_pre_48_amount_9: types.maybeNull(types.string),
    field_pre_48_input_1: types.maybeNull(types.string),
    field_pre_48_input_2: types.maybeNull(types.string),
    field_pre_48_input_3: types.maybeNull(types.string),
    field_pre_48_input_4: types.maybeNull(types.string),
    field_pre_48_input_5: types.maybeNull(types.string),
    field_pre_48_input_6: types.maybeNull(types.string),
    field_pre_48_input_7: types.maybeNull(types.string),
    field_pre_48_input_8: types.maybeNull(types.string),
    field_pre_48_input_9: types.maybeNull(types.string),
});

export const formW2Model = types.model({
    'Wages-tips-other-compensation_Self_employer_1': types.maybeNull(types.string),
    'Wages-tips-other-compensation_Self_employer_2': types.maybeNull(types.string),
    'Wages-tips-other-compensation_Self_employer_3': types.maybeNull(types.string),
    'Wages-tips-other-compensation_Self_employer_4': types.maybeNull(types.string),
    'Wages-tips-other-compensation_Self_employer_5': types.maybeNull(types.string),
    'Wages-tips-other-compensation_Self_employer_6': types.maybeNull(types.string),
    'Wages-tips-other-compensation_Self_employer_7': types.maybeNull(types.string),
    'Wages-tips-other-compensation_Self_excess': types.maybeNull(types.string),
    'Federal-income-tax-withheld_Self_employer_1': types.maybeNull(types.string),
    'Federal-income-tax-withheld_Self_employer_2': types.maybeNull(types.string),
    'Federal-income-tax-withheld_Self_employer_3': types.maybeNull(types.string),
    'Federal-income-tax-withheld_Self_employer_4': types.maybeNull(types.string),
    'Federal-income-tax-withheld_Self_employer_5': types.maybeNull(types.string),
    'Federal-income-tax-withheld_Self_employer_6': types.maybeNull(types.string),
    'Federal-income-tax-withheld_Self_employer_7': types.maybeNull(types.string),
    'Federal-income-tax-withheld_Self_excess': types.maybeNull(types.string),
    'Social-security-wages_Self_employer_1': types.maybeNull(types.string),
    'Social-security-wages_Self_employer_2': types.maybeNull(types.string),
    'Social-security-wages_Self_employer_3': types.maybeNull(types.string),
    'Social-security-wages_Self_employer_4': types.maybeNull(types.string),
    'Social-security-wages_Self_employer_5': types.maybeNull(types.string),
    'Social-security-wages_Self_employer_6': types.maybeNull(types.string),
    'Social-security-wages_Self_employer_7': types.maybeNull(types.string),
    'Social-security-wages_Self_excess': types.maybeNull(types.string),
    'Social-security-tax-withheld_Self_employer_1': types.maybeNull(types.string),
    'Social-security-tax-withheld_Self_employer_2': types.maybeNull(types.string),
    'Social-security-tax-withheld_Self_employer_3': types.maybeNull(types.string),
    'Social-security-tax-withheld_Self_employer_4': types.maybeNull(types.string),
    'Social-security-tax-withheld_Self_employer_5': types.maybeNull(types.string),
    'Social-security-tax-withheld_Self_employer_6': types.maybeNull(types.string),
    'Social-security-tax-withheld_Self_employer_7': types.maybeNull(types.string),
    'Social-security-tax-withheld_Self_excess': types.maybeNull(types.string),
    'Medicare-wages-and-tips_Self_employer_1': types.maybeNull(types.string),
    'Medicare-wages-and-tips_Self_employer_2': types.maybeNull(types.string),
    'Medicare-wages-and-tips_Self_employer_3': types.maybeNull(types.string),
    'Medicare-wages-and-tips_Self_employer_4': types.maybeNull(types.string),
    'Medicare-wages-and-tips_Self_employer_5': types.maybeNull(types.string),
    'Medicare-wages-and-tips_Self_employer_6': types.maybeNull(types.string),
    'Medicare-wages-and-tips_Self_employer_7': types.maybeNull(types.string),
    'Medicare-wages-and-tips_Self_excess': types.maybeNull(types.string),
    'Medicare-tax-withheld_Self_employer_1': types.maybeNull(types.string),
    'Medicare-tax-withheld_Self_employer_2': types.maybeNull(types.string),
    'Medicare-tax-withheld_Self_employer_3': types.maybeNull(types.string),
    'Medicare-tax-withheld_Self_employer_4': types.maybeNull(types.string),
    'Medicare-tax-withheld_Self_employer_5': types.maybeNull(types.string),
    'Medicare-tax-withheld_Self_employer_6': types.maybeNull(types.string),
    'Medicare-tax-withheld_Self_employer_7': types.maybeNull(types.string),
    'Medicare-tax-withheld_Self_excess': types.maybeNull(types.string),
    'Social-Security-Tips_Self_employer_1': types.maybeNull(types.string),
    'Social-Security-Tips_Self_employer_2': types.maybeNull(types.string),
    'Social-Security-Tips_Self_employer_3': types.maybeNull(types.string),
    'Social-Security-Tips_Self_employer_4': types.maybeNull(types.string),
    'Social-Security-Tips_Self_employer_5': types.maybeNull(types.string),
    'Social-Security-Tips_Self_employer_6': types.maybeNull(types.string),
    'Social-Security-Tips_Self_employer_7': types.maybeNull(types.string),
    'Social-Security-Tips_Self_excess': types.maybeNull(types.string),
    'Allocated-Tips_Self_employer_1': types.maybeNull(types.string),
    'Allocated-Tips_Self_employer_2': types.maybeNull(types.string),
    'Allocated-Tips_Self_employer_3': types.maybeNull(types.string),
    'Allocated-Tips_Self_employer_4': types.maybeNull(types.string),
    'Allocated-Tips_Self_employer_5': types.maybeNull(types.string),
    'Allocated-Tips_Self_employer_6': types.maybeNull(types.string),
    'Allocated-Tips_Self_employer_7': types.maybeNull(types.string),
    'Allocated-Tips_Self_excess': types.maybeNull(types.string),
    'Dependent-Care-Benefits_Self_employer_1': types.maybeNull(types.string),
    'Dependent-Care-Benefits_Self_employer_2': types.maybeNull(types.string),
    'Dependent-Care-Benefits_Self_employer_3': types.maybeNull(types.string),
    'Dependent-Care-Benefits_Self_employer_4': types.maybeNull(types.string),
    'Dependent-Care-Benefits_Self_employer_5': types.maybeNull(types.string),
    'Dependent-Care-Benefits_Self_employer_6': types.maybeNull(types.string),
    'Dependent-Care-Benefits_Self_employer_7': types.maybeNull(types.string),
    'Dependent-Care-Benefits_Self_excess': types.maybeNull(types.string),
    'Nonqualified-plans_Self_employer_1': types.maybeNull(types.string),
    'Nonqualified-plans_Self_employer_2': types.maybeNull(types.string),
    'Nonqualified-plans_Self_employer_3': types.maybeNull(types.string),
    'Nonqualified-plans_Self_employer_4': types.maybeNull(types.string),
    'Nonqualified-plans_Self_employer_5': types.maybeNull(types.string),
    'Nonqualified-plans_Self_employer_6': types.maybeNull(types.string),
    'Nonqualified-plans_Self_employer_7': types.maybeNull(types.string),
    'Nonqualified-plans_Self_excess': types.maybeNull(types.string),
    'Non-Taxable-Combat-Pay_Self_employer_1': types.maybeNull(types.string),
    'Non-Taxable-Combat-Pay_Self_employer_2': types.maybeNull(types.string),
    'Non-Taxable-Combat-Pay_Self_employer_3': types.maybeNull(types.string),
    'Non-Taxable-Combat-Pay_Self_employer_4': types.maybeNull(types.string),
    'Non-Taxable-Combat-Pay_Self_employer_5': types.maybeNull(types.string),
    'Non-Taxable-Combat-Pay_Self_employer_6': types.maybeNull(types.string),
    'Non-Taxable-Combat-Pay_Self_employer_7': types.maybeNull(types.string),
    'Non-Taxable-Combat-Pay_Self_excess': types.maybeNull(types.string),
    'See-instructions-for-box-12_Self_employer_1': types.maybeNull(types.string),
    'See-instructions-for-box-12_Self_employer_2': types.maybeNull(types.string),
    'See-instructions-for-box-12_Self_employer_3': types.maybeNull(types.string),
    'See-instructions-for-box-12_Self_employer_4': types.maybeNull(types.string),
    'See-instructions-for-box-12_Self_employer_5': types.maybeNull(types.string),
    'See-instructions-for-box-12_Self_employer_6': types.maybeNull(types.string),
    'See-instructions-for-box-12_Self_employer_7': types.maybeNull(types.string),
    'See-instructions-for-box-12_Self_excess': types.maybeNull(types.string),
    '12C_Self_employer_1': types.maybeNull(types.string),
    '12C_Self_employer_2': types.maybeNull(types.string),
    '12C_Self_employer_3': types.maybeNull(types.string),
    '12C_Self_employer_4': types.maybeNull(types.string),
    '12C_Self_employer_5': types.maybeNull(types.string),
    '12C_Self_employer_6': types.maybeNull(types.string),
    '12C_Self_employer_7': types.maybeNull(types.string),
    '12C_Self_excess': types.maybeNull(types.string),
    '12D_Self_employer_1': types.maybeNull(types.string),
    '12D_Self_employer_2': types.maybeNull(types.string),
    '12D_Self_employer_3': types.maybeNull(types.string),
    '12D_Self_employer_4': types.maybeNull(types.string),
    '12D_Self_employer_5': types.maybeNull(types.string),
    '12D_Self_employer_6': types.maybeNull(types.string),
    '12D_Self_employer_7': types.maybeNull(types.string),
    '12D_Self_excess': types.maybeNull(types.string),
    'Stat-Emp-Retire-Party-Sick_Self_employer_1': types.maybeNull(types.string),
    'Stat-Emp-Retire-Party-Sick_Self_employer_2': types.maybeNull(types.string),
    'Stat-Emp-Retire-Party-Sick_Self_employer_3': types.maybeNull(types.string),
    'Stat-Emp-Retire-Party-Sick_Self_employer_4': types.maybeNull(types.string),
    'Stat-Emp-Retire-Party-Sick_Self_employer_5': types.maybeNull(types.string),
    'Stat-Emp-Retire-Party-Sick_Self_employer_6': types.maybeNull(types.string),
    'Stat-Emp-Retire-Party-Sick_Self_employer_7': types.maybeNull(types.string),
    'Stat-Emp-Retire-Party-Sick_Self_excess': types.maybeNull(types.string),
    'Other_Self_employer_1': types.maybeNull(types.string),
    'Other_Self_employer_2': types.maybeNull(types.string),
    'Other_Self_employer_3': types.maybeNull(types.string),
    'Other_Self_employer_4': types.maybeNull(types.string),
    'Other_Self_employer_5': types.maybeNull(types.string),
    'Other_Self_employer_6': types.maybeNull(types.string),
    'Other_Self_employer_7': types.maybeNull(types.string),
    'Other_Self_excess': types.maybeNull(types.string),
    'Railroad-retire-compensation_Self_employer_1': types.maybeNull(types.string),
    'Railroad-retire-compensation_Self_employer_2': types.maybeNull(types.string),
    'Railroad-retire-compensation_Self_employer_3': types.maybeNull(types.string),
    'Railroad-retire-compensation_Self_employer_4': types.maybeNull(types.string),
    'Railroad-retire-compensation_Self_employer_5': types.maybeNull(types.string),
    'Railroad-retire-compensation_Self_employer_6': types.maybeNull(types.string),
    'Railroad-retire-compensation_Self_employer_7': types.maybeNull(types.string),
    'Railroad-retire-compensation_Self_excess': types.maybeNull(types.string),
    'RRTA-medicare-tax-withheld_Self_employer_1': types.maybeNull(types.string),
    'RRTA-medicare-tax-withheld_Self_employer_2': types.maybeNull(types.string),
    'RRTA-medicare-tax-withheld_Self_employer_3': types.maybeNull(types.string),
    'RRTA-medicare-tax-withheld_Self_employer_4': types.maybeNull(types.string),
    'RRTA-medicare-tax-withheld_Self_employer_5': types.maybeNull(types.string),
    'RRTA-medicare-tax-withheld_Self_employer_6': types.maybeNull(types.string),
    'RRTA-medicare-tax-withheld_Self_employer_7': types.maybeNull(types.string),
    'RRTA-medicare-tax-withheld_Self_excess': types.maybeNull(types.string),
    'State-Employers-state-ID-number_Self_employer_1': types.maybeNull(types.string),
    'State-Employers-state-ID-number_Self_employer_2': types.maybeNull(types.string),
    'State-Employers-state-ID-number_Self_employer_3': types.maybeNull(types.string),
    'State-Employers-state-ID-number_Self_employer_4': types.maybeNull(types.string),
    'State-Employers-state-ID-number_Self_employer_5': types.maybeNull(types.string),
    'State-Employers-state-ID-number_Self_employer_6': types.maybeNull(types.string),
    'State-Employers-state-ID-number_Self_employer_7': types.maybeNull(types.string),
    'State-Employers-state-ID-number_Self_excess': types.maybeNull(types.string),
    'State-wages-tips-etc_Self_employer_1': types.maybeNull(types.string),
    'State-wages-tips-etc_Self_employer_2': types.maybeNull(types.string),
    'State-wages-tips-etc_Self_employer_3': types.maybeNull(types.string),
    'State-wages-tips-etc_Self_employer_4': types.maybeNull(types.string),
    'State-wages-tips-etc_Self_employer_5': types.maybeNull(types.string),
    'State-wages-tips-etc_Self_employer_6': types.maybeNull(types.string),
    'State-wages-tips-etc_Self_employer_7': types.maybeNull(types.string),
    'State-wages-tips-etc_Self_excess': types.maybeNull(types.string),
    'State-income-tax_Self_employer_1': types.maybeNull(types.string),
    'State-income-tax_Self_employer_2': types.maybeNull(types.string),
    'State-income-tax_Self_employer_3': types.maybeNull(types.string),
    'State-income-tax_Self_employer_4': types.maybeNull(types.string),
    'State-income-tax_Self_employer_5': types.maybeNull(types.string),
    'State-income-tax_Self_employer_6': types.maybeNull(types.string),
    'State-income-tax_Self_employer_7': types.maybeNull(types.string),
    'State-income-tax_Self_excess': types.maybeNull(types.string),
    'Local-wages-tips-etc_Self_employer_1': types.maybeNull(types.string),
    'Local-wages-tips-etc_Self_employer_2': types.maybeNull(types.string),
    'Local-wages-tips-etc_Self_employer_3': types.maybeNull(types.string),
    'Local-wages-tips-etc_Self_employer_4': types.maybeNull(types.string),
    'Local-wages-tips-etc_Self_employer_5': types.maybeNull(types.string),
    'Local-wages-tips-etc_Self_employer_6': types.maybeNull(types.string),
    'Local-wages-tips-etc_Self_employer_7': types.maybeNull(types.string),
    'Local-wages-tips-etc_Self_excess': types.maybeNull(types.string),
    'Local-income-tax_Self_employer_1': types.maybeNull(types.string),
    'Local-income-tax_Self_employer_2': types.maybeNull(types.string),
    'Local-income-tax_Self_employer_3': types.maybeNull(types.string),
    'Local-income-tax_Self_employer_4': types.maybeNull(types.string),
    'Local-income-tax_Self_employer_5': types.maybeNull(types.string),
    'Local-income-tax_Self_employer_6': types.maybeNull(types.string),
    'Local-income-tax_Self_employer_7': types.maybeNull(types.string),
    'Local-income-tax_Self_excess': types.maybeNull(types.string),
    'Locality-name_Self_employer_1': types.maybeNull(types.string),
    'Locality-name_Self_employer_2': types.maybeNull(types.string),
    'Locality-name_Self_employer_3': types.maybeNull(types.string),
    'Locality-name_Self_employer_4': types.maybeNull(types.string),
    'Locality-name_Self_employer_5': types.maybeNull(types.string),
    'Locality-name_Self_employer_6': types.maybeNull(types.string),
    'Locality-name_Self_employer_7': types.maybeNull(types.string),
    'Locality-name_Self_excess': types.maybeNull(types.string),
    'Wages-tips-other-compensation_Spouse_employer_1': types.maybeNull(types.string),
    'Wages-tips-other-compensation_Spouse_employer_2': types.maybeNull(types.string),
    'Wages-tips-other-compensation_Spouse_employer_3': types.maybeNull(types.string),
    'Wages-tips-other-compensation_Spouse_employer_4': types.maybeNull(types.string),
    'Wages-tips-other-compensation_Spouse_employer_5': types.maybeNull(types.string),
    'Wages-tips-other-compensation_Spouse_employer_6': types.maybeNull(types.string),
    'Wages-tips-other-compensation_Spouse_employer_7': types.maybeNull(types.string),
    'Wages-tips-other-compensation_Spouse_excess': types.maybeNull(types.string),
    'Federal-income-tax-withheld_Spouse_employer_1': types.maybeNull(types.string),
    'Federal-income-tax-withheld_Spouse_employer_2': types.maybeNull(types.string),
    'Federal-income-tax-withheld_Spouse_employer_3': types.maybeNull(types.string),
    'Federal-income-tax-withheld_Spouse_employer_4': types.maybeNull(types.string),
    'Federal-income-tax-withheld_Spouse_employer_5': types.maybeNull(types.string),
    'Federal-income-tax-withheld_Spouse_employer_6': types.maybeNull(types.string),
    'Federal-income-tax-withheld_Spouse_employer_7': types.maybeNull(types.string),
    'Federal-income-tax-withheld_Spouse_excess': types.maybeNull(types.string),
    'Social-security-wages_Spouse_employer_1': types.maybeNull(types.string),
    'Social-security-wages_Spouse_employer_2': types.maybeNull(types.string),
    'Social-security-wages_Spouse_employer_3': types.maybeNull(types.string),
    'Social-security-wages_Spouse_employer_4': types.maybeNull(types.string),
    'Social-security-wages_Spouse_employer_5': types.maybeNull(types.string),
    'Social-security-wages_Spouse_employer_6': types.maybeNull(types.string),
    'Social-security-wages_Spouse_employer_7': types.maybeNull(types.string),
    'Social-security-wages_Spouse_excess': types.maybeNull(types.string),
    'Social-security-tax-withheld_Spouse_employer_1': types.maybeNull(types.string),
    'Social-security-tax-withheld_Spouse_employer_2': types.maybeNull(types.string),
    'Social-security-tax-withheld_Spouse_employer_3': types.maybeNull(types.string),
    'Social-security-tax-withheld_Spouse_employer_4': types.maybeNull(types.string),
    'Social-security-tax-withheld_Spouse_employer_5': types.maybeNull(types.string),
    'Social-security-tax-withheld_Spouse_employer_6': types.maybeNull(types.string),
    'Social-security-tax-withheld_Spouse_employer_7': types.maybeNull(types.string),
    'Social-security-tax-withheld_Spouse_excess': types.maybeNull(types.string),
    'Medicare-wages-and-tips_Spouse_employer_1': types.maybeNull(types.string),
    'Medicare-wages-and-tips_Spouse_employer_2': types.maybeNull(types.string),
    'Medicare-wages-and-tips_Spouse_employer_3': types.maybeNull(types.string),
    'Medicare-wages-and-tips_Spouse_employer_4': types.maybeNull(types.string),
    'Medicare-wages-and-tips_Spouse_employer_5': types.maybeNull(types.string),
    'Medicare-wages-and-tips_Spouse_employer_6': types.maybeNull(types.string),
    'Medicare-wages-and-tips_Spouse_employer_7': types.maybeNull(types.string),
    'Medicare-wages-and-tips_Spouse_excess': types.maybeNull(types.string),
    'Medicare-tax-withheld_Spouse_employer_1': types.maybeNull(types.string),
    'Medicare-tax-withheld_Spouse_employer_2': types.maybeNull(types.string),
    'Medicare-tax-withheld_Spouse_employer_3': types.maybeNull(types.string),
    'Medicare-tax-withheld_Spouse_employer_4': types.maybeNull(types.string),
    'Medicare-tax-withheld_Spouse_employer_5': types.maybeNull(types.string),
    'Medicare-tax-withheld_Spouse_employer_6': types.maybeNull(types.string),
    'Medicare-tax-withheld_Spouse_employer_7': types.maybeNull(types.string),
    'Medicare-tax-withheld_Spouse_excess': types.maybeNull(types.string),
    'Social-Security-Tips_Spouse_employer_1': types.maybeNull(types.string),
    'Social-Security-Tips_Spouse_employer_2': types.maybeNull(types.string),
    'Social-Security-Tips_Spouse_employer_3': types.maybeNull(types.string),
    'Social-Security-Tips_Spouse_employer_4': types.maybeNull(types.string),
    'Social-Security-Tips_Spouse_employer_5': types.maybeNull(types.string),
    'Social-Security-Tips_Spouse_employer_6': types.maybeNull(types.string),
    'Social-Security-Tips_Spouse_employer_7': types.maybeNull(types.string),
    'Social-Security-Tips_Spouse_excess': types.maybeNull(types.string),
    'Allocated-Tips_Spouse_employer_1': types.maybeNull(types.string),
    'Allocated-Tips_Spouse_employer_2': types.maybeNull(types.string),
    'Allocated-Tips_Spouse_employer_3': types.maybeNull(types.string),
    'Allocated-Tips_Spouse_employer_4': types.maybeNull(types.string),
    'Allocated-Tips_Spouse_employer_5': types.maybeNull(types.string),
    'Allocated-Tips_Spouse_employer_6': types.maybeNull(types.string),
    'Allocated-Tips_Spouse_employer_7': types.maybeNull(types.string),
    'Allocated-Tips_Spouse_excess': types.maybeNull(types.string),
    'Dependent-Care-Benefits_Spouse_employer_1': types.maybeNull(types.string),
    'Dependent-Care-Benefits_Spouse_employer_2': types.maybeNull(types.string),
    'Dependent-Care-Benefits_Spouse_employer_3': types.maybeNull(types.string),
    'Dependent-Care-Benefits_Spouse_employer_4': types.maybeNull(types.string),
    'Dependent-Care-Benefits_Spouse_employer_5': types.maybeNull(types.string),
    'Dependent-Care-Benefits_Spouse_employer_6': types.maybeNull(types.string),
    'Dependent-Care-Benefits_Spouse_employer_7': types.maybeNull(types.string),
    'Dependent-Care-Benefits_Spouse_excess': types.maybeNull(types.string),
    'Nonqualified-plans_Spouse_employer_1': types.maybeNull(types.string),
    'Nonqualified-plans_Spouse_employer_2': types.maybeNull(types.string),
    'Nonqualified-plans_Spouse_employer_3': types.maybeNull(types.string),
    'Nonqualified-plans_Spouse_employer_4': types.maybeNull(types.string),
    'Nonqualified-plans_Spouse_employer_5': types.maybeNull(types.string),
    'Nonqualified-plans_Spouse_employer_6': types.maybeNull(types.string),
    'Nonqualified-plans_Spouse_employer_7': types.maybeNull(types.string),
    'Nonqualified-plans_Spouse_excess': types.maybeNull(types.string),
    'Non-Taxable-Combat-Pay_Spouse_employer_1': types.maybeNull(types.string),
    'Non-Taxable-Combat-Pay_Spouse_employer_2': types.maybeNull(types.string),
    'Non-Taxable-Combat-Pay_Spouse_employer_3': types.maybeNull(types.string),
    'Non-Taxable-Combat-Pay_Spouse_employer_4': types.maybeNull(types.string),
    'Non-Taxable-Combat-Pay_Spouse_employer_5': types.maybeNull(types.string),
    'Non-Taxable-Combat-Pay_Spouse_employer_6': types.maybeNull(types.string),
    'Non-Taxable-Combat-Pay_Spouse_employer_7': types.maybeNull(types.string),
    'Non-Taxable-Combat-Pay_Spouse_excess': types.maybeNull(types.string),
    'See-instructions-for-box-12_Spouse_employer_1': types.maybeNull(types.string),
    'See-instructions-for-box-12_Spouse_employer_2': types.maybeNull(types.string),
    'See-instructions-for-box-12_Spouse_employer_3': types.maybeNull(types.string),
    'See-instructions-for-box-12_Spouse_employer_4': types.maybeNull(types.string),
    'See-instructions-for-box-12_Spouse_employer_5': types.maybeNull(types.string),
    'See-instructions-for-box-12_Spouse_employer_6': types.maybeNull(types.string),
    'See-instructions-for-box-12_Spouse_employer_7': types.maybeNull(types.string),
    'See-instructions-for-box-12_Spouse_excess': types.maybeNull(types.string),
    '12C_Spouse_employer_1': types.maybeNull(types.string),
    '12C_Spouse_employer_2': types.maybeNull(types.string),
    '12C_Spouse_employer_3': types.maybeNull(types.string),
    '12C_Spouse_employer_4': types.maybeNull(types.string),
    '12C_Spouse_employer_5': types.maybeNull(types.string),
    '12C_Spouse_employer_6': types.maybeNull(types.string),
    '12C_Spouse_employer_7': types.maybeNull(types.string),
    '12C_Spouse_excess': types.maybeNull(types.string),
    '12D_Spouse_employer_1': types.maybeNull(types.string),
    '12D_Spouse_employer_2': types.maybeNull(types.string),
    '12D_Spouse_employer_3': types.maybeNull(types.string),
    '12D_Spouse_employer_4': types.maybeNull(types.string),
    '12D_Spouse_employer_5': types.maybeNull(types.string),
    '12D_Spouse_employer_6': types.maybeNull(types.string),
    '12D_Spouse_employer_7': types.maybeNull(types.string),
    '12D_Spouse_excess': types.maybeNull(types.string),
    'Stat-Emp-Retire-Party-Sick_Spouse_employer_1': types.maybeNull(types.string),
    'Stat-Emp-Retire-Party-Sick_Spouse_employer_2': types.maybeNull(types.string),
    'Stat-Emp-Retire-Party-Sick_Spouse_employer_3': types.maybeNull(types.string),
    'Stat-Emp-Retire-Party-Sick_Spouse_employer_4': types.maybeNull(types.string),
    'Stat-Emp-Retire-Party-Sick_Spouse_employer_5': types.maybeNull(types.string),
    'Stat-Emp-Retire-Party-Sick_Spouse_employer_6': types.maybeNull(types.string),
    'Stat-Emp-Retire-Party-Sick_Spouse_employer_7': types.maybeNull(types.string),
    'Stat-Emp-Retire-Party-Sick_Spouse_excess': types.maybeNull(types.string),
    'Other_Spouse_employer_1': types.maybeNull(types.string),
    'Other_Spouse_employer_2': types.maybeNull(types.string),
    'Other_Spouse_employer_3': types.maybeNull(types.string),
    'Other_Spouse_employer_4': types.maybeNull(types.string),
    'Other_Spouse_employer_5': types.maybeNull(types.string),
    'Other_Spouse_employer_6': types.maybeNull(types.string),
    'Other_Spouse_employer_7': types.maybeNull(types.string),
    'Other_Spouse_excess': types.maybeNull(types.string),
    'Railroad-retire-compensation_Spouse_employer_1': types.maybeNull(types.string),
    'Railroad-retire-compensation_Spouse_employer_2': types.maybeNull(types.string),
    'Railroad-retire-compensation_Spouse_employer_3': types.maybeNull(types.string),
    'Railroad-retire-compensation_Spouse_employer_4': types.maybeNull(types.string),
    'Railroad-retire-compensation_Spouse_employer_5': types.maybeNull(types.string),
    'Railroad-retire-compensation_Spouse_employer_6': types.maybeNull(types.string),
    'Railroad-retire-compensation_Spouse_employer_7': types.maybeNull(types.string),
    'Railroad-retire-compensation_Spouse_excess': types.maybeNull(types.string),
    'RRTA-medicare-tax-withheld_Spouse_employer_1': types.maybeNull(types.string),
    'RRTA-medicare-tax-withheld_Spouse_employer_2': types.maybeNull(types.string),
    'RRTA-medicare-tax-withheld_Spouse_employer_3': types.maybeNull(types.string),
    'RRTA-medicare-tax-withheld_Spouse_employer_4': types.maybeNull(types.string),
    'RRTA-medicare-tax-withheld_Spouse_employer_5': types.maybeNull(types.string),
    'RRTA-medicare-tax-withheld_Spouse_employer_6': types.maybeNull(types.string),
    'RRTA-medicare-tax-withheld_Spouse_employer_7': types.maybeNull(types.string),
    'RRTA-medicare-tax-withheld_Spouse_excess': types.maybeNull(types.string),
    'State-Employers-state-ID-number_Spouse_employer_1': types.maybeNull(types.string),
    'State-Employers-state-ID-number_Spouse_employer_2': types.maybeNull(types.string),
    'State-Employers-state-ID-number_Spouse_employer_3': types.maybeNull(types.string),
    'State-Employers-state-ID-number_Spouse_employer_4': types.maybeNull(types.string),
    'State-Employers-state-ID-number_Spouse_employer_5': types.maybeNull(types.string),
    'State-Employers-state-ID-number_Spouse_employer_6': types.maybeNull(types.string),
    'State-Employers-state-ID-number_Spouse_employer_7': types.maybeNull(types.string),
    'State-Employers-state-ID-number_Spouse_excess': types.maybeNull(types.string),
    'State-wages-tips-etc_Spouse_employer_1': types.maybeNull(types.string),
    'State-wages-tips-etc_Spouse_employer_2': types.maybeNull(types.string),
    'State-wages-tips-etc_Spouse_employer_3': types.maybeNull(types.string),
    'State-wages-tips-etc_Spouse_employer_4': types.maybeNull(types.string),
    'State-wages-tips-etc_Spouse_employer_5': types.maybeNull(types.string),
    'State-wages-tips-etc_Spouse_employer_6': types.maybeNull(types.string),
    'State-wages-tips-etc_Spouse_employer_7': types.maybeNull(types.string),
    'State-wages-tips-etc_Spouse_excess': types.maybeNull(types.string),
    'State-income-tax_Spouse_employer_1': types.maybeNull(types.string),
    'State-income-tax_Spouse_employer_2': types.maybeNull(types.string),
    'State-income-tax_Spouse_employer_3': types.maybeNull(types.string),
    'State-income-tax_Spouse_employer_4': types.maybeNull(types.string),
    'State-income-tax_Spouse_employer_5': types.maybeNull(types.string),
    'State-income-tax_Spouse_employer_6': types.maybeNull(types.string),
    'State-income-tax_Spouse_employer_7': types.maybeNull(types.string),
    'State-income-tax_Spouse_excess': types.maybeNull(types.string),
    'Local-wages-tips-etc_Spouse_employer_1': types.maybeNull(types.string),
    'Local-wages-tips-etc_Spouse_employer_2': types.maybeNull(types.string),
    'Local-wages-tips-etc_Spouse_employer_3': types.maybeNull(types.string),
    'Local-wages-tips-etc_Spouse_employer_4': types.maybeNull(types.string),
    'Local-wages-tips-etc_Spouse_employer_5': types.maybeNull(types.string),
    'Local-wages-tips-etc_Spouse_employer_6': types.maybeNull(types.string),
    'Local-wages-tips-etc_Spouse_employer_7': types.maybeNull(types.string),
    'Local-wages-tips-etc_Spouse_excess': types.maybeNull(types.string),
    'Local-income-tax_Spouse_employer_1': types.maybeNull(types.string),
    'Local-income-tax_Spouse_employer_2': types.maybeNull(types.string),
    'Local-income-tax_Spouse_employer_3': types.maybeNull(types.string),
    'Local-income-tax_Spouse_employer_4': types.maybeNull(types.string),
    'Local-income-tax_Spouse_employer_5': types.maybeNull(types.string),
    'Local-income-tax_Spouse_employer_6': types.maybeNull(types.string),
    'Local-income-tax_Spouse_employer_7': types.maybeNull(types.string),
    'Local-income-tax_Spouse_excess': types.maybeNull(types.string),
    'Locality-name_Spouse_employer_1': types.maybeNull(types.string),
    'Locality-name_Spouse_employer_2': types.maybeNull(types.string),
    'Locality-name_Spouse_employer_3': types.maybeNull(types.string),
    'Locality-name_Spouse_employer_4': types.maybeNull(types.string),
    'Locality-name_Spouse_employer_5': types.maybeNull(types.string),
    'Locality-name_Spouse_employer_6': types.maybeNull(types.string),
    'Locality-name_Spouse_employer_7': types.maybeNull(types.string),
    'Locality-name_Spouse_excess': types.maybeNull(types.string),
    total_field_1: types.maybeNull(types.string),
    total_field_1_excess_withheld: types.maybeNull(types.string),
    total_field_2: types.maybeNull(types.string),
    total_field_2_excess_withheld: types.maybeNull(types.string),
    total_field_3: types.maybeNull(types.string),
    total_field_3_excess_withheld: types.maybeNull(types.string),
    total_field_4: types.maybeNull(types.string),
    total_field_4_excess_withheld: types.maybeNull(types.string),
    total_field_5: types.maybeNull(types.string),
    total_field_5_excess_withheld: types.maybeNull(types.string),
    total_field_6: types.maybeNull(types.string),
    total_field_6_excess_withheld: types.maybeNull(types.string),
    total_field_7: types.maybeNull(types.string),
    total_field_7_excess_withheld: types.maybeNull(types.string),
    total_field_8: types.maybeNull(types.string),
    total_field_8_excess_withheld: types.maybeNull(types.string),
    total_field_10: types.maybeNull(types.string),
    total_field_10_excess_withheld: types.maybeNull(types.string),
    total_field_11: types.maybeNull(types.string),
    total_field_11_excess_withheld: types.maybeNull(types.string),
    total_field_12a: types.maybeNull(types.string),
    total_field_12a_excess_withheld: types.maybeNull(types.string),
    total_field_12b: types.maybeNull(types.string),
    total_field_12b_excess_withheld: types.maybeNull(types.string),
    total_field_12c: types.maybeNull(types.string),
    total_field_12c_excess_withheld: types.maybeNull(types.string),
    total_field_12d: types.maybeNull(types.string),
    total_field_12d_excess_withheld: types.maybeNull(types.string),
    total_field_13: types.maybeNull(types.string),
    total_field_13_excess_withheld: types.maybeNull(types.string),
    total_field_14: types.maybeNull(types.string),
    total_field_14_excess_withheld: types.maybeNull(types.string),
    total_field_14a: types.maybeNull(types.string),
    total_field_14a_excess_withheld: types.maybeNull(types.string),
    total_field_14b: types.maybeNull(types.string),
    total_field_14b_excess_withheld: types.maybeNull(types.string),
    total_field_15: types.maybeNull(types.string),
    total_field_15_excess_withheld: types.maybeNull(types.string),
    total_field_16: types.maybeNull(types.string),
    total_field_16_excess_withheld: types.maybeNull(types.string),
    total_field_17: types.maybeNull(types.string),
    total_field_17_excess_withheld: types.maybeNull(types.string),
    total_field_18: types.maybeNull(types.string),
    total_field_18_excess_withheld: types.maybeNull(types.string),
    total_field_19: types.maybeNull(types.string),
    total_field_19_excess_withheld: types.maybeNull(types.string),
    total_field_20: types.maybeNull(types.string),
    total_field_20_excess_withheld: types.maybeNull(types.string),
});

export const scheduleEFormModel = types.model({
    dispose_investment: types.maybeNull(types.array(types.string)),
    field_1b_A: types.maybeNull(types.string),
    field_1b_B: types.maybeNull(types.string),
    field_1b_C: types.maybeNull(types.string),
    field_3_a: types.maybeNull(types.string),
    field_3_b: types.maybeNull(types.string),
    field_3_c: types.maybeNull(types.string),
    field_4_a: types.maybeNull(types.string),
    field_4_b: types.maybeNull(types.string),
    field_4_c: types.maybeNull(types.string),
    field_5_a: types.maybeNull(types.string),
    field_5_b: types.maybeNull(types.string),
    field_5_c: types.maybeNull(types.string),
    field_6_a: types.maybeNull(types.string),
    field_6_b: types.maybeNull(types.string),
    field_6_c: types.maybeNull(types.string),
    field_7_a: types.maybeNull(types.string),
    field_7_b: types.maybeNull(types.string),
    field_7_c: types.maybeNull(types.string),
    field_8_a: types.maybeNull(types.string),
    field_8_b: types.maybeNull(types.string),
    field_8_c: types.maybeNull(types.string),
    field_9_a: types.maybeNull(types.string),
    field_9_b: types.maybeNull(types.string),
    field_9_c: types.maybeNull(types.string),
    field_10_a: types.maybeNull(types.string),
    field_10_b: types.maybeNull(types.string),
    field_10_c: types.maybeNull(types.string),
    field_11_a: types.maybeNull(types.string),
    field_11_b: types.maybeNull(types.string),
    field_11_c: types.maybeNull(types.string),
    field_12_a: types.maybeNull(types.string),
    field_12_b: types.maybeNull(types.string),
    field_12_c: types.maybeNull(types.string),
    field_13_a: types.maybeNull(types.string),
    field_13_b: types.maybeNull(types.string),
    field_13_c: types.maybeNull(types.string),
    field_14_a: types.maybeNull(types.string),
    field_14_b: types.maybeNull(types.string),
    field_14_c: types.maybeNull(types.string),
    field_15_a: types.maybeNull(types.string),
    field_15_b: types.maybeNull(types.string),
    field_15_c: types.maybeNull(types.string),
    field_16_a: types.maybeNull(types.string),
    field_16_b: types.maybeNull(types.string),
    field_16_c: types.maybeNull(types.string),
    field_17_a: types.maybeNull(types.string),
    field_17_b: types.maybeNull(types.string),
    field_17_c: types.maybeNull(types.string),
    field_18_a: types.maybeNull(types.string),
    field_18_b: types.maybeNull(types.string),
    field_18_c: types.maybeNull(types.string),
    field_19_a: types.maybeNull(types.string),
    field_19_b: types.maybeNull(types.string),
    field_19_c: types.maybeNull(types.string),
    field_20_a: types.maybeNull(types.string),
    field_20_b: types.maybeNull(types.string),
    field_20_c: types.maybeNull(types.string),
    field_21_a: types.maybeNull(types.string),
    field_21_b: types.maybeNull(types.string),
    field_21_c: types.maybeNull(types.string),
    field_22_a: types.maybeNull(types.string),
    field_22_b: types.maybeNull(types.string),
    field_22_c: types.maybeNull(types.string),
    field_23a: types.maybeNull(types.string),
    field_23b: types.maybeNull(types.string),
    field_23c: types.maybeNull(types.string),
    field_23d: types.maybeNull(types.string),
    field_23e: types.maybeNull(types.string),
    field_24: types.maybeNull(types.string),
    field_25: types.maybeNull(types.string),
    field_26: types.maybeNull(types.string),
    field_27: types.maybeNull(types.string),
    field_28a_a: types.maybeNull(types.string),
    field_28a_b: types.maybeNull(types.string),
    field_28a_c: types.maybeNull(types.string),
    field_28a_d: types.maybeNull(types.string),
    field_28a_e: types.maybeNull(types.string),
    field_28a_f: types.maybeNull(types.string),
    field_28a_g: types.maybeNull(types.string),
    field_28a_h: types.maybeNull(types.string),
    field_28a_i: types.maybeNull(types.string),
    field_28a_j: types.maybeNull(types.string),
    field_28a_k: types.maybeNull(types.string),
    field_28b_a: types.maybeNull(types.string),
    field_28b_b: types.maybeNull(types.string),
    field_28b_c: types.maybeNull(types.string),
    field_28b_d: types.maybeNull(types.string),
    field_28b_e: types.maybeNull(types.string),
    field_28b_f: types.maybeNull(types.string),
    field_28b_g: types.maybeNull(types.string),
    field_28b_h: types.maybeNull(types.string),
    field_28b_i: types.maybeNull(types.string),
    field_28b_j: types.maybeNull(types.string),
    field_28b_k: types.maybeNull(types.string),
    field_28c_a: types.maybeNull(types.string),
    field_28c_b: types.maybeNull(types.string),
    field_28c_c: types.maybeNull(types.string),
    field_28c_d: types.maybeNull(types.string),
    field_28c_e: types.maybeNull(types.string),
    field_28c_f: types.maybeNull(types.string),
    field_28c_g: types.maybeNull(types.string),
    field_28c_h: types.maybeNull(types.string),
    field_28c_i: types.maybeNull(types.string),
    field_28c_j: types.maybeNull(types.string),
    field_28c_k: types.maybeNull(types.string),
    field_28d_a: types.maybeNull(types.string),
    field_28d_b: types.maybeNull(types.string),
    field_28d_c: types.maybeNull(types.string),
    field_28d_d: types.maybeNull(types.string),
    field_28d_e: types.maybeNull(types.string),
    field_28d_f: types.maybeNull(types.string),
    field_28d_g: types.maybeNull(types.string),
    field_28d_h: types.maybeNull(types.string),
    field_28d_i: types.maybeNull(types.string),
    field_28d_j: types.maybeNull(types.string),
    field_28d_k: types.maybeNull(types.string),
    field_29a_h: types.maybeNull(types.string),
    field_29a_k: types.maybeNull(types.string),
    field_29b_g: types.maybeNull(types.string),
    field_29b_i: types.maybeNull(types.string),
    field_29b_j: types.maybeNull(types.string),
    field_30_k: types.maybeNull(types.string),
    field_31_k: types.maybeNull(types.string),
    field_32_k: types.maybeNull(types.string),
    field_33a_a: types.maybeNull(types.string),
    field_33a_b: types.maybeNull(types.string),
    field_33a_c: types.maybeNull(types.string),
    field_33a_d: types.maybeNull(types.string),
    field_33a_e: types.maybeNull(types.string),
    field_33a_f: types.maybeNull(types.string),
    field_33b_a: types.maybeNull(types.string),
    field_33b_b: types.maybeNull(types.string),
    field_33b_c: types.maybeNull(types.string),
    field_33b_d: types.maybeNull(types.string),
    field_33b_e: types.maybeNull(types.string),
    field_33b_f: types.maybeNull(types.string),
    field_34a_d: types.maybeNull(types.string),
    field_34a_f: types.maybeNull(types.string),
    field_34b_c: types.maybeNull(types.string),
    field_34b_e: types.maybeNull(types.string),
    field_35_f: types.maybeNull(types.string),
    field_36_f: types.maybeNull(types.string),
    field_37_f: types.maybeNull(types.string),
    field_38_a: types.maybeNull(types.string),
    field_38_b: types.maybeNull(types.string),
    field_38_c: types.maybeNull(types.string),
    field_38_d: types.maybeNull(types.string),
    field_38_e: types.maybeNull(types.string),
    field_39: types.maybeNull(types.string),
    field_40: types.maybeNull(types.string),
    field_41: types.maybeNull(types.string),
    field_42: types.maybeNull(types.string),
    field_43: types.maybeNull(types.string),
    field_A: types.maybeNull(types.string),
    field_B: types.maybeNull(types.string),
    field_C: types.maybeNull(types.string),
    mainname: types.maybeNull(types.string),
    securityNumber: types.maybeNull(types.string),
})