import { observer } from "mobx-react";
import { FirstForm } from "../pages/first-form/first-form";
import { ScheduleFirst } from "../pages/schedule-one";
import { Route, Routes } from 'react-router-dom';
import { ScheduleTwo } from "../pages/schedule-two";
import { ScheduleThree } from "../pages/schedule-three";
import { ScheduleA } from "../pages/schedule-a";
import { ScheduleB } from "../pages/schedule-b";
import { ScheduleC } from "../pages/schedule-c";
import { ScheduleD } from "../pages/schedule-d";
import { ScheduleE } from "../pages/schedule-e";
import { FormW2 } from "../pages/form-w-2";
import { LoginPage } from "../pages/login";


export const AllRoutes = observer(() => {
    return (
        <Routes>
            <Route path="/" Component={LoginPage} />
            <Route path="/form_w2" Component={FormW2} />
            <Route path="/form1040" Component={FirstForm} />
            <Route path="/sch1" Component={ScheduleFirst} />
            <Route path="/sch2" Component={ScheduleTwo} />
            <Route path="/sch3" Component={ScheduleThree} />
            <Route path="/sch_a" Component={ScheduleA} />
            <Route path="/sch_b" Component={ScheduleB} />
            <Route path="/sch_c" Component={ScheduleC} />
            <Route path="/sch_d" Component={ScheduleD} />
            <Route path="/sch_e" Component={ScheduleE} />
        </Routes>
    )
})