import { Checkbox, Col, Form, Input, Radio, Row } from 'antd';
import style from '../style.module.scss';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useStore } from '../../../store/root-store';
import { useEffect } from 'react';

export const ScheduleB = observer(() => {
  const [scheduleBForm] = Form.useForm();
  const { setLoggedIn, firstFormStore: { handleScheduleB, getScheduleBForm } } = useStore('');
  useEffect(() => {
    setLoggedIn(true);
  }, []);
  useEffect(() => {
    // update fields on change of values after calculations from store
    scheduleBForm.setFieldsValue(getScheduleBForm);
  }, [getScheduleBForm]);
  const handleValuesChange = (data: any, allValues: any) => {
    // set values in store
    handleScheduleB(data, allValues);
  };
  return (
    <div className={style.incomeTaxReturnContainer}>
      <Form
        form={scheduleBForm}
        // onFinish={onFinish}
        name='formscheduleB'
        layout='vertical'
        onValuesChange={handleValuesChange}
        scrollToFirstError
      >
        <Row className={style.formItemStyle}>
          <Col span={6} className={style.borderRight}>
            <h2>"SCHEDULE B
              (Form 1040)"
            </h2>
            <p>
              Department of the Treasury	<br />
              Internal Revenue Service
            </p>
          </Col>
          <Col span={12} className={style.alignCenter}>
            <h2>Interest and Ordinary Dividends</h2>
            <h4>    Go to www.irs.gov/ScheduleB for instructions and the latest information.		<br />
              Attach to Form 1040A or 1040.											<br />																											</h4>
          </Col>
          <Col span={6} className={classnames(style.borderLeft, style.alignCenter)}>
            <div className={style.borderBottom}>OMB No. 1545-0074					</div>
            <h2>###
            </h2>
            <p>"Attachment
              Sequence  <b>08</b>"
            </p>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>

          <Col span={20}>
            <Form.Item
              label={'Name(s) shown on Form 1040 or 1040-SR.'}
              name={'mainname'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
              initialValue={`Obaid rehman & alina ali`}
            >
              <Input
                type={'text'}

              />
            </Form.Item>
          </Col>
          <Col span={4} className={style.borderLeft}>
            <Form.Item
              label={'Your social security number		'}
              name={'securityNumber'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}

              />
            </Form.Item>
          </Col>
        </Row>

        {/* Part I section start */}
        <Row className={style.formItemStyle}>
          <Col span={4}>
            <div>
              <h2>
                Part I
                Interest
              </h2>
              <p>(See instructions
                and the
                instructions for
                Form 1040 and
                1040-SR, line 2b.)
              </p>
              <div><b>Note:</b> If you
                received a  Form 1099-INT,  Form 1099-OID,  or substitute statement from  a brokerage firm,  list the firm’s  name as the  payer and enter  the total interest  shown on that form.
              </div>
            </div>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={15}>
                <div><b>1</b>List name of payer. If any interest is from a seller-financed mortgage and the
                  buyer used the property as a personal residence, see instructions and list this
                  interest first. Also, show that buyer’s social security number and address
                </div>
              </Col>
              <Col span={1} />
              <Col span={6}>
                <b>Amount</b>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                {[...Array(15)].map((_, index) => (
                  <Form.Item key={index} name={`field_1_desc_${index}`} rules={[]} className={style.noMarginBottom}>
                    <Input placeholder={`Input ${index + 1}`} />
                  </Form.Item>
                ))}
              </Col>
              <Col span={1} />
              <Col span={6}>
                <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                  <div className={style.addOnBefore}><b>1</b></div>
                  <div style={{ flexGrow: 1 }}>
                    {[...Array(15)].map((_, index) => (
                      <Form.Item key={index} name={`field_1_amount_${index}`} rules={[]} className={style.noMarginBottom}>
                        <Input placeholder={`Input ${index + 1}`} className={style.noLeftBorderRadius} />
                      </Form.Item>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>2</b>Add the amounts on line 1  .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
                  .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={1} />
              <Col span={6}>
                <Form.Item name={'field_2'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>2</b>}
                  disabled
                  className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>3</b>Excludable interest on series EE and I U.S. savings bonds issued after 1989.
                  Attach Form 8815			 .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
                  . </div>
              </Col>
              <Col span={1} />
              <Col span={6}>
                <Form.Item name={'field_3'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>3</b>}
                  // disabled
                  // className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>4</b>Subtract line 3 from line 2. Enter the result here and on Form 1040 or 1040-SR, line 2b .
                  . </div>
              </Col>
              <Col span={1} />
              <Col span={6}>
                <Form.Item name={'field_4'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>4</b>}
                  disabled
                  className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Part I section end */}


        {/* Part II section start */}
        <Row className={style.formItemStyle}>
          <Col span={4}>
            <div>
              <h2>
                Part II
                Ordinary
                Dividends

              </h2>
              <p>(See instructions
                and the
                instructions for
                Form 1040 and
                1040-SR, line 3b.)

              </p>
              <div><b>Note:</b> If you
                received a Form
                1099-DIV or
                substitute
                statement from
                a brokerage firm,
                list the firm's
                name as the
                payer and enter
                the ordinary
                dividends shown
                on that form.
              </div>
            </div>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={15}>
                <div><b>5</b>List name of payer
                </div>
              </Col>
              <Col span={1} />
              <Col span={6}>
                <b>Amount</b>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                {[...Array(15)].map((_, index) => (
                  <Form.Item key={index} name={`field_5_desc_${index}`} rules={[]} className={style.noMarginBottom}>
                    <Input placeholder={`Input ${index + 1}`} />
                  </Form.Item>
                ))}
              </Col>
              <Col span={1} />
              <Col span={6}>
                <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                  <div className={style.addOnBefore}><b>5</b></div>
                  <div style={{ flexGrow: 1 }}>
                    {[...Array(15)].map((_, index) => (
                      <Form.Item key={index} name={`field_5_amount_${index}`} rules={[]} className={style.noMarginBottom}>
                        <Input placeholder={`Input ${index + 1}`} className={style.noLeftBorderRadius} />
                      </Form.Item>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>6</b>Add the amounts on line 5. Enter the total here and on Form 1040 or 1040-SR, line 3b .   . </div>
              </Col>
              <Col span={1} />
              <Col span={6}>
                <Form.Item name={'field_6'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>6</b>}
                  disabled
                  className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Part II section end */}


        {/* Part III section start */}
        <Row className={style.formItemStyle}>
          <Col span={4}>
            <div>
              <h2>
                Part III
                Foreign Accounts
                and Trusts


              </h2>

              <div><b>Caution:</b> If
                required, failure to file FinCEN Form 114 may result in substantial penalties.
                Additionally, you may be required to file Form 8938, Statement of Specified Foreign Financial Assets.
                See instructions.

              </div>
            </div>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={15}>
                <div>You must complete this part if you (a) had over $1,500 of interest or dividends; (b) had a foreign
                  account; or (c) received a distribution from, or were a grantor of, or a transferor to, a foreign trust.

                </div>
              </Col>
              <Col span={1} />
              <Col span={6}>
                <b>Yes</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>No</b>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>7a</b>At any time during 2023, did you have interest in or a signature or other authority over a financial
                  account (such as a bank account, securities account, or brokerage account) located in a foreign
                  country? See instructions					 .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
                </div>
              </Col>
              <Col span={1} />
              <Col span={6}>
                <Form.Item name={'field_7a'} className={style.noMarginBottom}>
                  <Radio.Group>
                    <Radio value='yes'></Radio>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Radio value='no'></Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div>AIf “Yes,” are you required to file FinCEN Form 114, Report of Foreign Bank and Financial
                  Accounts (FBAR), to report that financial interest or signature authority? See FinCEN Form 114
                  and its instructions for filing requirements and exceptions to those requirements					.   .   .   .   .   .   .
                </div>
              </Col>
              <Col span={1} />
              <Col span={6}>
                <Form.Item name={'field_7a_yes'} className={style.noMarginBottom}>
                  <Radio.Group>
                    <Radio value='yes'></Radio>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Radio value='no'></Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>b</b>If you are required to file FinCEN Form 114, enter the name of the foreign country where the
                  financial account is located
                </div>
              </Col>
              <Col span={1} />
              <Col span={6}>
                <Form.Item name={'field_7b'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                  // disabled
                  // className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>8</b>During 2023, did you receive a distribution from, or were you the grantor of, or transferor to, a
                  foreign trust?  If "Yes," you may have to file Form 3520.  See instructions
                  .   .   .
                </div>
              </Col>
              <Col span={1} />
              <Col span={6}>
                <Form.Item name={'field_8'} className={style.noMarginBottom}>
                  <Radio.Group>
                    <Radio value='yes'></Radio>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Radio value='no'></Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Part III section end */}
      </Form>
    </div>
  );
});