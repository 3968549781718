import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Checkbox, Col, Form, Input, Radio, Row } from 'antd';
import style from '../style.module.scss';
import classnames from 'classnames';
import { useStore } from "../../../store/root-store";

export const ScheduleE = observer(() => {
  const [scheduleEForm] = Form.useForm();
  const { setLoggedIn, firstFormStore: { handleScheduleE, getScheduleEForm } } = useStore('');
  useEffect(() => {
    // update fields on change of values after calculations from store
    scheduleEForm.setFieldsValue(getScheduleEForm);
  }, [getScheduleEForm]);
  const handleValuesChange = (data: any, allValues: any) => {
    // set values in store
    handleScheduleE(data, allValues);
  };
  useEffect(() => {
    setLoggedIn(true);
  }, []);
  return (
    <div className={style.incomeTaxReturnContainer}>
      <Form
        form={scheduleEForm}
        // onFinish={onFinish}
        name='formscheduleE'
        layout='vertical'
        onValuesChange={handleValuesChange}
        scrollToFirstError
      >
        <Row className={style.formItemStyle}>
          <Col span={6} className={style.borderRight}>
            <h2>"SCHEDULE E
              (Form 1040)"
            </h2>
            <p>
              Department of the Treasury	<br />
              Internal Revenue Service
            </p>
          </Col>
          <Col span={12} className={style.alignCenter}>
            <h2>Supplemental Income and Loss</h2>
            <h3>(From rental real estate, royalties, partnerships, S corporations, estates, trusts, REMICs, etc.)</h3>
            <h4>    Attach to Form 1040, 1040-SR, 1040NR, or Form 1041.<br />
              Go to www.irs.gov/ScheduleE for instructions and the latest information.
              <br />																											</h4>
          </Col>
          <Col span={6} className={classnames(style.borderLeft, style.alignCenter)}>
            <div className={style.borderBottom}>OMB No. 1545-0074			</div>
            <h2>###
            </h2>
            <p>"Attachment
              Sequence  <b>13</b>"
            </p>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>

          <Col span={16}>
            <Form.Item
              label={'Name of Proprietor'}
              name={'mainname'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}

              />
            </Form.Item>
          </Col>
          <Col span={8} className={style.borderLeft}>
            <Form.Item
              label={'  Social security number (SSN)'}
              name={'securityNumber'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}

              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={3} style={{ background: '#121212', color: '#fff' }}>
            <h3><b>Part I</b>
            </h3>
          </Col>
          <Col span={11}><h3>Income or Loss From Rental Real Estate and Royalties
          </h3><b>Note: If you are in business of renting personal property, use Schedule C.  See instructions.  If you are an individual,
            report farm rental income or loss from Form 4835 on page 2, line 40.
            </b></Col>
          <Col span={6}>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>A</b></Col>
          <Col span={15}>Did you make any payments in 2023 that would require you to file Form(s) 1099?  See instructions.</Col>
          <Col span={6}>
            <Form.Item name={'dispose_investment'} className={style.noMarginBottom}>
              <Checkbox.Group>
                <Checkbox value={'yes'}>Yes</Checkbox>
                <Checkbox value={'no'}>No</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>B</b></Col>
          <Col span={15}>If “Yes,” did you or will you file all required Forms 1099?</Col>
          <Col span={6}>
            <Form.Item name={'dispose_investment'} className={style.noMarginBottom}>
              <Checkbox.Group>
                <Checkbox value={'yes'}>Yes</Checkbox>
                <Checkbox value={'no'}>No</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.borderTop}>
          <Col span={1}><b>A</b></Col>
          <Col span={21}>
            <Form.Item name={'field_A'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>B</b></Col>
          <Col span={21}>
            <Form.Item name={'field_B'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.borderBottom}>
          <Col span={1}><b>C</b></Col>
          <Col span={21}>
            <Form.Item name={'field_C'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <div><b>1b</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Type of Property (from list below)</div>
            <Form.Item className={style.noMarginBottom} name={'field_1b_A'}>
              <Input type="text" addonBefore={'A'} />
            </Form.Item>
            <Form.Item className={style.noMarginBottom} name={'field_1b_B'}>
              <Input type="text" addonBefore={'B'} />
            </Form.Item>
            <Form.Item className={style.noMarginBottom} name={'field_1b_C'}>
              <Input type="text" addonBefore={'C'} />
            </Form.Item>
          </Col>
          <Col span={19} className={style.borderLeft}>
            <Row>
              <Col span={6}><div><b>2</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For each rental real estate property listed
                above, report the number of fair rental and
                personal use days. Check the QJV box only
                if you meet the requirements to file as a
                qualified joint venture. See instructions.
              </div></Col>
              <Col span={6} className={style.borderLeft}>
                <div>Fair Rental Days</div>
                <Form.Item className={style.noMarginBottom} name={'field_1b_A'}>
                  <Input type="text" addonBefore={'A'} />
                </Form.Item>
                <Form.Item className={style.noMarginBottom} name={'field_1b_B'}>
                  <Input type="text" addonBefore={'B'} />
                </Form.Item>
                <Form.Item className={style.noMarginBottom} name={'field_1b_C'}>
                  <Input type="text" addonBefore={'C'} />
                </Form.Item></Col>
              <Col span={6} className={style.borderLeft}>
                <div>Personal Use Days</div>
                <Form.Item className={style.noMarginBottom} name={'field_1b_A'}>
                  <Input type="text" />
                </Form.Item>
                <Form.Item className={style.noMarginBottom} name={'field_1b_B'}>
                  <Input type="text" />
                </Form.Item>
                <Form.Item className={style.noMarginBottom} name={'field_1b_C'}>
                  <Input type="text" />
                </Form.Item></Col>
              <Col span={6} className={style.borderLeft} style={{ textAlign: 'center' }}>
                <div>QJV</div>
                <Form.Item className={style.noMarginBottom} name={'field_1b_A'}>
                  <Checkbox />
                </Form.Item>
                <Form.Item className={style.noMarginBottom} name={'field_1b_B'}>
                  <Checkbox />
                </Form.Item>
                <Form.Item className={style.noMarginBottom} name={'field_1b_C'}>
                  <Checkbox />
                </Form.Item></Col>
            </Row>


          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={24}>Type of Property:</Col>
          <Col span={6}>1&nbsp;&nbsp;&nbsp;	Single Family Residence</Col>
          <Col span={6}>2&nbsp;&nbsp;&nbsp;	Multi-Family Residence</Col>
          <Col span={6}>3&nbsp;&nbsp;&nbsp;	Vacation/Short-Term Rental</Col>
          <Col span={6}>4&nbsp;&nbsp;&nbsp;	Commercial</Col>
          <Col span={6}>5&nbsp;&nbsp;&nbsp;	Land</Col>
          <Col span={6}>6&nbsp;&nbsp;&nbsp;	Royalties</Col>
          <Col span={6}>7&nbsp;&nbsp;&nbsp;	Self-Rental</Col>
          <Col span={6}>8&nbsp;&nbsp;&nbsp;	Other  (describe)</Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={9}></Col>
          <Col span={15} style={{ textAlign: 'center' }}><b>Properties:</b></Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={9}><b>Income:</b></Col>
          <Col span={5} style={{ textAlign: 'center' }}><b>A</b></Col>
          <Col span={5} style={{ textAlign: 'center' }}><b>B</b></Col>
          <Col span={5} style={{ textAlign: 'center' }}><b>C</b></Col>
        </Row>
        <Row>
          <Col span={1}><b>3</b></Col>
          <Col span={8}><div>Rents received															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_3_a'}>
              <Input type="text" addonBefore={'3'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_3_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_3_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>4</b></Col>
          <Col span={8}><div>Royalties received															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_4_a'}>
              <Input type="text" addonBefore={'4'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_4_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_4_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.borderTop}>
          <Col span={9}><b>Expenses:</b></Col>
          <Col span={5} className={style.borderLeft}>Single Family Residence</Col>
          <Col span={5} className={style.borderLeft}></Col>
          <Col span={5} className={style.borderLeft}></Col>
        </Row>

        <Row>
          <Col span={1}><b>5</b></Col>
          <Col span={8}><div>Advertising
            .   .   .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_5_a'}>
              <Input type="text" addonBefore={'5'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_5_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_5_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>6</b></Col>
          <Col span={8}><div>Auto and travel (see instructions)
            .   .   .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_6_a'}>
              <Input type="text" addonBefore={'6'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_6_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_6_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>7</b></Col>
          <Col span={8}><div>Cleaning and maintenance
            .   .   .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_7_a'}>
              <Input type="text" addonBefore={'7'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_7_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_7_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>8</b></Col>
          <Col span={8}><div>Commissions															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            .   .   .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_8_a'}>
              <Input type="text" addonBefore={'8'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_8_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_8_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>9</b></Col>
          <Col span={8}><div>Insurance
            .   .   .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_9_a'}>
              <Input type="text" addonBefore={'9'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_9_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_9_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>10</b></Col>
          <Col span={8}><div>Legal and other professional fees															.   .   .   .   .   .   .   .   .
            .   .   .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_10_a'}>
              <Input type="text" addonBefore={'10'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_10_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_10_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>11</b></Col>
          <Col span={8}><div>Management fees															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            .   .   .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_11_a'}>
              <Input type="text" addonBefore={'11'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_11_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_11_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>12</b></Col>
          <Col span={8}><div>Mortgage interest paid to banks, etc. (see instructions)
            .   .   .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_12_a'}>
              <Input type="text" addonBefore={'12'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_12_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_12_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>13</b></Col>
          <Col span={8}><div>Other interest															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            .   .   .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_13_a'}>
              <Input type="text" addonBefore={'13'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_13_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_13_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>14</b></Col>
          <Col span={8}><div>Repairs															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_14_a'}>
              <Input type="text" addonBefore={'14'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_14_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_14_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>15</b></Col>
          <Col span={8}><div>Supplies															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            .   .   .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_15_a'}>
              <Input type="text" addonBefore={'15'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_15_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_15_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>16</b></Col>
          <Col span={8}><div>Taxes															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            .   .   .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_16_a'}>
              <Input type="text" addonBefore={'16'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_16_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_16_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>17</b></Col>
          <Col span={8}><div>Utilities															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            .   .   .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_17_a'}>
              <Input type="text" addonBefore={'17'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_17_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_17_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>18</b></Col>
          <Col span={8}><div>Depreciation expense or depletion															.   .   .   .   .   .   .   .   .
            .   .   .   .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_18_a'}>
              <Input type="text" addonBefore={'18'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_18_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_18_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>19</b></Col>
          <Col span={8}><div>Other (list). </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_19_a'}>
              <Input type="text" addonBefore={'19'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_19_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_19_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>20</b></Col>
          <Col span={8}><div>Total expenses. Add lines 5 through 19															.   .   .   .   .   .   .
            .   .   .   .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_20_a'}>
              <Input type="text" addonBefore={'20'} disabled
                className={style.formulaInput} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_20_b'}>
              <Input type="text" disabled
                className={style.formulaInput} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_20_c'}>
              <Input type="text" disabled
                className={style.formulaInput} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>21</b></Col>
          <Col span={8}><div>Subtract line 20 from line 3 (rents) and/or 4 (royalties).
            If result is a (loss), see instructions to find out if you
            must file Form 6198															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            .   .   .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_21_a'}>
              <Input type="text" addonBefore={'21'} disabled
                className={style.formulaInput} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_21_b'}>
              <Input type="text" disabled
                className={style.formulaInput} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_21_c'}>
              <Input type="text" disabled
                className={style.formulaInput} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>22</b></Col>
          <Col span={8}><div>Deductible rental real estate loss after limitation, if any,
            on Form 8582 (see instructions)															.   .   .   .   .   .   .   .   .  .   .   .   .   .   .   . </div></Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_22_a'}>
              <Input type="text" addonBefore={'22'} />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_22_b'}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={5} className={style.borderLeft}>
            <Form.Item className={style.noMarginBottom} name={'field_22_c'}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>23a</b></Col>
          <Col span={13}><div>Total of all amounts reported on line 3 for all rental properties																							.   .   .   .   .   .   .   .
          </div></Col>
          <Col span={5}>
            <Form.Item className={style.noMarginBottom} name={'field_23a'}>
              <Input type="text" addonBefore={'23a'} disabled
                className={style.formulaInput} />
            </Form.Item>
          </Col>
          <Col span={5}></Col>
        </Row>
        <Row>
          <Col span={1}><b>b</b></Col>
          <Col span={13}><div>Total of all amounts reported on line 4 for all royalty properties																							.   .   .   .   .   .   .
          </div></Col>
          <Col span={5}>
            <Form.Item className={style.noMarginBottom} name={'field_23b'}>
              <Input type="text" addonBefore={'23b'} />
            </Form.Item>
          </Col>
          <Col span={5}></Col>
        </Row>
        <Row>
          <Col span={1}><b>c</b></Col>
          <Col span={13}><div>Total of all amounts reported on line 12 for all properties																							.   .   .   .   .   .   .   .   .   .
          </div></Col>
          <Col span={5}>
            <Form.Item className={style.noMarginBottom} name={'field_23c'}>
              <Input type="text" addonBefore={'23c'} disabled
                className={style.formulaInput} />
            </Form.Item>
          </Col>
          <Col span={5}></Col>
        </Row>
        <Row>
          <Col span={1}><b>d</b></Col>
          <Col span={13}><div>Total of all amounts reported on line 18 for all properties																							.   .   .   .   .   .   .   .   .   .
          </div></Col>
          <Col span={5}>
            <Form.Item className={style.noMarginBottom} name={'field_23d'}>
              <Input type="text" addonBefore={'23d'} disabled
                className={style.formulaInput} />
            </Form.Item>
          </Col>
          <Col span={5}></Col>
        </Row>
        <Row>
          <Col span={1}><b>e</b></Col>
          <Col span={13}><div>Total of all amounts reported on line 20 for all properties																							.   .   .   .   .   .   .   .   .   .
          </div></Col>
          <Col span={5}>
            <Form.Item className={style.noMarginBottom} name={'field_23e'}>
              <Input type="text" addonBefore={'23e'} disabled
                className={style.formulaInput} />
            </Form.Item>
          </Col>
          <Col span={5}></Col>
        </Row>
        <Row>
          <Col span={1}><b>24</b></Col>
          <Col span={18}><div><b>Income</b>. Add positive amounts shown on line 21. Do not include any losses																													.   .   .   .   .   .   .   .   .   .   .
          </div></Col>
          <Col span={5}>
            <Form.Item className={style.noMarginBottom} name={'field_24'}>
              <Input type="text" addonBefore={'24'} disabled
                className={style.formulaInput} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>25</b></Col>
          <Col span={18}><div><b>Losses</b>. Add royalty losses from line 21 and rental real estate losses from line 22. Enter total losses here
          </div></Col>
          <Col span={5}>
            <Form.Item className={style.noMarginBottom} name={'field_25'}>
              <Input type="text" addonBefore={'25'} disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>26</b></Col>
          <Col span={18}><div><b>Total rental real estate and royalty income or (loss)</b>. Combine lines 24 and 25. Enter the result
            here. If Parts II, III, IV, and line 40 on page 2 do not apply to you, also enter this amount on
            Schedule 1 (Form 1040), line 5. Otherwise, include this amount in the total on line 41 on page 2																													.   .
          </div></Col>
          <Col span={5}>
            <Form.Item className={style.noMarginBottom} name={'field_26'}>
              <Input type="text" addonBefore={'26'} disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
        </Row>

        <Row className={style.formItemStyle}>

          <Col span={16}>
            <b>For Paperwork Reduction Act Notice, see the separate instructions.
            </b>
            <br />
            <div>Schedule E (Form 1040) 2023
            </div>
          </Col>
          <Col span={4}>	Cat. No. 11344L<br />
            Attachment Sequence No.						13

          </Col>
          <Col span={4} >
            <b>	Schedule E (Form 1040) 2023
            </b>
            <br />
            <div>	Page <b>2</b></div>
          </Col>
        </Row>

        <Row className={style.formItemStyle}>

          <Col span={16}>
            <Form.Item
              label={'Name(s) shown on return.						Do not enter name and social security number if shown on other side.              '}
              name={'mainname'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}

              />
            </Form.Item>
          </Col>
          <Col span={8} className={style.borderLeft}>
            <Form.Item
              label={'  Social security number (SSN)'}
              name={'securityNumber'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}

              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={3} style={{ background: '#121212', color: '#fff' }}>
            <h3><b>Part II</b>
            </h3>
          </Col>
          <Col span={11}><h3>Income or Loss From Partnerships and S Corporations
          </h3><b>Note:  If you report a loss, receive a distribution, dispose of stock, or receive a loan repayment from an S corporation, you must check
            the box in column (e) on line 28 and attach the required basis computation. If you report a loss from an at-risk activity for which any
            amount is not at risk, you must check the box in column (f) on line 28 and attach Form 6198 (see instructions).
            </b></Col>
          <Col span={6}>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>27</b></Col>
          <Col span={15}>Are you reporting any loss not allowed in a prior year due to the at-risk or basis limitations, a prior year unallowed loss from a
            passive activity (if that loss was not reported on Form 8582), or unreimbursed partnership expenses?
            If you answered “Yes,” see instructions before completing this section																														.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
          </Col>
          <Col span={6}>
            <Form.Item name={'field_27'} className={style.noMarginBottom}>
              <Checkbox.Group>
                <Checkbox value={'yes'}>Yes</Checkbox>
                <Checkbox value={'no'}>No</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row className={style.formItemStyle}>
          <Col span={1}><b>28</b></Col>
          <Col span={5}>(a)  Name</Col>
          <Col span={4}>(b)  Enter P for partnership; S
            for S corporation
          </Col>
          <Col span={4}>(c)  Check if
            foreign
            partnership
          </Col>
          <Col span={4}>(d)  Employer
            identification
            number
          </Col>
          <Col span={3}>(e) Check if
            basis computation
            is required
          </Col>
          <Col span={3}>(f)  Check if
            any amount is
            not at risk
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={6}>
            <Form.Item name={'field_28a_a'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'A'} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28a_b'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28a_c'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28a_d'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name={'field_28a_e'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name={'field_28a_f'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={6}>
            <Form.Item name={'field_28b_a'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'B'} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28b_b'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28b_c'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28b_d'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name={'field_28b_e'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name={'field_28b_f'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={6}>
            <Form.Item name={'field_28c_a'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'C'} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28c_b'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28c_c'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28c_d'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name={'field_28c_e'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name={'field_28c_f'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={6}>
            <Form.Item name={'field_28d_a'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'D'} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28d_b'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28d_c'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28d_d'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name={'field_28d_e'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name={'field_28d_f'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={10}><b>Passive Income and Loss</b></Col>
          <Col span={14}><b>Nonpassive Income and Loss</b></Col>
        </Row>

        <Row className={style.formItemStyle}>
          <Col span={1}><b></b></Col>
          <Col span={5}>(g)  Passive loss allowed
            (attach Form 8582 if required)
          </Col>
          <Col span={4}>(h)  Passive income
            from Schedule K-1
          </Col>
          <Col span={4}>(i)  Nonpassive loss allowed
            (see Schedule K-1)
          </Col>
          <Col span={4}>(j)  Section 179 expense
            deduction from Form 4562
          </Col>
          <Col span={6}>(k)  Nonpassive income
            from Schedule K-1
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={6}>
            <Form.Item name={'field_28a_g'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'A'} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28a_h'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28a_i'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28a_j'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_28a_k'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={6}>
            <Form.Item name={'field_28b_g'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'B'} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28b_h'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28b_i'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28b_j'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_28b_k'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={6}>
            <Form.Item name={'field_28c_g'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'C'} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28c_h'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28c_i'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28c_j'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_28c_k'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={6}>
            <Form.Item name={'field_28d_g'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'D'} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28d_h'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28d_i'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_28d_j'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_28d_k'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={6}>
            <b>29a</b>&nbsp;&nbsp;&nbsp;&nbsp;Totals
          </Col>
          <Col span={4}>
            <Form.Item name={'field_29a_h'} className={style.noMarginBottom}>
              <Input type="text" disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
          <Col span={4}></Col>
          <Col span={4}></Col>
          <Col span={6}>
            <Form.Item name={'field_29a_k'} className={style.noMarginBottom}>
              <Input type="text" disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <b>b</b>&nbsp;&nbsp;&nbsp;&nbsp;Totals
            <Form.Item name={'field_29b_g'} className={style.noMarginBottom}>
              <Input type="text" disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
          <Col span={4}></Col>
          <Col span={4}>
            <Form.Item name={'field_29b_i'} className={style.noMarginBottom}>
              <Input type="text" disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_29b_j'} className={style.noMarginBottom}>
              <Input type="text" disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
          <Col span={6}></Col>
        </Row>

        <Row className={style.formItemStyle}>
          <Col span={1}><b>30</b></Col>
          <Col span={17}>
            Add columns (h) and (k) of line 29a																											.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </Col>
          <Col span={6}>
            <Form.Item name={'field_30_k'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'30'} disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={1}><b>31</b></Col>
          <Col span={17}>Add columns (g), (i), and (j) of line 29b																											.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
          </Col>
          <Col span={6}>
            <Form.Item name={'field_31_k'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'31'} disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={1}><b>32</b></Col>
          <Col span={17}>Total partnership and S corporation income or (loss). Combine lines 30 and 31																											.   .   .   .
          </Col>
          <Col span={6}>
            <Form.Item name={'field_32_k'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'32'} disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
        </Row>

        <Row className={style.formItemStyle}>
          <Col span={3} style={{ background: '#121212', color: '#fff' }}>
            <b>Part III</b>
          </Col>
          <Col span={11}><b>Income or Loss From Estates and Trusts</b></Col>
          <Col span={6}>
          </Col>
        </Row>


        <Row className={style.formItemStyle}>
          <Col span={1}><b>33</b></Col>
          <Col span={17}>(a)  Name</Col>
          <Col span={6}>(b)  Employer
            identification number
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={18}>
            <Form.Item name={'field_33a_a'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'A'} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_33a_b'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={18}>
            <Form.Item name={'field_33b_a'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'B'} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_33b_b'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={10}><b>Passive Income and Loss</b></Col>
          <Col span={14}><b>Nonpassive Income and Loss</b></Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={6}>(c) Passive deduction or loss allowed
            (attach Form 8582 if required)
          </Col>
          <Col span={6}>(d) Passive income
            from Schedule K-1
          </Col>
          <Col span={6}>(e) Deduction or loss
            from Schedule K-1
          </Col>
          <Col span={6}>(f) Other income from
            Schedule K-1
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={6}>
            <Form.Item name={'field_33a_c'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'A'} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_33a_d'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_33a_e'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_33a_f'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={6}>
            <Form.Item name={'field_33b_c'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'B'} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_33b_d'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_33b_e'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_33b_f'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={6}>
            <b>34a</b>&nbsp;&nbsp;&nbsp;&nbsp;Totals
          </Col>
          <Col span={6}>
            <Form.Item name={'field_34a_d'} className={style.noMarginBottom}>
              <Input type="text" disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
          <Col span={6}></Col>
          <Col span={6}>
            <Form.Item name={'field_34a_f'} className={style.noMarginBottom}>
              <Input type="text" disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <b>b</b>&nbsp;&nbsp;&nbsp;&nbsp;Totals
            <Form.Item name={'field_34b_c'} className={style.noMarginBottom}>
              <Input type="text" disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
          <Col span={6}></Col>
          <Col span={6}>
            <Form.Item name={'field_34b_e'} className={style.noMarginBottom}>
              <Input type="text" disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
          <Col span={6}></Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={1}><b>35</b></Col>
          <Col span={17}>Add columns (d) and (f) of line 34a																													.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
          </Col>
          <Col span={6}>
            <Form.Item name={'field_35_f'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'35'} disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={1}><b>36</b></Col>
          <Col span={17}>Add columns (c) and (e) of line 34b																													.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
          </Col>
          <Col span={6}>
            <Form.Item name={'field_36_f'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'36'} disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={1}><b>37</b></Col>
          <Col span={17}>Total estate and trust income or (loss). Combine lines 35 and 36																													.   .   .   .   .   .   .   .   .   .   .   .   .
          </Col>
          <Col span={6}>
            <Form.Item name={'field_37_f'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'37'} disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
        </Row>

        <Row className={style.formItemStyle}>
          <Col span={3} style={{ background: '#121212', color: '#fff' }}>
            <b>Part IV</b>
          </Col>
          <Col span={17}><b>Income or Loss From Real Estate Mortgage Investment Conduits (REMICs)—Residual Holder
          </b></Col>

        </Row>


        <Row className={style.formItemStyle}>
          <Col span={1}><b>38</b></Col>
          <Col span={5}>(a)  Name
          </Col>
          <Col span={4}>(b) Employer identification
            number
          </Col>
          <Col span={4}>(c) Excess inclusion from Schedules Q, line 2c
            (see instructions)
          </Col>
          <Col span={4}>(d)  Taxable income (net loss)
            from Schedules Q, line 1b
          </Col>
          <Col span={6}>(e)  Income from
            Schedules Q, line 3b
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={6}>
            <Form.Item name={'field_38_a'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_38_b'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_38_c'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name={'field_38_d'} className={style.noMarginBottom}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_38_e'} className={style.noMarginBottom} style={{ textAlign: 'center' }}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>

        <Row className={style.formItemStyle}>
          <Col span={1}><b>39</b></Col>
          <Col span={17}>Combine columns (d) and (e) only. Enter the result here and include in the total on line 41 below</Col>
          <Col span={6}>
            <Form.Item name={'field_39'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'39'} disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
        </Row>

        <Row className={style.formItemStyle}>
          <Col span={3} style={{ background: '#121212', color: '#fff' }}>
            <b>Part V</b>
          </Col>
          <Col span={17}><b>Summary</b></Col>
        </Row>

        <Row className={style.formItemStyle}>
          <Col span={1}><b>40</b></Col>
          <Col span={17}>Net farm rental income or (loss) from Form 4835. Also, complete line 42 below																													.   .   .   .   .   .   .   .   .   .
          </Col>
          <Col span={6}>
            <Form.Item name={'field_40'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'40'} />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={1}><b>41</b></Col>
          <Col span={17}>{`Total income or (loss). Combine lines 26, 32, 37, 39, and 40. Enter the result here and on Schedule																													
1 (Form 1040), line 5																													.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . 
`}</Col>
          <Col span={6}>
            <Form.Item name={'field_41'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'41'} disabled
                className={style.formulaInput}/>
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={1}><b>42</b></Col>
          <Col span={17}>{`Reconciliation of farming and fishing income.  Enter your gross																				
            farming and fishing income reported on Form 4835, line 7; Schedule K-1																				
            Form 1065), box 14, code B; Schedule K-1 (Form 1120S), box 17, code																				
            AD; and Schedule K-1 (Form 1041), box 14, code F (see instructions)																				 . 
            `}</Col>
          <Col span={6}>
            <Form.Item name={'field_42'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'42'} />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={1}><b>43</b></Col>
          <Col span={17}>Reconciliation for real estate professionals. If you were a real estate
            professional (see instructions),  enter the net income or (loss) you
            reported  anywhere on Form 1040, Form 1040-SR, or Form 1040-NR
            from all rental real estate activities in which you materially participated
            under the passive activity loss rules																				.   .   .   .   .   .   .   .   .   .   .   .   .
          </Col>
          <Col span={6}>
            <Form.Item name={'field_43'} className={style.noMarginBottom}>
              <Input type="text" addonBefore={'43'} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
});