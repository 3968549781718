// import makeInspectable from 'mobx-devtools-mst';
import {initFirstFormStore, FirstFormStore} from './sub-store/firstFormStore';
import {Instance, types} from 'mobx-state-tree';
import {useMemo} from 'react';

const RootStore = types.model({
  firstFormStore: FirstFormStore,
  loggedIn: types.boolean,
}).actions(self => {
  const setLoggedIn = (value: boolean) => {
    self.loggedIn = value;
  }
  return {
    setLoggedIn,
  };
});

export type RootStoreType = Instance<typeof RootStore>;

let store: RootStoreType;

export function initializeStore(snapshot = null) {
  const _store =
    store ??
    RootStore.create({
      firstFormStore: initFirstFormStore(),
      loggedIn: false,
    });

  if (typeof window === 'undefined') {
    return _store;
  }
  if (!store) {
    store = _store;
  }
//   makeInspectable(store);
  return store;
}

export function useStore(initialState: any): Instance<typeof RootStore> {
  return useMemo(() => initializeStore(initialState), [initialState]);
}
