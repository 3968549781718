import { Col, Form, Input, Row } from 'antd';
import style from '../style.module.scss';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useStore } from '../../../store/root-store';

export const ScheduleTwo = observer(() => {
  const { setLoggedIn, firstFormStore: { handleSchedule2, getSchedule2Form } } = useStore('');
  const [schedule2Form] = Form.useForm();
  useEffect(() => {
    setLoggedIn(true);
  }, []);
  useEffect(() => {
    // update fields on change of values after calculations from store
    schedule2Form.setFieldsValue(getSchedule2Form);
  }, [getSchedule2Form]);
  const handleValuesChange = (data: any, allValues: any) => {
    // set values in store
    handleSchedule2(data, allValues);
  };
  return (
    <div className={style.incomeTaxReturnContainer}>
      <Form
        form={schedule2Form}
        // onFinish={onFinish}
        name='formschedule2'
        layout='vertical'
        onValuesChange={handleValuesChange}
        scrollToFirstError
      >
        <Row className={style.formItemStyle}>
          <Col span={6} className={style.borderRight}>
            <h2>"SCHEDULE 2
              (Form 1040)"
            </h2>
            <p>
              Department of the Treasury	<br />
              Internal Revenue Service
            </p>
          </Col>
          <Col span={12} className={style.alignCenter}>
            <h2>Additional Taxes</h2>
            <h4>	Attach to Form 1040, 1040-SR, or 1040-NR.											<br />
              Go to www.irs.gov/Form1040 for instructions and the latest information.																																							</h4>
          </Col>
          <Col span={6} className={classnames(style.borderLeft, style.alignCenter)}>
            <div className={style.borderBottom}>OMB No. 1545-0074					</div>
            <h2>###
            </h2>
            <p>"Attachment
              Sequence  <b>02</b>"
            </p>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>

          <Col span={20}>
            <Form.Item
              label={'Name(s) shown on Form 1040, 1040-SR, or 1040-NR	'}
              name={'mainname'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
              initialValue={`Obaid rehman & alina ali`}
            >
              <Input
                type={'text'}
                
              />
            </Form.Item>
          </Col>
          <Col span={4} className={style.borderLeft}>
            <Form.Item
              label={'Your social security number		'}
              name={'securityNumber'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                
              />
            </Form.Item>
          </Col>
        </Row>


        {/* part one Tax start */}
        <Row className={style.formItemStyle}>
          <Col span={2} className={style.borderRight}>
            <h2>Part I
            </h2>

          </Col>
          <Col span={20}>
            <h2>Tax</h2>

          </Col>

        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>1</b></Col>
          <Col span={14}><div>Alternative minimum tax.  Attach Form 6251												.   .   .   .   .   .   .   .   .   .  </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_1'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>1</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>

        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>2</b></Col>
          <Col span={14}><div>Excess advance premium tax credit repayment. Attach Form 8962																									.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_2'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>2</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>

        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>3</b></Col>
          <Col span={14}><div>Add lines 1 and 2. Enter here and on Form 1040, 1040-SR, or 1040-NR, line 17																														. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .							  </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_3'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>3</b>}
                disabled
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* part one Tax end */}


        {/* part two Other Taxes start */}
        <Row className={style.formItemStyle}>
          <Col span={2} className={style.borderRight}>
            <h2>Part II
            </h2>

          </Col>
          <Col span={20}>
            <h2>Other Taxes</h2>

          </Col>

        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>4</b></Col>
          <Col span={14}><div>Self-employment tax. Attach Schedule SE . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_4'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>4</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>

        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>5</b></Col>
          <Col span={14}><div>Social security and Medicare tax on unreported tip income.
            Attach Form 4137															. . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_5'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>5</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>6</b></Col>
          <Col span={14}><div>Uncollected social security and Medicare tax on wages. Attach
            Form 8919																.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .  . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_6'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>6</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>7</b></Col>
          <Col span={14}><div>Total additional social security and Medicare tax.  Add lines 5 and 6																	. . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_7'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                disabled
                className={style.formulaInput}
                addonBefore={<b>7</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>8</b></Col>
          <Col span={14}><div>Additional tax on IRAs or other tax-favored accounts. Attach Form 5329 if required																					<br />If not required, check here						 . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_8'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>8</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>9</b></Col>
          <Col span={14}><div>Household employment taxes. Attach Schedule H													 . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_9'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>9</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>10</b></Col>
          <Col span={14}><div>Repayment of first-time homebuyer credit.  Attach Form 5405 if required																		 . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_10'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>10</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>11</b></Col>
          <Col span={14}><div>Additional Medicare Tax.  Attach Form 8959											 . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_11'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>11</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>12</b></Col>
          <Col span={14}><div>Net investment income tax.  Attach Form 8960												. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_12'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>12</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>13</b></Col>
          <Col span={14}><div>Uncollected social security and Medicare or RTTA tax on tips or group-term life
            insurance from Form W-2, box 12																				 . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_13'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>13</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>14</b></Col>
          <Col span={14}><div>Interest on tax due on installment income from the sale of certain residential lots
            and timeshares																					. . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_14'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>14</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>15</b></Col>
          <Col span={14}><div>Interest on the deferred tax on gain from certain installment sales with a sales price
            over $150,000																						.   .    .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .  </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_15'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>15</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>16</b></Col>
          <Col span={14}><div>Recapture of low-income housing credit. Attach Form 8611															 . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_16'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>16</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>

          <Col span={16}>
            <b>For Paperwork Reduction Act Notice, see your tax return instructions.																																</b>
            <br />
            <div>Schedule 2 (Form 1040)  2022						</div>
          </Col>
          <Col span={4}>	Cat. No. 71478U				</Col>
          <Col span={4} >
            <b>			Schedule 2 (Form 1040) 2022</b>
            <br />
            <div>	Page <b>2</b></div>
          </Col>
        </Row>
        {/* part two Other Taxes end */}


        {/* part two Other Taxes page 2 start */}
        <Row className={style.formItemStyle}>
          <Col span={2} className={style.borderRight}>
            <h2>Part II
            </h2>

          </Col>
          <Col span={20}>
            <h2>Other Taxes <i>(Continued)</i></h2>

          </Col>

        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>17</b></Col>
          <Col span={14}><div>Other additional taxes:					 </div></Col>
          <Col span={8}>

          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>a</b></Col>
          <Col span={14}><div>Recapture of other credits. List type, form number, and
            amount:															. . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17a'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17a</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>b</b></Col>
          <Col span={14}><div>Recapture of federal mortgage subsidy. If you sold your home in
            2022, see instructions																.   .   .   .   .   .   .   .   .   .   .   .   . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17b'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17b</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>c</b></Col>
          <Col span={14}><div>Additional tax on HSA distributions.   Attach Form 8889														. . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17c'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17c</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>d</b></Col>
          <Col span={14}><div>Additional tax on an HSA because you didn't remain an eligible
            individual.  Attach Form 8889																.   .   .   .   .   .   .   .   .   .   .   .   .   .   . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17d'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17d</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>e</b></Col>
          <Col span={14}><div>Additional tax on Archer MSA distributions. Attach Form 8853																.   . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17e'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17e</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>f</b></Col>
          <Col span={14}><div>Additional tax on Medicare Advantage MSA distributions. Attach
            Form 8853																.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17f'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17f</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>g</b></Col>
          <Col span={14}><div>Recapture of a charitable contribution deduction related to a
            fractional interest in tangible personal property															 . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17g'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17g</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>h</b></Col>
          <Col span={14}><div>Income you received from a nonqualified deferred compen-
            sation plan that fails to meet the requirements of section 409A																. . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17h'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17h</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>i</b></Col>
          <Col span={14}><div>Compensation you received from a nonqualified deferred
            compensation plan described in section 457A															 . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17i'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17i</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>j</b></Col>
          <Col span={14}><div>Section 72(m)(5) excess benefits tax									 . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17j'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17j</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>k</b></Col>
          <Col span={14}><div> Golden parachute payments							. . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17k'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17k</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>l</b></Col>
          <Col span={14}><div> Tax on accumulation distribution of trusts										. . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17l'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17l</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>m</b></Col>
          <Col span={14}><div>Excise tax on insider stock compensation from an expatriated
            corporation																 .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .  . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17m'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17m</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>n</b></Col>
          <Col span={14}><div> Look-back interest under section 167(g) or 460(b) from Form
            8697 or 8866																.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17n'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17n</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>o</b></Col>
          <Col span={14}><div> Tax on non-effectively connected income for any part of the
            year you were a nonresident alien from Form 1040-NR															. . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17o'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17o</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>p</b></Col>
          <Col span={14}><div> Any interest from Form 8621, line 16f, relating to distributions
            from, and dispositions of, stock of a section 1291 fund																 .   .   .   .  . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17p'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17p</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>q</b></Col>
          <Col span={14}><div> Any interest from Form 8621, line 24									. . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17q'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17q</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>z</b></Col>
          <Col span={14}><div> Any other taxes. List type and amount									. . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_17z'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>17z</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>18</b></Col>
          <Col span={14}><div> Total additional taxes.  Add lines 17a through 17z													. . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_18'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>18</b>}
                disabled
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>19</b></Col>
          <Col span={14}><div> Reserved for future use						 . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_19'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>19</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>20</b></Col>
          <Col span={14}><div> Section 965 net tax liability installment from Form 965-A														. . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_20'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>20</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>21</b></Col>
          <Col span={14}><div> Add lines 4, 7 through 16, and 18.  These are your <b>total other taxes</b>.  Enter here and
            on Form 1040 or 1040-SR, line 23, or Form 1040-NR, line 23b																						.   .   .   .   .   .   .   .   .  . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_21'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                disabled
                addonBefore={<b>21</b>}
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* part two Other Taxes page 2 end */}
      </Form>
    </div>
  );
});

