import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Col, Form, Input, Row } from 'antd';
import style from '../style.module.scss';
import { useStore } from "../../../store/root-store";
import { debounce } from "lodash";
import { formW2Calculations } from "../../utils/formControl";

export const fieldsList = [
  { key: 'Wages-tips-other-compensation', number: '1', label: 'Wages, tips, other compensation  ', usedAt: 'Form 1040, Line 1.  ' },
  { key: 'Federal-income-tax-withheld', number: '2', label: 'Federal income tax withheld  ', usedAt: 'Form 1040, Line 25a  ' },
  { key: 'Social-security-wages', number: '3', label: 'Social security wages  ', usedAt: 'Sch. SE, Line 8a  ' },
  { key: 'Social-security-tax-withheld', number: '4', label: 'Social security tax withheld  ', usedAt: 'Line 13a (CTC), Child Tax Credit W/S, Line 11 W/S, Line 6; Form 8812, Line 9 W/S, Line 1  ' },
  { key: 'Medicare-wages-and-tips', number: '5', label: 'Medicare wages and tips  ', usedAt: 'Form 8959, Who Must File, Criterion 3; Form 8959, Additional Medicare Tax, Line 1  ' },
  { key: 'Medicare-tax-withheld', number: '6', label: 'Medicare tax withheld  ', usedAt: 'Line 13a, Child Tax Credit Worksheet, Line 14 Worksheet, Line 7; Form 8812, Line 9 W/S, Line 2; Form 8959, Additional Medicare Tax, Line 19  ' },
  { key: 'Social-Security-Tips', number: '7', label: 'Social Security Tips  ', usedAt: '' },
  { key: 'Allocated-Tips', number: '8', label: 'Allocated Tips  ', usedAt: '' },
  { key: 'Dependent-Care-Benefits', number: '10', label: 'Dependent Care Benefits  ', usedAt: 'Form 2441, Part III, Line 12  ' },
  { key: 'Nonqualified-plans', number: '11', label: 'Nonqualified plans  ', usedAt: '' },
  { key: 'Non-Taxable-Combat-Pay', number: '12a', label: 'Non Taxable Combat Pay (Code 12Q)  ', usedAt: 'Form 1040, line 1i  and  Line 27 (EIC), Step 5, line 4  ' },
  { key: 'See-instructions-for-box-12', number: '12b', label: 'See instructions for box 12  ', usedAt: '' },
  { key: '12C', number: '12c', label: '', usedAt: '' },
  { key: '12D', number: '12d', label: '', usedAt: '' },
  { key: 'Stat-Emp-Retire-Party-Sick', number: '13', label: 'Stat Emp / Retire / 3rd Party Sick  ', usedAt: '' },
  { key: 'Other', number: '14', label: 'Other  ', usedAt: '' },
  { key: 'Railroad-retire-compensation', number: '14a', label: 'Railroad retire (RRTA) compensation  ', usedAt: 'Form 8959, Additional Medicare Tax, Who Must File, Criterion 2 and 4; Line 14  ' },
  { key: 'RRTA-medicare-tax-withheld', number: '14b', label: 'RRTA medicare tax withheld (see Form CT-2)  ', usedAt: 'Form 8959, Additional Medicare Tax, Line 23  ' },
  { key: 'State-Employers-state-ID-number', number: '15', label: `State - Employer's state ID number  `, usedAt: '' },
  { key: 'State-wages-tips-etc', number: '16', label: 'State wages, tips, etc.   ', usedAt: '' },
  { key: 'State-income-tax', number: '17', label: 'State income tax  ', usedAt: 'Sch. A, Line 5  ' },
  { key: 'Local-wages-tips-etc', number: '18', label: 'Local wages, tips, etc.  ', usedAt: '' },
  { key: 'Local-income-tax', number: '19', label: 'Local income tax  ', usedAt: 'Sch. A, Line 5  ' },
  { key: 'Locality-name', number: '20', label: 'Locality name  ', usedAt: '' },
];
export const FormW2 = observer(() => {
  const { setLoggedIn, firstFormStore: { handleFormW2, getFormW2, loadW2FromData, setW2ValuesInForms } } = useStore('');
  const [formW2] = Form.useForm();
useEffect(() => {
  setLoggedIn(true);
  loadW2FromData();
}, []);
  useEffect(() => {
    // update fields on change of values after calculations from store
    formW2.setFieldsValue(getFormW2);
    const calculatedData = formW2Calculations({}, getFormW2);
    formW2.setFieldsValue(calculatedData);
    setW2ValuesInForms(calculatedData);
}, [getFormW2]);
  const handleValuesChange = debounce((data: any, allValues: any) => {
    // set values in store
    handleFormW2(data, allValues);
}, 500);
  
  return (
    <div className={style.incomeTaxReturnContainer}>
      <Form
        form={formW2}
        // onFinish={onFinish}
        name='formW2'
        layout='vertical'
        onValuesChange={handleValuesChange}
        scrollToFirstError
      >
        <Row className={style.formItemStyle}>
          <Col span={12}><h2>Information from Form W-2, Wage and Tax Statement</h2></Col>
          <Col span={2}><h4>Maximum SS Tax Earnings:</h4></Col>
          <Col span={2}><h4>$160,200.00</h4></Col>
          <Col span={2}><h4>SS Tax Rate:</h4></Col>
          <Col span={2}><h4>6.2%</h4></Col>
          <Col span={2}><h4>Max. SS Tax:</h4></Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={1}></Col>
          <Col span={6}><h4>Enter your name on the sheet labeled '1040'</h4></Col>
          <Col span={2}><h4>Employer #1</h4></Col>
          <Col span={2}><h4>Employer #2</h4></Col>
          <Col span={2}><h4>Employer #3</h4></Col>
          <Col span={2}><h4>Employer #4</h4></Col>
          <Col span={2}><h4>Employer #5</h4></Col>
          <Col span={2}><h4>Employer #6</h4></Col>
          <Col span={2}><h4>Total</h4></Col>
          <Col span={2}><h4>Excess</h4></Col>
        </Row>
        {fieldsList.map(item => {
          return <Row className={style.formItemStyle} key={item.number}>
            <Col span={1}>{item.number}</Col>
            <Col span={6}><h4 style={{ marginTop: 0, marginBottom: 0 }}>{item.label}</h4></Col>
            <Col span={2}>
              <Form.Item className={style.noMarginBottom} name={`${item.key}_Self_employer_1`}><Input type="text" /></Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item className={style.noMarginBottom} name={`${item.key}_Self_employer_2`}><Input type="text" /></Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item className={style.noMarginBottom} name={`${item.key}_Self_employer_3`}><Input type="text" /></Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item className={style.noMarginBottom} name={`${item.key}_Self_employer_4`}><Input type="text" /></Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item className={style.noMarginBottom} name={`${item.key}_Self_employer_5`}><Input type="text" /></Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item className={style.noMarginBottom} name={`${item.key}_Self_employer_6`}><Input type="text" /></Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item className={style.noMarginBottom} name={`${item.key}_Self_employer_7`}><Input type="text" disabled
                    className={style.formulaInput}/></Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item className={style.noMarginBottom} name={`${item.key}_Self_excess`}><Input type="text" /></Form.Item>
            </Col>
          </Row>
        })}
        <div className={style.borderTop} />
        <Row className={style.formItemStyle}>
          <Col span={12}><h2>Information from Form W-2, Wage and Tax Statement</h2></Col>
          <Col span={2}></Col>
          <Col span={2}></Col>
          <Col span={2}></Col>
          <Col span={2}></Col>
          <Col span={2}></Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={1}></Col>
          <Col span={6}><h4>Enter your spouse's on the sheet labeled '1040'</h4></Col>
          <Col span={2}><h4>Employer #1</h4></Col>
          <Col span={2}><h4>Employer #2</h4></Col>
          <Col span={2}><h4>Employer #3</h4></Col>
          <Col span={2}><h4>Employer #4</h4></Col>
          <Col span={2}><h4>Employer #5</h4></Col>
          <Col span={2}><h4>Employer #6</h4></Col>
          <Col span={2}><h4>Total</h4></Col>
          <Col span={2}><h4>Excess</h4></Col>
        </Row>
        {fieldsList.map(item => {
          return <Row className={style.formItemStyle} key={item.number}>
            <Col span={1}>{item.number}</Col>
            <Col span={6}><h4 style={{ marginTop: 0, marginBottom: 0 }}>{item.label}</h4></Col>
            <Col span={2}>
              <Form.Item className={style.noMarginBottom} name={`${item.key}_Spouse_employer_1`}><Input type="text" /></Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item className={style.noMarginBottom} name={`${item.key}_Spouse_employer_2`}><Input type="text" /></Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item className={style.noMarginBottom} name={`${item.key}_Spouse_employer_3`}><Input type="text" /></Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item className={style.noMarginBottom} name={`${item.key}_Spouse_employer_4`}><Input type="text" /></Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item className={style.noMarginBottom} name={`${item.key}_Spouse_employer_5`}><Input type="text" /></Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item className={style.noMarginBottom} name={`${item.key}_Spouse_employer_6`}><Input type="text" /></Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item className={style.noMarginBottom} name={`${item.key}_Spouse_employer_7`}><Input type="text" disabled
                    className={style.formulaInput}/></Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item className={style.noMarginBottom} name={`${item.key}_Spouse_excess`}><Input type="text" /></Form.Item>
            </Col>
          </Row>
        })}

        <div className={style.borderTop} style={{ height: '100px' }} />
        <Row className={style.formItemStyle}>
          <Col span={1}></Col>
          <Col span={6}><h4>Total's</h4></Col>
          <Col span={3}><h4>Total's</h4></Col>
          <Col span={11}><h4>Used at:</h4></Col>
          <Col span={2}><h4>Excess Soc. Sec. Tax Withheld</h4></Col>
        </Row>
        {fieldsList.map(item => {
          return <Row className={style.formItemStyle} key={item.number}>
            <Col span={1}>{item.number}</Col>
            <Col span={6}><h4 style={{ marginTop: 0, marginBottom: 0 }}>{item.label}</h4></Col>
            <Col span={3}>
              <Form.Item className={style.noMarginBottom} name={`total_field_${item.number}`}><Input type="text" disabled
                    className={style.formulaInput}/></Form.Item>
            </Col>
            <Col span={11}>{item.usedAt}</Col>
            <Col span={2}>
              <Form.Item className={style.noMarginBottom} name={`total_field_${item.number}_excess_withheld`}><Input type="text" /></Form.Item>
            </Col>
          </Row>
        })}
      </Form>
    </div>
  );
});