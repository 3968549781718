import React, { useState } from 'react';
import { Input } from 'antd';
import { styled } from 'styled-components';

const BoxInput = () => {
  const [values, setValues] = useState(['', '', '', '', '']);

  const handleChange = (index: number, value: string) => {
    const newValues = [...values];
    newValues[index] = value;

    if (value.length === 1 && index < values.length - 1) {
      const nextInput = document.getElementById(`input-${index + 1}`);
      nextInput?.focus();
    }

    setValues(newValues);
  };

  return (
    <div>
      {values.map((value, index) => (
        <InputStyled
          key={index}
          id={`input-${index}`}
          maxLength={1}
          value={value}
          onChange={(e: { target: { value: string; }; }) => handleChange(index, e.target.value)}
        />
      ))}
    </div>
  );
};

export default BoxInput;


const InputStyled = styled(Input)`
width: 32px;
`;

