import { fieldsList } from "../pages/form-w-2";
import { calculateIncomeTax, getStandardDeduction, subtractValues, sumValues } from "./const";

// main logics function that run on form values change are placed here
export const firstFormCalculations = (data: any, allValues: { [x: string]: any; }) => {
    const filingStatus = allValues['filing_status'] || 'single';
      const taxableIncome = 50000;
      const numCheckboxesChecked = allValues['age_blindness']?.length || 0;
      const standardDeduction = getStandardDeduction(filingStatus, taxableIncome, numCheckboxesChecked);
      // notification.info({message: `value: ${standardDeduction}`});

      const checkAgeBlind = allValues['age_blindness'] || [];
      const payload = {
        'id': 1,
        'filing_status': allValues['filing_status'],
        'first_name': 'John',
        'middle_initial': 'D',
        'last_name': 'Doe',
        'you_ssn': '123-45-6789',
        'spouse_ssn': '987-65-4321',
        'occupation': 'Engineer',
        'spouse_first_name': 'Jane',
        'spouse_middle_initial': 'M',
        'spouse_last_name': 'Doe',
        'spouse_occupation': 'Doctor',
        'phone_number': '555-123-4567',
        'email_address': 'john@example.com',
        'home_address': '123 Main St',
        'apartment_no': 'Apt 4',
        'city': 'Cityville',
        'state': 'ST',
        'zip': '12345',
        'foreign_country': 'Canada',
        'foreign_province': 'Ontario',
        'foreign_postal_code': 'M1M 1M1',
        'you_presidential_election': true,
        'spouse_presidential_election': true,
        'you_blind': checkAgeBlind?.includes('you_blind'),
        'Were_born_before_1958': checkAgeBlind?.includes('Were_born_before_1958'),
        'spouse_blind': checkAgeBlind?.includes('spouse_blind'),
        'spouse_born_before_1958': checkAgeBlind?.includes('spouse_born_before_1958'),
        'digital_assets': false,
        'number_dependents': 2,
        'claimed_as_dependent': false,
        'standard_deduction_exceptions': true,
        'field_1a': 1000.0,
        'field_1b': 2000.0,
        'field_1c': 3000.0,
        'field_1d': 4000.0,
        'field_1e': 5000.0,
        'field_1f': 6000.0,
        'field_1g': 7000.0,
        'field_1h': 8000.0,
        'field_1i': 9000.0,
        'field_1z': 10000.0,
        'field_2a': 20000.0,
        'field_2b': 30000.0,
        'field_3a': 40000.0,
        'field_3b': 50000.0,
        'field_4a': 60000.0,
        'field_4b': 70000.0,
        'field_5a': 80000.0,
        'field_5b': 90000.0,
        'field_6a': null,
        'field_6b': null,
        'field_6c': null,
        'field_7_checkbox': true,
        'field_7': 1000.0,
        'schedule_1_additional_income': false,
        'field_8': null,
        'field_9': 500.0,
        'field_10': null,
        'field_11': 200.0,
        'itemizing': true,
        'field_12': 100.0,
        'field_13': 200.0,
        'field_14': 300.0,
        'field_15': 400.0,
        'field_16': 500.0,
        'schedule_2_part_i_needed': false,
        'field_17': null,
        'field_18': 600.0,
        'field_19': 700.0,
        'need_schedule_3_part_i': true,
        'field_20': null,
        'field_21': 300.0,
        'field_22': 400.0,
        'field_23': null,
        'field_24': 500.0,
        'field_25a': null,
        'field_25b': 600.0,
        'field_25c': 700.0,
        'field_25d': 800.0,
        'field_26': 900.0,
        'field_27': null,
        'field_28': 1000.0,
        'field_29': null,
        'field_30': null,
        'field_31': null,
        'field_32': 1100.0,
        'field_33': 1200.0,
        'field_34': null,
        'field_35a': null,
        'field_35b': 'Some value',
        'field_35c': null,
        'field_35d': 'Some value',
        'field_36': null,
        'field_37': null,
        'field_38': 1300.0,
        'dependents': [],
      };
      // const response = await postFormData(payload);
      // form.setFieldsValue(response);

      const sum1ato1h = sumValues([allValues['field_1a'], allValues['field_1b'], allValues['field_1c'], allValues['field_1d'], allValues['field_1e'], allValues['field_1f'], allValues['field_1g'], allValues['field_1h']]);
      const sum2bto8 = sumValues([sum1ato1h, allValues['field_2b'], allValues['field_3b'], allValues['field_4b'], allValues['field_5b'], allValues['field_6b'], allValues['field_7'], allValues['field_8']]);
      const sub10from9 = subtractValues(sum2bto8, allValues['field_10']);
      const sum12and13 = sumValues([standardDeduction, allValues['field_13']]);
      const sub14from11 = subtractValues(sub10from9 as number, sum12and13);
      const income = Number(sub14from11) < 0 ? 0 : sub14from11;
      const taxLiability = calculateIncomeTax(Number(income), allValues['filing_status']);
      const valueOf18 = sumValues([taxLiability, allValues['field_17']]);
      const valueOf21 = sumValues([allValues['field_19'], allValues['field_20']]);
      const sub21from18 = subtractValues(valueOf18 as number, valueOf21);
      const valueOf22 = Number(sub21from18) < 0 ? 0 : sub21from18;
      const valueOf24 = sumValues([valueOf22, allValues['field_23']]);

      const finalData = {
        ...allValues,
        field_1z: String(sum1ato1h),
        field_9: String(sum2bto8),
        field_11: String(sub10from9),
        field_12: String(standardDeduction),
        field_14: String(sum12and13),
        field_15: String(income),
        field_16: String(taxLiability),
        field_18: String(valueOf18),
        field_21: String(valueOf21),
        field_22: String(valueOf22),
        field_24: String(valueOf24),
      };
      return finalData;
};

export const formW2Calculations = (data: any, allValues: { [x: string]: any; }) => {
  if (allValues || data) {
    // const numberFound = fieldsList.find(a => Object.keys(data)[0].includes(`_${a.number}_`))?.number;
    // const sumValuesNameSet = sumValues([1, 2, 3, 4, 5, 6].map(z => {
    //   return allValues[`name_field_${numberFound}_employer_${z}`];
    // }));
    // form.setFieldValue([`name_field_${numberFound}_employer_7`], String(sumValuesNameSet));
    const newObject: { [key: string]: any } = {};
    fieldsList.forEach(item => {
      const sumValuesNameList = sumValues([1, 2, 3, 4, 5, 6].map(z => {
        return allValues[`${item.key}_Self_employer_${z}`];
      }));
      const sumValuesSpouseList = sumValues([1, 2, 3, 4, 5, 6].map(z => {
        return allValues[`${item.key}_Spouse_employer_${z}`];
      }));
      const grandTotal = sumValues([sumValuesNameList, sumValuesSpouseList]);
      newObject[`${item.key}_Self_employer_7`] = String(sumValuesNameList);
      newObject[`${item.key}_Spouse_employer_7`] = String(sumValuesSpouseList);
      newObject[`total_field_${item.number}`] = String(grandTotal);
    })
    // form.setFieldValue([`name_field_${numberFound}_employer_7`], String(newObject[`name_field_${numberFound}_employer_7`]));
    // form.setFieldValue([`spouse_field_${numberFound}_employer_7`], String(newObject[`spouse_field_${numberFound}_employer_7`]));
    // form.setFieldValue([`total_field_${numberFound}`], String(newObject[`total_field_${numberFound}`]));
    const finalData = {
      ...allValues,
      ...newObject,
    };
    return finalData;
  }
}