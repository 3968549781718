import {Col, Form, Input, Row} from 'antd';
import style from '../style.module.scss';
import classnames from 'classnames';
import {observer} from 'mobx-react';
import { useStore } from '../../../store/root-store';
import { useEffect } from 'react';

export const ScheduleThree = observer(() => {
  const { setLoggedIn, firstFormStore: { handleSchedule3, getSchedule3Form } } = useStore('');
  const [schedule3Form] = Form.useForm();
  useEffect(() => {
    setLoggedIn(true);
  }, []);
  useEffect(() => {
    // update fields on change of values after calculations from store
    schedule3Form.setFieldsValue(getSchedule3Form);
  }, [getSchedule3Form]);
  const handleValuesChange = (data: any, allValues: any) => {
    // set values in store
    handleSchedule3(data, allValues);
  };
  return (
    <div className={style.incomeTaxReturnContainer}>
      <Form
        form={schedule3Form}
        // onFinish={onFinish}
        name='formschedule3'
        layout='vertical'
        onValuesChange={handleValuesChange}
        scrollToFirstError
      >
        <Row className={style.formItemStyle}>
          <Col span={6} className={style.borderRight}>
            <h2>"SCHEDULE 3
                            (Form 1040)"
            </h2>
            <p>
                            Department of the Treasury	<br />
                            Internal Revenue Service
            </p>
          </Col>
          <Col span={12} className={style.alignCenter}>
            <h2>Additional Credits and Payments																				
																				</h2>
            <h4>	Attach to Form 1040, 1040-SR, or 1040-NR.											<br />
                        Go to www.irs.gov/Form1040 for instructions and the latest information.																																							</h4>
          </Col>
          <Col span={6} className={classnames(style.borderLeft, style.alignCenter)}>
            <div className={style.borderBottom}>OMB No. 1545-0074					</div>
            <h2>###
            </h2>
            <p>"Attachment
                            Sequence  <b>03</b>"
            </p>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>

          <Col span={20}>
            <Form.Item
              label={'Name(s) shown on Form 1040, 1040-SR, or 1040-NR	'}
              name={'mainname'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
              initialValue={`Obaid rehman & alina ali`}
            >
              <Input
                type={'text'}
                
              />
            </Form.Item>
          </Col>
          <Col span={4} className={style.borderLeft}>
            <Form.Item
              label={'Your social security number		'}
              name={'securityNumber'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={2} className={style.borderRight}>
            <h2>Part I
            </h2>

          </Col>
          <Col span={20}>
            <h2>Nonrefundable Credits							</h2>

          </Col>

        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>1</b></Col>
          <Col span={14}><div>Foreign tax credit.  Attach Form 1116, if required													 . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_1'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>1</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>

        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>2</b></Col>
          <Col span={14}><div>Credit for child and dependent care expenses from Form 2441, line 11.  Attach																				
Form 2441																				. . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_2'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>2</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>3</b></Col>
          <Col span={14}><div>	Education credits from Form 8863, line 19											.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .  . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_3'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>3</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>4</b></Col>
          <Col span={14}><div>Retirement savings contributions credit.  Attach Form 8880																															. . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_4'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>4</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>5</b></Col>
          <Col span={14}><div>Residential energy credit.  Attach Form 5695																													 . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_5'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>5</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>6</b></Col>
          <Col span={14}><div>Other nonrefundable credits:														 . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>a</b></Col>
          <Col span={14}><div>General business credit.  Attach Form 3800														 . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_6a'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>6a</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>b</b></Col>
          <Col span={14}><div>Credit for prior year minimum tax.  Attach Form 8801															. . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_6b'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>6b</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>c</b></Col>
          <Col span={14}><div>Adoption credit. Attach Form 8839										. . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_6c'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>6c</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>d</b></Col>
          <Col span={14}><div>Credit for the elderly or disabled. Attach Schedule R														 . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_6d'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>6d</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>e</b></Col>
          <Col span={14}><div>Alternative motor vehicle credit. Attach Form 8910														. . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_6e'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>6e</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>f</b></Col>
          <Col span={14}><div>Qualified plug-in motor vehicle credit. Attach Form 8936															 .    .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .  </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_6f'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>6f</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>g</b></Col>
          <Col span={14}><div>Mortgage interest credit. Attach Form 8396																								 . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_6g'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>6g</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>h</b></Col>
          <Col span={14}><div>District of Columbia first-time homebuyer credit. Attach Form 8859																																		 . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_6h'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>6h</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>i</b></Col>
          <Col span={14}><div>Qualified electric vehicle credit. Attach Form 8834																															 . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_6i'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>6i</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>j</b></Col>
          <Col span={14}><div>Alternative fuel vehicle refueling property credit. Attach Form 8911																																	 . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_6j'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>6j</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>k</b></Col>
          <Col span={14}><div>Credit to holders of tax credit bonds. Attach Form 8912																																	 . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_6k'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>6k</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>l</b></Col>
          <Col span={14}><div>Amount on Form 8978, line 14. See instructions																																 . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_6l'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>6l</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>z</b></Col>
          <Col span={14}><div>Other nonrefundable credits.  List type and amount:																														 . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_6z'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>6z</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>7</b></Col>
          <Col span={14}><div>Total other nonrefundable credits. Add lines 6a through 6z																																							 . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_7'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>7</b>}
                disabled
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>8</b></Col>
          <Col span={14}><div>Add lines 1 through 5 and 7. Enter here and on Form 1040, 1040-SR, or 1040-NR,																					
line 20																					. . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_8'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>8</b>}
                disabled
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
                    
                    <Col span={16}>
                    <b>For Paperwork Reduction Act Notice, see your tax return instructions.																</b>
                    <br />
                    <div>Schedule 3 (Form 1040)  2022						</div>
                    </Col>
                    <Col span={4}>Cat. No. 71480G				</Col>
                    <Col span={4} >
                    <b>			Schedule 3 (Form 1040) 2022</b>
                    <br />
                    <div>	Page <b>2</b></div>
                    </Col>
                </Row>
        <Row className={style.formItemStyle}>
          <Col span={2} className={style.borderRight}>
            <h2>Part II
            </h2>

          </Col>
          <Col span={20}>
            <h2>Other Payments and Refundable Credits													 </h2>

          </Col>

        </Row>

        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>9</b></Col>
          <Col span={14}><div>Net premium tax credit. Attach Form 8962												 . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_9'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>9</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>10</b></Col>
          <Col span={14}><div>Amount paid with request for extension to file (see instructions)																  .   .   .   .   .   .   .   .   .   .   .   . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_10'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>10</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>

        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>11</b></Col>
          <Col span={14}><div>Excess social security and tier 1 RRTA tax withheld																						. . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_11'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>11</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>12</b></Col>
          <Col span={14}><div>Credit for federal tax on fuels. Attach Form 4136														   .   .   .   .   .   .   .   .   .   .   .   .   . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_12'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>12</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>13</b></Col>
          <Col span={14}><div>Other payments or refundable credits:																							 </div></Col>
          <Col span={8}>
            
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>a</b></Col>
          <Col span={14}><div>Form 2439		  .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_13a'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>13a</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>b</b></Col>
          <Col span={14}><div>Credit for qualified sick and family leave wages paid in 2022 from																
Schedules(s) for leave taken before April 1, 2021																 . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_13b'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>13b</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>c</b></Col>
          <Col span={14}><div>Reserved for future use									. . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_13c'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>13c</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>d</b></Col>
          <Col span={14}><div>Credit for repayment of amounts included in income from earlier																
years																. . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_13d'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>13d</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>e</b></Col>
          <Col span={14}><div>Reserved for future use						. . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_13e'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>13e</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>f</b></Col>
          <Col span={14}><div> Deferred amount of net 965 tax liability (see instructions)															. . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_13f'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>13f</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>g</b></Col>
          <Col span={14}><div>Reserved for future use										. . . . . . . . . . . . . . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_13g'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>13g</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>h</b></Col>
          <Col span={14}><div>Credit for qualified sick and family leave wages paid in 2022																
from Schedule(s) H for leave taken after March 31, 2021, and																
before October 1, 2021																.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .  . . . . . . . . . . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_13h'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>13h</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>z</b></Col>
          <Col span={14}><div>Other payments or refundable credits. List type and amount:																   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_13z'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>13z</b>}
                
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>14</b></Col>
          <Col span={14}><div> Total other payments or refundable credits. Add lines 13a through 13z																			. . . . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_14'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>14</b>}
                disabled
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'}>
          <Col span={2} className={style.alignCenter}><b>15</b></Col>
          <Col span={14}><div> Add lines 9 through 12 and 14. Enter here and on Form 1040, 1040-SR, or 1040-NR,																						
line 31																						. . . . . . </div></Col>
          <Col span={8}>
            <Form.Item
              label={''}
              name={'field_15'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}
                addonBefore={<b>15</b>}
                disabled
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
});