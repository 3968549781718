import { Checkbox, Col, Form, Input, Radio, Row } from 'antd';
import style from '../style.module.scss';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useStore } from '../../../store/root-store';
import { useEffect } from 'react';

export const ScheduleC = observer(() => {
  const [scheduleCForm] = Form.useForm();
  const { setLoggedIn, firstFormStore: { handleScheduleC, getScheduleCForm } } = useStore('');
  useEffect(() => {
    setLoggedIn(true);
  }, []);
  useEffect(() => {
    // update fields on change of values after calculations from store
    scheduleCForm.setFieldsValue(getScheduleCForm);
  }, [getScheduleCForm]);
  const handleValuesChange = (data: any, allValues: any) => {
    // set values in store
    handleScheduleC(data, allValues);
  };
  const partIIList = [
    { addon: '8', number: '8', desc: 'Advertising .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .    ', field: 'field_8' },
    { addon: '9', number: '9', desc: 'Car and truck expenses(see instructions)  .   .   .   .   .   .   .   .   .   .   .   .', field: 'field_9' },
    { addon: '10', number: '10', desc: 'Commissions and fees				 .   .   .   .   .   .   .   .   .      ', field: 'field_10' },
    { addon: '11', number: '11', desc: 'Contract labor (see instructions)				 .   .   .   .   .      ', field: 'field_11' },
    { addon: '12', number: '12', desc: 'Depletion    .   .   .   .   .   .   .   .   .   .   .   .   .    ', field: 'field_12' },
    { addon: '13', number: '13', desc: 'Depreciation and section 179 expense deduction (not included in Part III) (see instructions)				.   .   .   .   .   .   .   .   .   .   .  ', field: 'field_13' },
    { addon: '14', number: '14', desc: 'Employee benefit programs	(other than on line 19)				.   .   .   .   .   .   .   .  ', field: 'field_14' },
    { addon: '15', number: '15', desc: 'Insurance (other than health)				.   .   .   .   .   .      ', field: 'field_15' },
    { addon: '16', number: '16', desc: 'Interest:    ', field: '' },
    { addon: '16a', number: 'a', desc: 'Mortgage (paid to banks, etc.)   .   .   .   .   .   .    ', field: 'field_16a' },
    { addon: '16b', number: 'b', desc: 'Other .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .    ', field: 'field_16b' },
    { addon: '17', number: '17', desc: 'Legal and professional services    ', field: 'field_17' },
    { addon: '18', number: '18', desc: 'Office Expense (see instructions)						.   .   .   .   .     ', field: 'field_18' },
    { addon: '19', number: '19', desc: 'Pension and profit-sharing plans						.   .   .   .   .     ', field: 'field_19' },
    { addon: '20', number: '20', desc: 'Rent or lease (see instructions):    ', field: '' },
    { addon: '20a', number: 'a', desc: 'Vehicles, machinery and equipment						.   .   .   .     ', field: 'field_20a' },
    { addon: '20b', number: 'b', desc: 'Other business property						 .   .   .   .   .   .   .   .   .     ', field: 'field_20b' },
    { addon: '21', number: '21', desc: 'Repairs and maintenance						  .   .   .   .   .   .   .   .     ', field: 'field_21' },
    { addon: '22', number: '22', desc: 'Supplies (not included in Part III)						.   .   .   .   .     ', field: 'field_22' },
    { addon: '23', number: '23', desc: 'Taxes and licenses						.   .   .   .   .   .   .   .   .   .   .     ', field: 'field_23' },
    { addon: '24', number: '24', desc: 'Travel and meals:    ', field: '' },
    { addon: '24a', number: 'a', desc: 'Travel						.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .     ', field: 'field_24a' },
    { addon: '24b', number: 'b', desc: 'Deductible meals (see instructions)						.   .   .   .   .   .   .   .   .   .   .   .   .   .   . ', field: 'field_24b' },
    { addon: '25', number: '25', desc: 'Utilities						.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .     ', field: 'field_25' },
    { addon: '26', number: '26', desc: 'Wages (less employment credits)						.   .   .   .   .     ', field: 'field_26' },
    { addon: '27a', number: '27a', desc: 'Other expenses (from line 48)    ', field: 'field_27a', isDisabled: true },
    { addon: '27b', number: 'b', desc: 'Reserved for future use						  .   .   .   .   .   .   .   .     ', field: 'field_27b' },
  ];

  return (
    <div className={style.incomeTaxReturnContainer}>
      <Form
        form={scheduleCForm}
        // onFinish={onFinish}
        name='formscheduleC'
        layout='vertical'
        onValuesChange={handleValuesChange}
        scrollToFirstError
      >
        <Row className={style.formItemStyle}>
          <Col span={6} className={style.borderRight}>
            <h2>"SCHEDULE C
              (Form 1040)"
            </h2>
            <p>
              Department of the Treasury	<br />
              Internal Revenue Service
            </p>
          </Col>
          <Col span={12} className={style.alignCenter}>
            <h2>Profit or Loss from Business</h2>
            <h3>(Sole Proprietorship)</h3>
            <h4>   Go to www.irs.gov/ScheduleC for instructions and the latest information.	<br />
              Attach to Form 1040, 1040-SR, 1040-NR, or 1041; partnerships generally must file Form 1065.
              <br />																											</h4>
          </Col>
          <Col span={6} className={classnames(style.borderLeft, style.alignCenter)}>
            <div className={style.borderBottom}>OMB No. 1545-0074					</div>
            <h2>###
            </h2>
            <p>"Attachment
              Sequence  <b>09</b>"
            </p>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>

          <Col span={16}>
            <Form.Item
              label={'Name of Proprietor'}
              name={'mainname'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}

              />
            </Form.Item>
          </Col>
          <Col span={8} className={style.borderLeft}>
            <Form.Item
              label={'  Social security number (SSN)'}
              name={'securityNumber'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}

              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>

          <Col span={16}>
            <Form.Item
              label={<div><b>A</b>&nbsp;&nbsp;&nbsp;	Principal business or profession, including product or service (see instructions)              </div>}
              name={'businessName'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}

              />
            </Form.Item>
          </Col>
          <Col span={8} className={style.borderLeft}>
            <Form.Item
              label={<div>B &nbsp;&nbsp;&nbsp; Enter code from instructions                </div>}
              name={'codeInstruction'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}

              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>

          <Col span={16}>
            <Form.Item
              label={<div><b>C</b>&nbsp;&nbsp;&nbsp;  Business name.  If no separate business name, leave blank.              </div>}
              name={'business'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}

              />
            </Form.Item>
          </Col>
          <Col span={8} className={style.borderLeft}>
            <Form.Item
              label={<div>D &nbsp;&nbsp;&nbsp; Employer ID number (EIN) (see instr.)                </div>}
              name={'employeeid'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}

              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>

          <Col span={22}>
            <Form.Item
              label={<div><b>E</b> &nbsp;&nbsp;&nbsp; Business address (including suite or room no.)
                City, town or post office, state, and ZIP code
              </div>}
              name={'businessaddress'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}

              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <div><b>F</b>&nbsp;&nbsp;&nbsp; Accounting method:     </div>
          </Col>
          <Col span={12} >
            <Form.Item name={'accountingoption'}>
              <Radio.Group>
                <Radio value='Cash'>Cash</Radio>
                <Radio value='Accrual'>Accrual</Radio>
                <Radio value='Other'>Other (specify)</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'accountingMethod'} className={style.noMarginBottom}>
              <Input
                type={'text'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>G</b>&nbsp;&nbsp;&nbsp;  Did you 'materially participate' in the operation of this business during 2023? If 'No,' see instructions for loss limits.
              .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={1} />
          <Col span={6}>
            <Form.Item name={'field_g'} className={style.noMarginBottom}>
              <Radio.Group>
                <Radio value='yes'>Yes</Radio>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Radio value='no'>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>H</b>&nbsp;&nbsp;&nbsp;  If you started or acquired this business during 2023, check here
              .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={1} />
          <Col span={6}>
            <Form.Item name={'field_h'} className={style.noMarginBottom}>
              <Checkbox value='yes'></Checkbox>&nbsp;&nbsp;&nbsp;
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>I</b>&nbsp;&nbsp;&nbsp;  Did you make any payments in 2023 that would require you to file Form(s) 1099? (see instructions)
              .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={1} />
          <Col span={6}>
            <Form.Item name={'field_i'} className={style.noMarginBottom}>
              <Radio.Group>
                <Radio value='yes'>Yes</Radio>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Radio value='no'>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>J</b>&nbsp;&nbsp;&nbsp;  If "Yes," did you or will you file all required Forms 1099?
              .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={1} />
          <Col span={6}>
            <Form.Item name={'field_j'} className={style.noMarginBottom}>
              <Radio.Group>
                <Radio value='yes'>Yes</Radio>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Radio value='no'>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>


        <Row className={style.formItemStyle}>
          <Col span={3} style={{ background: '#121212', color: '#fff' }}>
            <div><b>Part I</b>
            </div>
          </Col>
          <Col span={11}>Income</Col>
          <Col span={6}>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>1</b>Gross receipts or sales. See instructions for line 1 and check the box if this income was reported to you on
              Form W-2 and the “Statutory employee” box on that form was checked												.   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_1'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>1</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>2</b>Returns and allowances															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_2'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>2</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>3</b>Subtract line 2 from line 1															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_3'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>3</b>}
                disabled
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>4</b>Cost of goods sold  (from line 42)
              .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_4'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>4</b>}
                disabled
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>5</b>&nbsp;<b>Gross profit.</b>  Subtract line 4 from line 3
              .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_5'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>5</b>}
                disabled
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div><b>6</b>Other income, including federal and state gasoline or fuel tax credit or refund  (see instructions)
              .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_6'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>6</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>7</b></Col>
          <Col span={15}>
            <div><b>Gross income.</b>  Add lines 5 and 6
              .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_7'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>7</b>}
                disabled
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>



        <Row className={style.formItemStyle}>
          <Col span={3} style={{ background: '#121212', color: '#fff' }}>
            <div><b>Part II</b>
            </div>
          </Col>
          <Col span={11}><b>Expenses</b>.	Enter expenses for business use of your home only on line 30.
          </Col>
          <Col span={6}>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {partIIList.slice(0, partIIList.length / 2).map(item => {
              return <Row key={item.addon}>
                <Col span={1}><b>{item.number}</b></Col>
                <Col span={15}>
                  {item.desc}
                </Col>
                <Col span={7}>
                  {item.field && <Form.Item name={item.field} className={style.noMarginBottom}>
                    <Input
                      type={'text'}
                      addonBefore={<b>{item.addon}</b>}
                    />
                  </Form.Item>}
                </Col>
              </Row>
            })}
          </Col>
          <Col span={12}>
            {partIIList.slice(partIIList.length / 2, partIIList.length).map(item => {
              return <Row key={item.addon}>
                <Col span={1}><b>{item.number}</b></Col>
                <Col span={15}>
                  {item.desc}
                </Col>
                <Col span={7}>
                  {item.field && <Form.Item name={item.field} className={style.noMarginBottom}>
                    <Input
                      type={'text'}
                      disabled={item.isDisabled}
                      className={item.isDisabled ? `${style.formulaInput}` : ''}
                      addonBefore={<b>{item.addon}</b>}
                    />
                  </Form.Item>}
                </Col>
              </Row>
            })}
          </Col>
        </Row>
        <div className={style.borderTop} />
        <Row>
          <Col span={1}><b>28</b></Col>
          <Col span={15}>
            <div>Total expenses before expenses for business use of home.  Add lines 8 through 27a															.   .   .   .   .   .   .   .   .   .   .

            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_28'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>28</b>}
                disabled
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>29</b></Col>
          <Col span={15}>
            <div>Tentative profit (loss).  Subtract line 28 from line 7															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .

            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_29'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>29</b>}
                disabled
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>30</b></Col>
          <Col span={15}>
            <div>Expenses for business use of your home. Do not report these expenses elsewhere. Attach Form 8829
              unless using the simplified method (see instructions).														300
              Simplified method filers only: enter the total square footage of: (a) your home:
              and (b) the part of your home used for business:												0 		0
              Use the Simplified Method Worksheet in the instructions to figure the amount to enter on line 30															  .   .   .   .   .   .   .   .

            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_30'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>30</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>31</b></Col>
          <Col span={15}>
            <div>Net profit or (loss).  Subtract line 30 from line 29.
              • If a profit, enter on both Schedule 1 (Form 1040), line 3, and on Schedule SE, line 2.  (If you
              checked the box on line 1, see instructions). Estates and trusts, enter on Form 1041, line 3.
              • If a loss, you must go to line 32.

            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_31'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>31</b>}
                disabled
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>32</b></Col>
          <Col span={15}>
            <div>If you have a loss, check the box that describes your investment in this activity. See instructions.
              • If you checked 32a, enter the loss on both Schedule 1 (Form 1040), line 3, and on Schedule
              SE, line 2. (If you checked the box on line 1, see the line 31 instructions). Estates and trusts, enter on
              Form 1041, line 3.
              • If you checked 32b, you must attach Form 6198. Your loss may be limited.

            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_32'} className={style.noMarginBottom}>
              <Checkbox.Group>
                <Checkbox value={'a'}>32a</Checkbox>
                <Checkbox value={'b'}>32b</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>


        <Row className={style.formItemStyle}>

          <Col span={16}>
            <b>For Paperwork Reduction Act Notice, see the separate instructions.
            </b>
            <br />
            <div>Schedule C (Form 1040) 2023
            </div>
          </Col>
          <Col span={4}>	Cat. No. 11334P
          </Col>
          <Col span={4} >
            <b>		Schedule C (Form 1040)  2023
            </b>
            <br />
            <div>	Page <b>2</b></div>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>
          <Col span={3} style={{ background: '#121212', color: '#fff' }}>
            <div><b>Part III</b>
            </div>
          </Col>
          <Col span={11}><b>Cost of Goods Sold</b>     (see instructions)
          </Col>
          <Col span={6}>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>33</b></Col>
          <Col span={15}>
            <div>Method(s) used to
              value closing inventory
              .   .   .   .   .   .   .   .   .   .   .

            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_33'} className={style.noMarginBottom}>
              <Checkbox.Group>
                <Checkbox value={'cost'}>Cost </Checkbox>
                <Checkbox value={'lowerCost'}> Lower cost or market</Checkbox>
                <Checkbox value={'other'}>  Other (attach explanation)</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>34</b></Col>
          <Col span={15}>
            <div>Was there any change in determining quantities, cost or valuations between opening and closing inventory?
              If "Yes", attach explanation													.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .

            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_34'} className={style.noMarginBottom}>
              <Checkbox.Group>
                <Checkbox value={'yes'}>Yes</Checkbox>
                <Checkbox value={'no'}>No</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>35</b></Col>
          <Col span={15}>
            <div>Inventory at beginning of the year.  If different from last year's closing inventory, attach explanation															.   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_35'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>35</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>36</b></Col>
          <Col span={15}>
            <div>Purchases less cost of items withdrawn for personal use															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_36'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>36</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>37</b></Col>
          <Col span={15}>
            <div>Cost of labor.  Do not include any amounts paid to yourself
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_37'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>37</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>38</b></Col>
          <Col span={15}>
            <div>Materials and supplies															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_38'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>38</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>39</b></Col>
          <Col span={15}>
            <div>Other costs															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_39'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>39</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>40</b></Col>
          <Col span={15}>
            <div>Add lines 35 through 39															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_40'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>40</b>}
                disabled
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>41</b></Col>
          <Col span={15}>
            <div>Inventory at end of year															.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_41'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>41</b>}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>42</b></Col>
          <Col span={15}>
            <div><b>Cost of goods sold.</b>   Subtract line 41 from line 40.  Enter the result here and on line 4															.   .   .   .   .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_42'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>42</b>}
                disabled
                className={style.formulaInput}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className={style.formItemStyle}>
          <Col span={3} style={{ background: '#121212', color: '#fff' }}>
            <div><b>Part IV</b>
            </div>
          </Col>
          <Col span={11}><b>Information on Your Vehicle.	</b>   			Complete this part only if you are claiming car or truck expenses on line 9 and
            are not required to file Form 4562 for this business. See the instructions for line 13 on instructions to find out if you must file
            Form 4562.
          </Col>
          <Col span={6}>
          </Col>
        </Row>

        <Row>
          <Col span={1}><b>43</b></Col>
          <Col span={15}>
            <div>When did you place your vehicle in service for business purposes?   (month/day/year)
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_43'} className={style.noMarginBottom}>
              <Input
                type={'date'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>44</b></Col>
          <Col span={15}>
            <div>Of the total number of miles you drove your vehicle during 2023, enter the number of miles you used your vehicle for:
            </div>
          </Col>
          <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
            <b>a</b>
            <Form.Item name={'field_44a'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                placeholder='Business'
              />
            </Form.Item>&nbsp;&nbsp;
            <b>b</b>
            <Form.Item name={'field_44b'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                placeholder='Commuting'
              />
            </Form.Item>&nbsp;&nbsp;
            <b>c</b>
            <Form.Item name={'field_44c'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                placeholder='Other'
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>45</b></Col>
          <Col span={15}>
            <div>Was your vehicle available for use during off-duty hours?													.    .    .    .    .    .    .    .    .    .    .    .    .    .    .    .    .    .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_45'} className={style.noMarginBottom}>
              <Checkbox.Group>
                <Checkbox value={'yes'}>Yes</Checkbox>
                <Checkbox value={'no'}>No</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>46</b></Col>
          <Col span={15}>
            <div>Do you (or your spouse) have another vehicle available for personal use?													 .    .    .    .    .    .    .    .    .    .    .    .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_46'} className={style.noMarginBottom}>
              <Checkbox.Group>
                <Checkbox value={'yes'}>Yes</Checkbox>
                <Checkbox value={'no'}>No</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>47a</b></Col>
          <Col span={15}>
            <div>Do you have evidence to support your deduction?													.    .    .    .    .    .    .    .    .    .    .    .    .    .    .    .    .    .    .    .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_47a'} className={style.noMarginBottom}>
              <Checkbox.Group>
                <Checkbox value={'yes'}>Yes</Checkbox>
                <Checkbox value={'no'}>No</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={1}><b>b</b></Col>
          <Col span={15}>
            <div>If "Yes", is the evidence written?													.    .    .    .    .    .    .    .    .    .    .    .    .    .    .    .    .   .    .    .    .    .    .    .    .    .    .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_47b'} className={style.noMarginBottom}>
              <Checkbox.Group>
                <Checkbox value={'yes'}>Yes</Checkbox>
                <Checkbox value={'no'}>No</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row className={style.formItemStyle}>
          <Col span={3} style={{ background: '#121212', color: '#fff' }}>
            <div><b>Part V</b>
            </div>
          </Col>
          <Col span={11}><b>Other expenses. 	</b>     List below business expenses not included on lines 8-26 or line 30.

          </Col>
          <Col span={6}>
          </Col>
        </Row>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(item => {
          return <Row key={item} gutter={[10, 10]}>
            <Col span={15}>
              <Form.Item name={`field_pre_48_input_${item}`} style={{ marginBottom: 10 }} className={style.noMarginBottom}>
                <Input
                  type={'text'}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={`field_pre_48_amount_${item}`} style={{ marginBottom: 10 }} className={style.noMarginBottom}>
                <Input
                  type={'text'}
                />
              </Form.Item>
            </Col>
          </Row>
        })}
        <Row>
          <Col span={1}><b>48</b></Col>
          <Col span={15}>
            <div><b>Total other expenses.</b>   Enter here and on line 27a															.   .    .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .    .   .   .   .   .   .   .
            </div>
          </Col>
          <Col span={6}>
            <Form.Item name={'field_48'} className={style.noMarginBottom}>
              <Input
                type={'text'}
                addonBefore={<b>48</b>}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
});