import axios from 'axios';
import { cast, flow, types } from 'mobx-state-tree';
import { multiplyByPercent, subtractValues, sumValues } from '../../components/utils/const';
import { firstFormModel, formW2Model, schedule1FormModel, schedule2FormModel, schedule3FormModel, scheduleAFormModel, scheduleBFormModel, scheduleCFormModel, scheduleEFormModel } from './models';
import { toJS } from 'mobx';
import { fieldsList } from '../../components/pages/form-w-2';
import { formW2Calculations } from '../../components/utils/formControl';


export const FirstFormStore = types.model({
  counter: types.number,
  firstForm: firstFormModel,
  schedule1Form: schedule1FormModel,
  schedule2Form: schedule2FormModel,
  schedule3Form: schedule3FormModel,
  scheduleAForm: scheduleAFormModel,
  scheduleBForm: scheduleBFormModel,
  scheduleCForm: scheduleCFormModel,
  scheduleEForm: scheduleEFormModel,
  formW2: formW2Model,
}).views((self) => ({
  get getFirstForm() {
    return toJS(self.firstForm)
  },
  get getSchedule1Form() {
    return toJS(self.schedule1Form)
  },
  get getSchedule2Form() {
    return toJS(self.schedule2Form)
  },
  get getSchedule3Form() {
    return toJS(self.schedule3Form)
  },
  get getScheduleAForm() {
    return toJS(self.scheduleAForm)
  },
  get getScheduleBForm() {
    return toJS(self.scheduleBForm)
  },
  get getScheduleCForm() {
    return toJS(self.scheduleCForm)
  },
  get getScheduleEForm() {
    return toJS(self.scheduleEForm)
  },
  get getFormW2() {
    return toJS(self.formW2)
  },
})).actions(self => {

  const updateData = (values: any) => {
    self.firstForm = cast(values);
  }

  const postFormData = async (data: any) => {
    try {
      const response = await axios.put(`http://159.89.227.70:8000/forms/api/taxforms/1/`, data, {
        // headers: {},
      });
      // Handle the response data or return it
      // updateData(response?.data)
      return response?.data;
    } catch (error) {
      // Handle any errors or throw an error
      console.log('errrrrrrrr', error)
      // throw new Error(error?.message);
    }
  }
  const loadFirstFormValues = async () => {
    try {
      const response = await axios.get(`http://159.89.227.70:8000/forms/api/taxforms/1/`, {
        // headers: {},
      });
      // Handle the response data or return it
      // updateData(response?.data)
      return response?.data;
    } catch (error) {
      // Handle any errors or throw an error
      console.log('errrrrrrrr', error)
      // throw new Error(error?.message);
    }
  };
  const loadW2FromData = flow(function* () {
    try {
      const response = yield axios.get(`https://staging.musetax.com/api/v1/adp-w2-data-access?key=OK1Xn6sEt3Sd1dopQoxeMj9vjndjQ4oW&access-token=7JQruyeFD3&user-id=123`);
      const responseObj = {} as any;
      // response.data?.Data['Emp#1']?.forEach((z: any) => {
      //   responseObj[`${z?.meta_key}_${z?.type}_employer_1`] = String(z?.meta_value);
      // })
      for (let i = 1; i <= 7; i++) {
        const employerKey = `Emp#${i}`;
        response.data?.Data[employerKey]?.forEach((z: any) => {
          responseObj[`${z?.meta_key}_${z?.type}_employer_${i}`] = String(z?.meta_value);
        });
      }
      self.formW2 = responseObj as any;
    } catch (error) {
      console.error(error)
    }
  });

  const setCounter = (value: number) => {
    self.counter = value;
  };
  const setValuesInForms = (data: any) => {
    self.scheduleAForm.field_2 = data['field_11'];
  };
  const handleFirstForm = (data: any, allValues: { [x: string]: any; }) => {
    if (allValues || data) {
      self.firstForm = allValues as any;
    }
  };
  const handleSchedule1 = (data: any, allValues: { [x: string]: any; }) => {
    if (allValues || data) {
      const sum8ato8z = sumValues([allValues['field_8a'], allValues['field_8b'], allValues['field_8c'], allValues['field_8d'], allValues['field_8e'], allValues['field_8f'], allValues['field_8g'], allValues['field_8h'],
      allValues['field_8i'], allValues['field_8j'], allValues['field_8k'], allValues['field_8l'], allValues['field_8m'], allValues['field_8n'], allValues['field_8o'], allValues['field_8p'], allValues['field_8q'], allValues['field_8r'],
      allValues['field_8s'], allValues['field_8t'], allValues['field_8u'], allValues['field_8z']]);
      const sum1to7And9 = sumValues([allValues['field_1'], allValues['field_2a'], allValues['field_3'], allValues['field_4'], allValues['field_5'], allValues['field_6'], allValues['field_7'], sum8ato8z]);
      const sum24ato24z = sumValues([allValues['field_24a'], allValues['field_24b'], allValues['field_24c'], allValues['field_24d'], allValues['field_24e'], allValues['field_24f'], allValues['field_24g'], allValues['field_24h'], allValues['field_24i'], allValues['field_24j'], allValues['field_24k'], allValues['field_24z']]);
      const sum11to23And25 = sumValues([allValues['field_11'], allValues['field_12'], allValues['field_13'], allValues['field_14'], allValues['field_15'], allValues['field_16'], allValues['field_17'], allValues['field_18'], allValues['field_19a'], allValues['field_20'], allValues['field_21'], allValues['field_22'], allValues['field_23'], sum24ato24z]);
      self.schedule1Form = {
        ...allValues,
        field_9: String(sum8ato8z),
        field_10: String(sum1to7And9),
        field_25: String(sum24ato24z),
        field_26: String(sum11to23And25),
      } as any;
      self.firstForm.field_8 = String(sum1to7And9);
      self.firstForm.field_10 = String(sum11to23And25);
    }
  };
  const handleSchedule2 = (data: any, allValues: { [x: string]: any; }) => {
    if (allValues || data) {
      const sum1and2 = sumValues([allValues['field_1'], allValues['field_2']]);
      const sum5and6 = sumValues([allValues['field_5'], allValues['field_6']]);
      const sum17aTo17z = sumValues([allValues['field_17a'], allValues['field_17b'], allValues['field_17c'], allValues['field_17d'], allValues['field_17e'], allValues['field_17f'], allValues['field_17g'], allValues['field_17h'], allValues['field_17i'], allValues['field_17j'],
      allValues['field_17k'], allValues['field_17l'], allValues['field_17m'], allValues['field_17n'], allValues['field_17o'], allValues['field_17p'], allValues['field_17q'], allValues['field_17z']]);
      const sum4And7To16And18 = sumValues([allValues['field_4'], allValues['field_7'], allValues['field_8'], allValues['field_9'], allValues['field_10'], allValues['field_11'], allValues['field_12'], allValues['field_13'], allValues['field_14'],
      allValues['field_15'], allValues['field_16'], sum17aTo17z]);
      self.schedule2Form = {
        ...allValues,
        field_3: String(sum1and2),
        field_7: String(sum5and6),
        field_18: String(sum17aTo17z),
        field_21: String(sum4And7To16And18),
      } as any;
      self.firstForm.field_17 = String(sum1and2);
      self.firstForm.field_23 = String(sum4And7To16And18);
    }
  };
  const handleSchedule3 = (data: any, allValues: { [x: string]: any; }) => {
    if (allValues || data) {
      const sum6aTo6z = sumValues([allValues['field_6a'], allValues['field_6b'], allValues['field_6c'], allValues['field_6d'], allValues['field_6e'], allValues['field_6f'], allValues['field_6g'], allValues['field_6h'], allValues['field_6i'],
      allValues['field_6j'], allValues['field_6k'], allValues['field_6l'], allValues['field_6z']]);
      const sum1To5And7 = sumValues([allValues['field_1'], allValues['field_2'], allValues['field_3'], allValues['field_4'], allValues['field_5'], sum6aTo6z]);
      const sum13aTo13z = sumValues([allValues['field_13a'], allValues['field_13b'], allValues['field_13c'], allValues['field_13d'], allValues['field_13e'], allValues['field_13f'], allValues['field_13g'], allValues['field_13h'], allValues['field_13z']]);
      const sum9To12And14 = sumValues([allValues['field_9'], allValues['field_10'], allValues['field_11'], allValues['field_12'], sum13aTo13z]);
      self.schedule3Form = {
        ...allValues,
        field_7: String(sum6aTo6z),
        field_8: String(sum1To5And7),
        field_14: String(sum13aTo13z),
        field_15: String(sum9To12And14),
      } as any;
      self.firstForm.field_20 = String(sum1To5And7);
      self.firstForm.field_31 = String(sum9To12And14);
    }
  };

  const handleScheduleA = (data: any, allValues: { [x: string]: any; }) => {
    if (allValues || data) {
      const multiply2By7_5 = multiplyByPercent(Number(self.firstForm.field_11), 7.5);
      const sub3from1 = subtractValues(allValues['field_1'], multiply2By7_5);
      const valueOf4 = Number(sub3from1) < 0 ? 0 : sub3from1;
      const sum5aTo5c = sumValues([allValues['field_5a'], allValues['field_5b'], allValues['field_5c']]);
      const checkFromFilingStatus = self.firstForm.filing_status === 'marriedFilingSeparately' ? 5000 : 10000;
      const value5dorless = sum5aTo5c <= checkFromFilingStatus ? sum5aTo5c : checkFromFilingStatus;
      const sum5eAnd6 = sumValues([value5dorless, allValues['field_6']]);
      const sum8aTo8d = sumValues([allValues['field_8a'], allValues['field_8b'], allValues['field_8c'], allValues['field_8d']]);
      const sum8eAnd9 = sumValues([sum8aTo8d, allValues['field_9']]);
      const sum11To13 = sumValues([allValues['field_11'], allValues['field_12'], allValues['field_13']]);
      const sum4To16 = sumValues([valueOf4, sum5eAnd6, sum8eAnd9, sum11To13, allValues['field_15'], allValues['field_16']]);
      self.scheduleBForm = {
        ...allValues,
        field_3: String(multiply2By7_5),
        field_4: String(valueOf4),
        field_5d: String(sum5aTo5c),
        field_5e: String(value5dorless),
        field_7: String(sum5eAnd6),
        field_8e: String(sum8aTo8d),
        field_10: String(sum8eAnd9),
        field_14: String(sum11To13),
        field_17: String(sum4To16),
      } as any;
      // self.firstForm.field_12 = String(sum4To16);
    }
  };

  const handleScheduleB = (data: any, allValues: { [x: string]: any; }) => {
    if (allValues || data) {
      const sumFields1 = sumValues([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(z => allValues[`field_1_amount_${z}`]));
      const sub3from2 = subtractValues(sumFields1, allValues['field_3']);
      const sumFields5 = sumValues([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(z => allValues[`field_5_amount_${z}`]));
      self.scheduleBForm = {
        ...allValues,
        field_2: String(sumFields1),
        field_4: String(sub3from2),
        field_6: String(sumFields5),
      } as any;
      // self.firstForm.field_12 = String(sum4To16);
    }
  };
  const handleScheduleC = (data: any, allValues: { [x: string]: any; }) => {
    if (allValues || data) {
      const sub2from1 = subtractValues(allValues['field_1'], allValues['field_2']);
      const sub4from3 = subtractValues(sub2from1, allValues['field_4']);
      const sum5and6 = sumValues([sub4from3, allValues['field_6']]);
      const valueOf27a = allValues['field_48'] || '';
      const sum8To27a = sumValues([allValues['field_8'], allValues['field_9'], allValues['field_10'], allValues['field_11'], allValues['field_12'], allValues['field_13'], allValues['field_14'], allValues['field_15'],
      allValues['field_16a'], allValues['field_16b'], allValues['field_17'], allValues['field_18'], allValues['field_19'], allValues['field_20a'], allValues['field_20b'], allValues['field_21'], allValues['field_22'],
      allValues['field_23'], allValues['field_24a'], allValues['field_24b'], allValues['field_25'], allValues['field_26'], valueOf27a]);
      const sub28from7 = subtractValues(sum5and6, sum8To27a);
      const sub30from29 = subtractValues(sub28from7, allValues['field_30']);
      const sum35To39 = sumValues([allValues['field_35'], allValues['field_36'], allValues['field_37'], allValues['field_38'], allValues['field_39']]);
      const sub41from40 = subtractValues(sum35To39, allValues['field_41']);
      self.scheduleCForm = {
        ...allValues,
        field_3: String(sub2from1),
        field_5: String(sub4from3),
        field_7: String(sum5and6),
        field_27a: valueOf27a,
        field_28: String(sum8To27a),
        field_29: String(sub28from7),
        field_31: String(sub30from29),
        field_40: String(sum35To39),
        field_42: String(sub41from40),
        field_4: String(sub41from40),
      } as any;
      // self.firstForm.field_12 = String(sum4To16);
    }
  };
  const handleScheduleE = (data: any, allValues: { [x: string]: any; }) => {
    if (allValues || data) {
      const sum5aTo19a = sumValues([5,6,7,8,9,10,11,12,13,14,15,16,17,18,19].map(z => allValues[`field_${z}_a`]));
      const sum5bTo19b = sumValues([5,6,7,8,9,10,11,12,13,14,15,16,17,18,19].map(z => allValues[`field_${z}_b`]));
      const sum5cTo19c = sumValues([5,6,7,8,9,10,11,12,13,14,15,16,17,18,19].map(z => allValues[`field_${z}_c`]));
      const sub20afrom3a = subtractValues(allValues['field_3_a'], sum5aTo19a);
      const sub20bfrom3b = subtractValues(allValues['field_3_b'], sum5bTo19b);
      const sub20cfrom3c = subtractValues(allValues['field_3_c'], sum5cTo19c);
      const sum3a3band3c = sumValues([allValues['field_3_a'], allValues['field_3_b'], allValues['field_3_c']]);
      const sum12a12band12c = sumValues([allValues['field_12_a'], allValues['field_12_b'], allValues['field_12_c']]);
      const sum18a18band18c = sumValues([allValues['field_18_a'], allValues['field_18_b'], allValues['field_18_c']]);
      const sum20a20band20c = sumValues([sum5aTo19a, sum5bTo19b, sum5cTo19c]);
      const sumPositive21 = sumValues([sub20afrom3a > 0 ? sub20afrom3a : 0, sub20bfrom3b > 0 ? sub20bfrom3b : 0, sub20cfrom3c > 0 ? sub20cfrom3c : 0]);
      const field22a = Number(allValues['field_22_a']);
      const field22b = Number(allValues['field_22_b']);
      const field22c = Number(allValues['field_22_c']);
      const sumNegativeValuesof21and22 = sumValues([sub20afrom3a < 0 ? sub20afrom3a : 0, sub20bfrom3b < 0 ? sub20bfrom3b : 0, sub20cfrom3c < 0 ? sub20cfrom3c : 0,
        field22a < 0 ? field22a : 0, field22b < 0 ? field22b : 0, field22c < 0 ? field22c : 0]);
      const sum24and25 = sumValues([sumPositive21, sumNegativeValuesof21and22]);
      const sum28gFields = sumValues([allValues['field_28a_g'], allValues['field_28b_g'], allValues['field_28c_g'], allValues['field_28d_g']]);
      const sum28hFields = sumValues([allValues['field_28a_h'], allValues['field_28b_h'], allValues['field_28c_h'], allValues['field_28d_h']]);
      const sum28iFields = sumValues([allValues['field_28a_i'], allValues['field_28b_i'], allValues['field_28c_i'], allValues['field_28d_i']]);
      const sum28jFields = sumValues([allValues['field_28a_j'], allValues['field_28b_j'], allValues['field_28c_j'], allValues['field_28d_j']]);
      const sum28kFields = sumValues([allValues['field_28a_k'], allValues['field_28b_k'], allValues['field_28c_k'], allValues['field_28d_k']]);
      const sum29a = sumValues([sum28hFields, sum28kFields]);
      const sum29b = sumValues([sum28gFields, sum28iFields, sum28jFields]);
      const sub31from30 = subtractValues(sum29a, sum29b);
      const sum33cFields = sumValues([allValues['field_33a_c'], allValues['field_33b_c']]);
      const sum33dFields = sumValues([allValues['field_33a_d'], allValues['field_33b_d']]);
      const sum33eFields = sumValues([allValues['field_33a_e'], allValues['field_33b_e']]);
      const sum33fFields = sumValues([allValues['field_33a_f'], allValues['field_33b_f']]);
      const sum34a = sumValues([sum33dFields, sum33fFields]);
      const sum34b = sumValues([sum33cFields, sum33eFields]);
      const sub36from35 = subtractValues(sum34a, sum34b);
      const sum38dAnde = sumValues([allValues['field_38_d'], allValues['field_38_e']]);
      const sum2632373940 = sumValues([allValues['field_26'], sub31from30, sub36from35, sum38dAnde, allValues['field_40']]);
      self.scheduleEForm = {
        ...allValues,
        field_20_a: String(sum5aTo19a),
        field_20_b: String(sum5bTo19b),
        field_20_c: String(sum5cTo19c),
        field_21_a: String(sub20afrom3a),
        field_21_b: String(sub20bfrom3b),
        field_21_c: String(sub20cfrom3c),
        field_23a: String(sum3a3band3c),
        field_23c: String(sum12a12band12c),
        field_23d: String(sum18a18band18c),
        field_23e: String(sum20a20band20c),
        field_24: String(sumPositive21),
        field_25: String(sumNegativeValuesof21and22),
        field_26: String(sum24and25),
        field_29a_h: String(sum28hFields),
        field_29a_k: String(sum28kFields),
        field_29b_g: String(sum28gFields),
        field_29b_i: String(sum28iFields),
        field_29b_j: String(sum28jFields),
        field_30_k: String(sum29a),
        field_31_k: String(sum29b),
        field_32_k: String(sub31from30),
        field_34a_d: String(sum33dFields),
        field_34a_f: String(sum33fFields),
        field_34b_c: String(sum33cFields),
        field_34b_e: String(sum33eFields),
        field_35_f: String(sum34a),
        field_36_f: String(sum34b),
        field_37_f: String(sub36from35),
        field_39: String(sum38dAnde),
        field_41: String(sum2632373940),
      } as any;
      self.schedule1Form.field_5 = String(sum2632373940);
    }
  };
  const handleFormW2 = (data: any, allValues: { [x: string]: any; }) => {
    if (allValues || data) {
      self.formW2 = allValues as any;
      
    }
  };
  const setW2ValuesInForms = (newObject: any) => {
    self.firstForm.field_1a = newObject[`total_field_1`];
    self.firstForm.field_25a = newObject[`total_field_2`];
    self.firstForm.field_1i = newObject[`total_field_12a`];
    self.scheduleAForm.field_5a = String(sumValues([newObject[`total_field_17`], newObject[`total_field_19`]]));
  }
  
  return {
    loadW2FromData,
    setW2ValuesInForms,
    updateData,
    loadFirstFormValues,
    postFormData,
    setCounter,
    setValuesInForms,
    handleFirstForm,
    handleSchedule1,
    handleSchedule2,
    handleSchedule3,
    handleScheduleA,
    handleScheduleB,
    handleScheduleC,
    handleScheduleE,
    handleFormW2,
  };
});


export function initFirstFormStore() {
  return FirstFormStore.create({
    counter: 0,
    firstForm: {
      filing_status: 'single',
    },
    schedule1Form: {},
    schedule2Form: {},
    schedule3Form: {},
    scheduleAForm: {},
    scheduleBForm: {},
    scheduleCForm: {},
    scheduleEForm: {},
    formW2: {},
  });
}

