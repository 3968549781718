import { Col, Form, Input, Row } from "antd";
import style from '../style.module.scss';
import classnames from 'classnames';
import { useStore } from "../../../store/root-store";
import { useEffect } from "react";
import { observer } from "mobx-react";

export const ScheduleFirst = observer(() => {
    const { setLoggedIn, firstFormStore: { handleSchedule1, getSchedule1Form } } = useStore('');
    const [schedule1Form] = Form.useForm();
    useEffect(() => {
        setLoggedIn(true);
      }, []);
    useEffect(() => {
        // update fields on change of values after calculations from store
        schedule1Form.setFieldsValue(getSchedule1Form);
    }, [getSchedule1Form]);
    const handleValuesChange = (data: any, allValues: any) => {
        // set values in store
        handleSchedule1(data, allValues);
    };
    return (
        <div className={style.incomeTaxReturnContainer}>
            <Form
                form={schedule1Form}
                // onFinish={onFinish}
                name="formschedule1"
                layout="vertical"
                onValuesChange={handleValuesChange}
                scrollToFirstError
            >
                <Row className={style.formItemStyle}>
                    <Col span={6} className={style.borderRight}>
                        <h2>"SCHEDULE 1
                            (Form 1040)"
                        </h2>
                        <p>
                            Department of the Treasury	<br />
                            Internal Revenue Service
                        </p>
                    </Col>
                    <Col span={12} className={style.alignCenter}>
                        <h2>Additional Income and Adjustments to Income	</h2>
                        <h4>						Attach to Form 1040, 1040-SR, or 1040-NR.	<br />
                            Go to www.irs.gov/Form1040 for instructions and the latest information.																				</h4>
                    </Col>
                    <Col span={6} className={classnames(style.borderLeft, style.alignCenter)}>
                        <div className={style.borderBottom}>OMB No. 1545-0074					</div>
                        <h2>###
                        </h2>
                        <p>"Attachment
                            Sequence  <b>01</b>"
                        </p>
                    </Col>
                </Row>
                <Row className={style.formItemStyle}>

                    <Col span={20}>
                        <Form.Item
                            label={'Name(s) shown on Form 1040, 1040-SR, or 1040-NR	'}
                            name={"mainname"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                            initialValue={`Obaid rehman & alina ali`}
                        >
                            <Input
                                type={"text"}
                                
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4} className={style.borderLeft}>
                        <Form.Item
                            label={'Your social security number		'}
                            name={"securityNumber"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>


                {/* part one Additional Income section start */}
                <Row className={style.formItemStyle}>
                    <Col span={2} className={style.borderRight}>
                        <h2>Part I
                        </h2>

                    </Col>
                    <Col span={20}>
                        <h2>Additional Income						</h2>

                    </Col>

                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>1</b></Col>
                    <Col span={14}><div>Taxable refunds, credits, or offsets of state and local income taxes	.   .   .   .   .   .   .   .   .   .  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_1"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>1</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>2a</b></Col>
                    <Col span={14}><div>Alimony received																							.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_2a"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>2a</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>b</b></Col>
                    <Col span={14}><div>Date of original divorce or separation agreement (see instructions)										. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .							  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_2b"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"date"}
                                addonBefore={<b>2b</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>3</b></Col>
                    <Col span={14}><div>Business income or (loss). Attach Schedule C			. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .																				.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .  																  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_3"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>3</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>4</b></Col>
                    <Col span={14}><div>Other gains or (losses). Attach Form 4797			. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .																						  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_4"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>4</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>5</b></Col>
                    <Col span={14}><div>Rental real estate, royalties, partnerships, S corporations, trusts, etc. Attach Schedule E.																							. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_5"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>5</b>}
                                disabled
                                className={style.formulaInput}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>6</b></Col>
                    <Col span={14}><div>Farm income or (loss). Attach Schedule F											. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .														  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_6"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>6</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>7</b></Col>
                    <Col span={14}><div>Unemployment compensation												. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_7"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>7</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>8</b></Col>
                    <Col span={14}><div>Other income:														 . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>

                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>a</b></Col>
                    <Col span={14}><div>Net operating loss																. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8a"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8a</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>b</b></Col>
                    <Col span={14}><div>Gambling																	. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8b"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8b</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>c</b></Col>
                    <Col span={14}><div>Cancellation of debt																					. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8c"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8c</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>d</b></Col>
                    <Col span={14}><div>Foreign earned income exclusion from Form 2555																																		. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8d"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8d</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>e</b></Col>
                    <Col span={14}><div>Income from Form 8853																																							. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8e"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8e</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>f</b></Col>
                    <Col span={14}><div>Income from Form 8889																																												. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8f"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8f</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>g</b></Col>
                    <Col span={14}><div>Alaska Permanent Fund dividends																																																			. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8g"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8g</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>h</b></Col>
                    <Col span={14}><div>Jury duty pay																																													. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8h"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8h</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>i</b></Col>
                    <Col span={14}><div>Prizes and awards																																															. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8i"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8i</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>j</b></Col>
                    <Col span={14}><div>Activity not engaged in for profit income																																																				. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8j"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8j</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>k</b></Col>
                    <Col span={14}><div>Stock options																																														. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8k"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8k</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>l</b></Col>
                    <Col span={14}><div>Income from the rental of personal property if you engaged in the rental
                        for profit but were not in the business of renting such property																																																								. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8l"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8l</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>m</b></Col>
                    <Col span={14}><div>Olympic and Paralympic medals and USOC prize money (see
                        instructions)																																																											. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8m"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8m</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>n</b></Col>
                    <Col span={14}><div>Section 951(a) inclusion (see instructions)																																																						. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8n"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8n</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>o</b></Col>
                    <Col span={14}><div>Section 951A(a) inclusion (see instructions)																																																							. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8o"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8o</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>p</b></Col>
                    <Col span={14}><div>Section 461(l) excess business loss adjustment																																																							. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8p"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8p</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>q</b></Col>
                    <Col span={14}><div>Taxable distributions from an ABLE account (see instructions)																																																											. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8q"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8q</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>r</b></Col>
                    <Col span={14}><div>Scholarship and fellowship grants not reported on Form W-2																																																										. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8r"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8r</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>s</b></Col>
                    <Col span={14}><div>Nontaxable amount of Medicaid waiver payments included on Form
                        1040, line 1a or 1d																 																																												. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8s"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8s</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>t</b></Col>
                    <Col span={14}><div>Pension or annuity from a nonqualifed deferred compensation plan
                        or a nongovernmental section 457 plan																																																									. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8t"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8t</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>u</b></Col>
                    <Col span={14}><div>Wages earned while incarcerated																																																				. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8u"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8u</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>z</b></Col>
                    <Col span={14}><div>Other income.  List type and amount:																																																				. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_8z"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>8z</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>9</b></Col>
                    <Col span={14}><div>Total other income. Add lines 8a through 8z																																																														. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_9"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>9</b>}
                                disabled
                                className={style.formulaInput}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>10</b></Col>
                    <Col span={14}><div>Combine lines 1 through 7 and 9. Enter here and on Form 1040 or 1040-NR, line 8																																																																																			. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_10"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                disabled
                                addonBefore={<b>10</b>}
                                className={style.formulaInput}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className={style.formItemStyle}>

                    <Col span={16}>
                        <b>For Paperwork Reduction Act Notice, see your tax return instructions.																</b>
                        <br />
                        <div>Schedule 1 (Form 1040)  2022						</div>
                    </Col>
                    <Col span={4}>Cat. No. 71479F			</Col>
                    <Col span={4} >
                        <b>			Schedule 1 (Form 1040) 2022</b>
                        <br />
                        <div>	Page <b>2</b></div>
                    </Col>
                </Row>
                {/* part one Additional Income section end */}



                {/* part two Adjustments to Income section start */}
                <Row className={style.formItemStyle}>
                    <Col span={2} className={style.borderRight}>
                        <h2>Part II
                        </h2>

                    </Col>
                    <Col span={20}>
                        <h2>Adjustments to Income												</h2>

                    </Col>

                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>11</b></Col>
                    <Col span={14}><div>Educator expenses																																																																																					. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_11"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>11</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>12</b></Col>
                    <Col span={14}><div>Certain business expenses of reservists, performing artists, and fee-basis government
                        officials. Attach Form 2106																																																																																													. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_12"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>12</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>13</b></Col>
                    <Col span={14}><div>Health savings account deduction. Attach Form 8889																																																																		. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_13"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>13</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>14</b></Col>
                    <Col span={14}><div>Moving expenses for members of the Armed Forces. Attach Form 3903																																																																																				. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_14"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>14</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>15</b></Col>
                    <Col span={14}><div>Deductible part of self-employment tax. Attach Schedule SE																																																																																																				. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_15"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>15</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>16</b></Col>
                    <Col span={14}><div>Self-employed SEP, SIMPLE, and qualified plans																																																																																																. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_16"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>16</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>17</b></Col>
                    <Col span={14}><div>Self-employed health insurance deduction																																																																																																									. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_17"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>17</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>18</b></Col>
                    <Col span={14}><div>Penalty on early withdrawal of savings																																																																																																								. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_18"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>18</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>19a</b></Col>
                    <Col span={14}><div>Alimony paid																																																																																																. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_19a"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>19a</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>b</b></Col>
                    <Col span={14}><div>Recipient's SSN																																																																																																									. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_19b"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>b</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>c</b></Col>
                    <Col span={14}><div>Date of original divorce or separation agreement (see instructions)																																																																																																																				. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_19c"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>c</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>20</b></Col>
                    <Col span={14}><div>IRA deduction																																																																																																																					. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_20"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>20</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>21</b></Col>
                    <Col span={14}><div>Student loan interest deduction																																																																																																																										. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_21"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>21</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>22</b></Col>
                    <Col span={14}><div>Reserved for future use																																																																																																																							. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_22"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>22</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>23</b></Col>
                    <Col span={14}><div>Archer MSA deduction																																																																																																																								. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_23"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>23</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>24</b></Col>
                    <Col span={14}><div>Other adjustments:																																																																																																																				. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>

                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>a</b></Col>
                    <Col span={14}><div>Jury duty pay (see instructions)																																																																																																																								. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_24a"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>24a</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>b</b></Col>
                    <Col span={14}><div>Deductible expenses related to income reported on line 8k from
                        the rental of personal property engaged in for profit																																																																																																																																				. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_24b"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>24b</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>c</b></Col>
                    <Col span={14}><div>Nontaxable amount of the value of Olympic and Paralympic
                        medals and USOC prize money reported on line 8l																																																																																																																																				. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_24c"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>24c</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>d</b></Col>
                    <Col span={14}><div>Reforestation amortization and expenses																																																																																																																																. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_24d"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>24d</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>e</b></Col>
                    <Col span={14}><div>Repayment of supplemental unemployment benefits under the
                        Trade Act of 1974																																																																																																																																		. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_24e"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>24e</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>f</b></Col>
                    <Col span={14}><div>Contributions to section 501(c)(18)(D) pension plans																																																																																																																																				. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_24f"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>24f</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>g</b></Col>
                    <Col span={14}><div>Contributions by certain chaplains to section 403(b) plans																																																																																																																														. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_24g"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>24g</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>h</b></Col>
                    <Col span={14}><div>Attorney fees and court costs for actions involving certain
                        unlawful discrimination claims (see instructions)																																																																																																																																						. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_24h"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>24h</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>i</b></Col>
                    <Col span={14}><div>Attorney fees and court costs you paid in connection with an
                        award from the IRS for information you provided that helped the
                        IRS detect tax law violations																																																																																																																																							. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_24i"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>24i</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>j</b></Col>
                    <Col span={14}><div>Housing deduction from Form 2555																																																																																																																													. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_24j"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>24j</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>k</b></Col>
                    <Col span={14}><div>Excess deductions of section 67(e) expenses from Schedule K-1
                        (Form 1041)																 																																																																																																																					. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_24k"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>24k</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>z</b></Col>
                    <Col span={14}><div>Other adjustments.  List type and amount																																																																																																																																. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_24z"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                addonBefore={<b>24z</b>}
                                
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>25</b></Col>
                    <Col span={14}><div>Total other adjustments. Add lines 24a through 24z																																																																																																																																				. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_25"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                disabled
                                addonBefore={<b>25</b>}
                                className={style.formulaInput}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={2} className={style.alignCenter}><b>26</b></Col>
                    <Col span={14}><div>Add lines 11 through 23 and 25. These are your adjustments to income. Enter
                        here and on Form 1040 or 1040-SR, line 10, or Form 1040-NR, line 10a																																																																																																																																												. . . . . . . . . . . . . . . . . . . . . . . . . . . . . .											  </div></Col>
                    <Col span={8}>
                        <Form.Item
                            label={''}
                            name={"field_26"}
                            validateTrigger={['onBlur']}
                            className={style.noMarginBottom}
                        >
                            <Input
                                type={"text"}
                                disabled
                                addonBefore={<b>26</b>}
                                className={style.formulaInput}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {/* part two Adjustments to Income section end */}

            </Form>

        </div>
    )
})
