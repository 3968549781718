import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { AllRoutes } from './components/layout/routes';
import { NavBar } from './components/layout/navBar';
import { observer } from 'mobx-react';
import { useStore } from './store/root-store';


function App() {
  const {loggedIn} = useStore('');
  return (
    <Router>
      {loggedIn && <NavBar />}
      <AllRoutes />
    </Router>
  );
}

export default observer(App);
