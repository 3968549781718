import { Checkbox, Col, Form, Input, Row } from 'antd';
import style from '../style.module.scss';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useStore } from '../../../store/root-store';
import { useEffect } from 'react';

export const ScheduleA = observer(() => {
  const [scheduleAForm] = Form.useForm();
  const {setLoggedIn, firstFormStore: { handleScheduleA, getScheduleAForm } } = useStore('');
  useEffect(() => {
    // update fields on change of values after calculations from store
    scheduleAForm.setFieldsValue(getScheduleAForm);
  }, [getScheduleAForm]);
  useEffect(() => {
    setLoggedIn(true);
  }, []);
  const handleValuesChange = (data: any, allValues: any) => {
    // set values in store
    handleScheduleA(data, allValues);
  };
  return (
    <div className={style.incomeTaxReturnContainer}>
      <Form
        form={scheduleAForm}
        // onFinish={onFinish}
        name='formscheduleA'
        layout='vertical'
        onValuesChange={handleValuesChange}
        scrollToFirstError
      >
        <Row className={style.formItemStyle}>
          <Col span={6} className={style.borderRight}>
            <h2>"SCHEDULE A
              (Form 1040)"
            </h2>
            <p>
              Department of the Treasury	<br />
              Internal Revenue Service
            </p>
          </Col>
          <Col span={12} className={style.alignCenter}>
            <h2>Itemized Deductions	</h2>
            <h4>    Go to www.irs.gov/Form1040 for instructions and the latest information.		<br />
              Attach to Form 1040 or 1040-SR.											<br />
              Caution: If you are claiming a net qualified disaster loss on Form 4684, see the instructions for line 16.																																					</h4>
          </Col>
          <Col span={6} className={classnames(style.borderLeft, style.alignCenter)}>
            <div className={style.borderBottom}>OMB No. 1545-0074					</div>
            <h2>###
            </h2>
            <p>"Attachment
              Sequence  <b>07</b>"
            </p>
          </Col>
        </Row>
        <Row className={style.formItemStyle}>

          <Col span={20}>
            <Form.Item
              label={'Name(s) shown on Form 1040 or 1040-SR.'}
              name={'mainname'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
              initialValue={`Obaid rehman & alina ali`}
            >
              <Input
                type={'text'}

              />
            </Form.Item>
          </Col>
          <Col span={4} className={style.borderLeft}>
            <Form.Item
              label={'Your social security number		'}
              name={'securityNumber'}
              validateTrigger={['onBlur']}
              className={style.noMarginBottom}
            >
              <Input
                type={'text'}

              />
            </Form.Item>
          </Col>
        </Row>

        {/* Medical and dental expenses section start */}
        <Row className={style.formItemStyle}>
          <Col span={4}>
            <div>
              <h2>
                Medical
                and
                Dental
                Expenses

              </h2>
            </div>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={15}>
                <div><b>Caution:</b> Do not include expenses reimbursed or paid by others. </div>
              </Col>
              <Col span={6}>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>1</b>Medical and dental expenses (see instructions)
                  .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_1'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>1</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>2</b>Enter amount from Form 1040 or 1040-SR, line 11
                  .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_2'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>2</b>}
                    disabled
                    className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>3</b>Multiply line 2 above by 7.5% (0.075)
                  .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_3'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>3</b>}
                    disabled
                    className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>4</b>Subtract line 3 from line 1.  If line 3 is more than line 1, enter -0-
                  .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_4'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>4</b>}
                    disabled
                    className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Medical and dental expenses section end */}


        {/* Taxes You Paid section start */}
        <Row className={style.formItemStyle}>
          <Col span={4}>
            <div>
              <h2>
                Taxes You Paid
              </h2>
            </div>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={15}>
                <div><b>5</b>State and local taxes </div>
              </Col>
              <Col span={6}>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>a</b>State and local income taxes or general sales taxes. You may include
                  either income taxes or general sales taxes on line 5a, but not both.
                  If you elect to include general sales taxes instead of income taxes,
                  check this box					.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .
                </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_5a'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>5a</b>}
                    disabled
                    className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>b</b>State and local real estate taxes (see instructions)
                  .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_5b'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>5b</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>c</b>State and local personal property taxes
                  .   .   .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_5c'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>5c</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>d</b>Add lines 5a through 5c
                  .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_5d'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>5d</b>}
                    disabled
                    className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>e</b>Enter the smaller of line 5d or $10,000 ($5,000 if married filing
                  separately)
                  .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_5e'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>5e</b>}
                    disabled
                    className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>6</b>Other taxes.   List type and amount
                  .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_6'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>6</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>7</b>Add lines 5e and 6
                  .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_7'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>7</b>}
                    disabled
                    className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Taxes You Paid section end */}


        {/* Interest You Paid section start */}
        <Row className={style.formItemStyle}>
          <Col span={4}>
            <div>
              <h2>
              Interest You
Paid
              </h2>
            </div>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={15}>
                <div><b>8</b>Home mortgage interest and points. If you didn't use all of your					
home mortgage loan(s) to buy, build, or improve your home,					
see instructions and check this box					.   .   .   .   .   .   .   .   .  
</div>
              </Col>
              <Col span={6}>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>a</b>Home mortgage interest and points reported to you on Form 1098.
See instructions if limited
 .   .   .   .   .   .   .   .
                </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_8a'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>8a</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>b</b>Home mortgage interest not reported to you on Form 1098.  See						
instructions if limited. If paid to the person from whom you bought the						
home, see instructions and show that person's name, identifying no.,						
and address						.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .  
</div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_8b'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>8b</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>c</b>Points not reported to you on Form 1098. See instructions for special						
rules 						.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .  
 </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_8c'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>8c</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>d</b>Reserved for future use

                  .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_8d'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>8d</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>e</b>Add lines 8a through 8d
  .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_8e'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>8e</b>}
                    disabled
                    className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>9</b>Investment interest. Attach Form 4952 if required.  See instructions
                  .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_9'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>9</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>10</b>Add lines 8e and 9
                  .   .   .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_10'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>10</b>}
                    disabled
                    className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Interest You Paid section end */}


{/* Gifts to charity section start */}
<Row className={style.formItemStyle}>
          <Col span={4}>
            <div>
              <h2>
              Gifts to
Charity
              </h2>
            </div>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={15}>
                <div><b>11</b>Gifts by cash or check. If you made any gift of $250 or more, see							
instructions							.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . 

                </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_11'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>11</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>12</b>Other than by cash or check. If any gift of $250 or more,							
see instructions. You must attach Form 8283 if over $500							.   .   .   . 
  .   .   .   .   .   .   .   .   .   .  
</div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_12'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>12</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>13</b>Carryover from prior year
  .   .   .   .   .   .   .   .   .   .  
 </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_13'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>13</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>14</b>Add lines 11 through 13
  .   .   .   . </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_14'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>14</b>}
                    disabled
                    className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Gifts to charity section end */}

        {/* Casualty and Theft Losses section start */}
<Row className={style.formItemStyle}>
          <Col span={4}>
            <div>
              <h2>
              Casualty and
Theft Losses
              </h2>
            </div>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={15}>
                <div><b>15</b>Casualty and theft loss(es) from a federally declared disaster (other than net qualified									
disaster losses). Attach Form 4684 and enter the amount from line 18 of that form. See									
instructions									.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . 
</div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_15'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>15</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Casualty and Theft Losses section end */}

        {/* Other Itemized Deductions section start */}
<Row className={style.formItemStyle}>
          <Col span={4}>
            <div>
              <h2>
              Other
Itemized
Deductions

              </h2>
            </div>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={15}>
                <div><b>16</b>Other--from list in instructions.  List type and amount.
   .   .   .   .   .   .   .   .   .  
 </div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_16'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>16</b>}
                  />
                </Form.Item>
              </Col>
            </Row>
            
          </Col>
        </Row>
        {/* Other Itemized Deductions section end */}

        {/* Total Itemized Deductions section start */}
<Row className={style.formItemStyle}>
          <Col span={4}>
            <div>
              <h2>
              Total
Itemized
Deductions

              </h2>
            </div>
          </Col>
          <Col span={20}>
            <Row>
              <Col span={15}>
                <div><b>17</b>Add the amounts in the far right column for lines 4 through 16. Also, enter this amount on									
Form 1040 or 1040-SR, line 12									.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   . 
</div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_17'} className={style.noMarginBottom}>
                  <Input
                    type={'text'}
                    addonBefore={<b>17</b>}
                    disabled
                    className={style.formulaInput}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <div><b>18</b>If you elect to itemize deductions even though they are less than your standard									
deduction, check here									.   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .   .  

</div>
              </Col>
              <Col span={6}>
                <Form.Item name={'field_18'} className={style.noMarginBottom}>
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Total Itemized Deductions section end */}
      </Form>
    </div>
  );
});